import styled from 'styled-components';

export const ReportFiltersBox = styled.div`
    padding: 25px;
    margin-top: 25px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0,0,0,.1);
    background: #fff;
    margin-bottom: 50px;
`;

export const ReportFiltersTitle = styled.div`
    font-size: 20px;
    text-transform: uppercase;
    padding: 10px 15px;
    margin-bottom: 0;
    text-align: center;
    border-radius: 3px;
`;

export const FilterElements = styled.div`
    display: flex;
    flex-direction: column;
    > div {
        margin-top: 10px;
    }
`;

export const FilretButtonsBox = styled.div`
    display: flex;
    button:not(:first-child) {
        margin-left: 15px
    };
`;

export const ReportRangeItem = styled.div`
    display: flex;
    align-items: baseline;
    margin-left: ${(p) => (p.left ? '25px' : 'none')};
`;

export const ReportFilterItems = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    > div:not(:first-child) {
        margin-left: 15px
    };
`;

export const MsFiltersItem = styled.div`
    width: 100%;
    > input {
        width: 100%;
        border: none;
        border-bottom: 2px solid transparent;
        box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
        background: #fff;
        color: #0d1a28;
        border-radius: 3px;
        padding: 10px 15px;
    }
`;

export const FilterBox = styled.div`
    margin: 8px;
    border: 1px solid gainsboro;
    border-radius: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    position: relative;
    min-width: ${(props) => {
    if (props.Order) {
      return '322px';
    } if (props.History) {
      return '200px';
    }
    return null;
  }};
    flex: ${(props) => (props.Select ? '1' : null)};
`;

export const FilterBoxTitle = styled.div`
    position: absolute;
    padding: 5px 15px;
    background: white;
    top: -1em;
`;

export const FilterItemBox = styled.div`
    padding: 10px 10px 10px 10px;
    width: 100%;
    height: 100%;
    display: ${(props) => (props.range ? 'flex' : '')};
    `;

export const OrderElementBox = styled.div` 
    padding: 8px;
    border: 1px solid;
    border-radius: 10px 10px 10px 10px;
    border-color: ${(props) => (props.isDragging ? '#FC4C02' : 'gainsboro')};
    color: ${(props) => (props.isDragging ? '#ffffff' : 'black')};
    margin-bottom: 8px;
    background-color: ${(props) => (props.isDragging ? '#FC4C02' : 'gainsboro')};
`;

export const TestDiv = styled.div`
    align-items: center;
    display: flex;
    min-width: ${(props) => (props.typew ? '285px' : '275px;')};
    max-width: ${(props) => (props.typew ? '285px' : ' 275px;')};
    justify-content: space-between;
`;

export const TestDiv2 = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const TestDiv3 = styled.div`
    display: flex;
    height: 32px;
    align-items: ${(props) => (props.time ? 'center' : 'none')};
`;

export const FilterLineBox = styled.div`
   margin-top: 10px;
`;
