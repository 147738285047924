import React, {
  useState, useCallback, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.min.css';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import { getReportConfig } from '../../../../../../../../logic/store/redux/reports';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';

function OperatorLogi(props) {
  const [queryParams, setQueryParams] = useState();
  const { dispatch, preloader } = props;
  const reportConfig = useSelector((s) => s);

  const moreInfo = useCallback((row, status, type) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        reportType: 'ticketReport',
        rootReport: reportConfig.reportType,
        renderBackButton: true,
        rootTable: type,
        buttonType: status,
        activeRow: row,
      },
    ));
  }, [reportConfig, dispatch]);

  const doughnutClick = useCallback((markValue, operatorId, markText) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        reportType: 'markReport',
        rootReport: reportConfig.reportType,
        renderBackButton: true,
        anyId: operatorId,
        buttonType: markValue,
        markText,
      },
    ));
  }, [reportConfig, dispatch]);

  const preparQueryParams = useCallback(async (filters) => {
    const columns = TableConfig(moreInfo, doughnutClick); // Колонки для таблиц
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'operatorLogi',
    };
    setQueryParams(params);
  }, [moreInfo, doughnutClick]);

  useEffect(() => {
    preparQueryParams();
  }, [props, preparQueryParams]);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'operators', 'workplaces'],
          others: [],
          orders: [],
        }}
        paramsCallback={preparQueryParams}
        type="operatorLogi"
      />
      <PrepareTable
        keys="key"
        type="OperatorTable"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

export default OperatorLogi;
