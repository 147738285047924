import React, {
  useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Drawer, Skeleton, Divider, Card, Col, Row, Statistic,
} from 'antd';
import TicketHistory from './TicketHistory';

import Expand from './ExpandTable/Expander';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';
import SqlQuery from './ExpandTable/SqlQuery';

function TicketTable(props) {
  const [queryParams, setQueryParams] = useState();
  const { preloader } = props;

  const [drawerData, setDrawerData] = useState([]);
  const [currentTicket, setCurrentTicket] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  const moreInfo = (row) => {
    setDrawerOpen(true);
    setLoading(true);
    setCurrentTicket(row);
    SqlQuery(row.ticket_id).then((resp) => {
      const { myData } = resp;
      console.log('myData === ', myData);
      setDrawerData(myData);
      setCurrentTicket(row);
      setLoading(false);
    });
  };

  const preparQueryParams = async (filters) => {
    const columns = TableConfig(moreInfo); // Колонки для таблиц
    const params = {
      columns,
      filters: filters || defaultFilters('close_data::timestamp DESC'),
      url: '/api/reports/build',
      type: 'ticketReport',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, []);

  return (queryParams && (
  <>
    <ReportFilters
      showFilters={{
        main: ['reportRange', 'operators', 'workplaces', 'queues'],
        others: ['servedDateFilters', 'tiketParamsFilters', 'servedTimeFilters', 'actionFilters'],
        orders: ['create_data', 'close_data', 'ticket_number', 'user_fio', 'workplace_name', 'queue_name', 'prior', 'total_wait_time',
          'total_proc_time'],
      }}
      paramsCallback={preparQueryParams}
      type="ticketReport"
      showTicketId
    />
    <PrepareTable
      keys="ticket_id"
      type="TicketTable"
      expand={Expand}
      {...queryParams}
      preloader={preloader}
    />
    <Drawer
      width={840}
      title={`История талона № ${currentTicket.ticket_number}`}
      placement="right"
      onClose={onDrawerClose}
      visible={drawerOpen}
    >
      {loading ? <Skeleton />
        : (
          <>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Длительность ожидания"
                    value={currentTicket.total_wait_time}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Длительность обслуживания"
                    value={currentTicket.total_proc_time}
                  />
                </Card>
              </Col>
            </Row>
            <Divider orientation="right">История</Divider>
            <TicketHistory data={drawerData} currentTicket={currentTicket} />
            <Divider orientation="right">Доп. информация</Divider>
            <Row gutter={16}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Приоритет"
                    value={currentTicket.prior}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Оценка"
                    value={currentTicket.ticket_mark === '0' ? 'Не проставлена' : currentTicket.ticket_mark}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Источник"
                    value={currentTicket.source === '' ? 'Не указан' : currentTicket.source}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card>
                  <Statistic
                    title="Кол-во решенных вопросов"
                    value={currentTicket.solved_questions}
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
    </Drawer>
  </>
  ));
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(TicketTable);
