import React from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import locale from 'antd/es/locale/ru_RU';
import TableConfig from './TableConfig';
import { Title } from '../../../../../../../common/ui/Common';

function WorkPlaceList(props) {
  const { data, userKick } = props;
  const config = TableConfig(userKick);
  return (
    data ? (
      <>
        <Title>Список рабочих мест</Title>
        <Table
          rowKey="id"
          dataSource={data}
          columns={config}
          locale={locale.Table}
        />
      </>
    ) : (<div>Пусто</div>));
}

export default React.memo(WorkPlaceList);
