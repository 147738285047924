const groupingSlots = (slots, qeueList) => {
  const list = {};
  slots.forEach((el) => {
    const { pastart_on: pastartOn, q_id: slotQId, registered } = el;
    const pastartTime = pastartOn.split(' ')[1];
    const it = list[`${pastartTime} ${slotQId}`];
    if (it) {
      list[`${pastartTime} ${slotQId}`] = {
        q_id: slotQId,
        qName: qeueList.find((q) => q.id === slotQId) ? qeueList.find((q) => q.id === slotQId).name : '',
        length: list[`${pastartTime} ${slotQId}`].length + 1,
        emptySlotsLength: registered === false ? list[`${pastartTime} ${slotQId}`].emptySlotsLength + 1 : list[`${pastartTime} ${slotQId}`].emptySlotsLength,
      };
    } else {
      list[`${pastartTime} ${slotQId}`] = {
        length: 1,
        emptySlotsLength: registered === false ? 1 : 0,
        qName: qeueList.find((q) => q.id === slotQId) ? qeueList.find((q) => q.id === slotQId).name : '',
        q_id: slotQId,
      };
    }
  });

  const finList = {};
  Object.keys(list)
    .map((el) => ({
      time: el.split(' ')[0],
      registered: list[el].registered,
      length: list[el].length,
      q_id: list[el].q_id,
      qName: list[el].qName,
      emptySlotsLength: list[el].emptySlotsLength,
    }))
    .sort((a, b) => {
      const hA = +a.time.split(':')[0];
      const hB = +b.time.split(':')[0];
      const mA = +a.time.split(':')[1];
      const mB = +b.time.split(':')[1];
      if (hA === hB) {
        return mA > mB ? 1 : -1;
      }
      return hA > hB ? 1 : -1;
    })
    // eslint-disable-next-line no-return-assign
    .forEach((item) => finList[item.q_id] = finList[item.q_id]
      ? [...finList[item.q_id], item]
      : [item]);
  return finList;
};

export default groupingSlots;
