export function getWhere({
  before, from, value, comparator,
}, filld) {
  switch (comparator) {
    case 'range':
      if (before && from !== '') {
        return `> '${from}'::timestamp  and ${filld}::timestamp < '${before}'::timestamp`;
      }
      return 'all';
    case 'none':
      return 'all';
    default:
      if (value !== '') {
        return `${comparator} '${value}'::timestamp`;
      }
      return 'all';
  }
}

export default null;
