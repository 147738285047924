import { DubleLineTemplate } from '../../../../../utils/formater';

export default function TableConfig(getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: '2',
        title: 'Очередь',
        render: (cell, row) => (
          DubleLineTemplate('text', 'Очередь:', row[2], 'count', 'Рабочих дней:', row[1])
        ),
        ...getColumnSearchProps('2', 'очередь'),
      },
      {
        dataIndex: '3',
        title: 'Талонов обработано',
        sorter: (a, b) => b[3] - a[3],
        sortDirections: ['descend', 'ascend'],
        render: (cell, row) => DubleLineTemplate('count', 'Всего:', row[3], 'count', 'В день:', row[4]),
        editable: false,
      },
      {
        dataIndex: '7',
        isDummyField: true,
        title: 'Закрытых',
        sorter: (a, b) => b[7] - a[7],
        sortDirections: ['descend', 'ascend'],
        render: (cell, row) => DubleLineTemplate('count', 'Всего:', row[7], 'count', 'В день:', row[8]),
      },
      {
        dataIndex: '5',
        title: 'Закрытых по неявке',
        sorter: (a, b) => b[5] - a[5],
        sortDirections: ['descend', 'ascend'],
        render: (cell, row) => DubleLineTemplate('count', 'Всего:', row[5], 'count', 'В день:', row[6]),
      },
      {
        dataIndex: '9',
        isDummyField: true,
        title: 'Удаленные системой',
        sorter: (a, b) => b[9] - a[9],
        sortDirections: ['descend', 'ascend'],
        render: (cell, row) => DubleLineTemplate('count', 'Всего:', row[9], 'count', 'В день:', row[10]),
      },
    ],
  };
}
