import React, {
  useState, useCallback,
} from 'react';
import {
  Route, Navigate, Routes as SwitchRoutes, useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import routesList from './routesList';
import Preloader from '../components/preloader/Preloader';
import ErrorModal from '../../../common/ui/Modal/ErrorModal';
import { useToggle } from '../../../logic/hooks/useToggle';
import getLinks from '../../../logic/utils/getLinks';
import getToken from '../../../logic/utils/getToken';

function Routes() {
  const [isOpen, toggleOpen] = useToggle(false);
  const [isError, setIsError] = useToggle(false);
  const [errorText, setErrorText] = useState('');

  const showError = useCallback((text) => {
    setIsError();
    setErrorText(text);
  }, [setIsError]);

  const links = getLinks();
  const location = useLocation().pathname.split('/')[1];
  const isValid = getToken() && links.some((el) => new RegExp(location).test(el));

  const routes = () => {
    if (!isValid) return <Route path="*" element={<Navigate to="/login" />} />;

    return routesList.map((route) => (
      <Route
        key={route.name}
        path={route.name}
        element={(
          <route.value
            preloader={toggleOpen}
            showError={showError}
            write={getLinks(true)}
          />
        )}
      />
    ));
  };
  return (
    <>
      <SwitchRoutes>
        {routes()}
        <Route path="*" element={<Navigate to={isValid ? '/monitoring' : '/login'} />} />
      </SwitchRoutes>
      <Preloader isOpen={isOpen} />
      <ToastContainer />
      <ErrorModal
        isError={isError}
        errorText={errorText}
        setIsError={setIsError}
        showError={showError}
      />
    </>
  );
}

export default Routes;
