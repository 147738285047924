/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { Button, Drawer } from 'antd';
import {
  FilterMonth,
  MonthBox,
} from '../style';
import Day from './day';
import Record from './record';
import preEntryStore, { updatePreEntryStore } from '../../../../../../logic/store/pullstate/preEntryStore';
import request from '../../../../../../logic/utils/request';
import AddSlots from './AddSlots';
import randomId from '../../../../../../logic/utils/randomId';

function Calendar({
  qeueList,
}) {
  const selectedDate = preEntryStore.useState(((s) => s.selectedDate));
  const selectedDayId = selectedDate.id;

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [month, setMonth] = useState(moment().format('MM'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [calendar, setCalendar] = useState();
  const [openAddSlotDrawer, setOpenAddSlotDrawer] = useState('');

  const day = moment().format('DD');
  const newDay = moment(`${year}.${month}.${day}`, 'YYYY:M:DD').isValid() ? day : 1;

  const mom = moment(`${year}.${month}.${newDay}`, 'YYYY:M:DD');

  const offset = moment(`${year}.${month}.01`, 'YYYY:MM:DD').isoWeekday();
  const offsetEnd = 7 - moment(`${year}.${month}.01`, 'YYYY:MM:DD').endOf('month').isoWeekday();
  const countDaysInSelectedMounth = mom.daysInMonth();

  const monthName = mom.format('MMMM');
  const arrNameDays = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  const description = [
    { text: 'Запись не ведется', class: 'disable' },
    { text: 'Можно записаться', class: 'green' },
    { text: 'Все слоты для записи заняты', class: 'blue' },
    { text: 'Все записи обслужены', class: 'red' },
    { text: 'Выбранный день', class: 'white' },
  ];

  useEffect(() => {
    const newMonth = moment().set({ month: month - 1, year });
    setStartDate(moment(newMonth).startOf('month'));
    setEndDate(moment(newMonth).endOf('month'));
  }, [month]);

  const renderDays = () => {
    const arrDays = [];
    for (let i = 1; i < offset; i += 1) {
      arrDays.push(<li key={i + 100} />);
    }
    for (let dayInMounthNumber = 1;
      dayInMounthNumber <= countDaysInSelectedMounth;
      dayInMounthNumber += 1) {
      const item = calendar[dayInMounthNumber];
      arrDays.push(
        <Day
          selectedDayId={selectedDayId}
          monthNumber={month}
          dayInMounthNumber={dayInMounthNumber}
          item={item}
          key={dayInMounthNumber}
        />,
      );
    }
    return arrDays;
  };

  const ofEnd = [];
  for (let of = 1; of <= offsetEnd; of += 1) {
    ofEnd.push(<li key={of}>{of}</li>);
  }

  const fetchCalendar = async () => {
    const calendarData = await request(
      '/server/api/dpafullcalendarslot/',
      'get',
      {
        dateFrom: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
        dateTo: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
      },
    );
    const cal = {};
    calendarData.data.data.forEach((el) => {
      const date = +el.thedate.split(' ')[0].split('.')[2];
      if (cal[date]) {
        cal[date].push(el);
      } else {
        cal[date] = [el];
      }
    });
    setCalendar(cal);
    updatePreEntryStore(
      {
        selectedDate: {
          ...selectedDate,
          data: cal[selectedDate.day.split('.')[0]],
        },
      },
    );
  };

  useEffect(() => {
    fetchCalendar();
  }, [startDate, endDate]);

  return (
    <div className="flex" style={{ marginBottom: 60, alignItems: 'flex-start' }}>

      <Drawer
        title="Добавление слотов"
        placement="right"
        onClose={() => { setOpenAddSlotDrawer(false); }}
        visible={openAddSlotDrawer}
        width={660}
      >
        <AddSlots
          qeueList={qeueList}
          fetchCalendar={fetchCalendar}
        />
      </Drawer>

      <MonthBox>
        <Button
          onClick={() => { setOpenAddSlotDrawer(true); }}
          style={{ width: '100%', marginBottom: '10px' }}
          disabled={moment().add(-1, 'day').isAfter(selectedDate)}
          size="large"
        >
          Добавить слот
        </Button>
        <FilterMonth>
          {qeueList && (
          <div className="monthFilter">
            { moment().format('YYYY.MM') !== `${year}.${month}`
              ? (month !== 1
                ? (
                  <span onClick={() => setMonth(+month - 1)}>
                    <FaChevronLeft />
                  </span>
                )
                : (
                  <span onClick={() => { setMonth(12); setYear(+year - 1); }}>
                    <FaChevronLeft />
                  </span>
                ))
              : <span className="shadow" />}
            <p>
              {monthName}
              ,
              {' '}
              {year}
            </p>
            { month < 12
              ? <span onClick={() => setMonth(+month + 1)}><FaChevronRight /></span>
              : (
                <span onClick={() => { setMonth(1); setYear(+year + 1); }}>
                  <FaChevronRight />
                </span>
              )}
          </div>
          )}
        </FilterMonth>
        <>
          <div className="month">
            <div className="titleBox">{arrNameDays.map((el) => <li key={el} className="title">{el}</li>)}</div>
            {calendar && renderDays()}
            {ofEnd}
          </div>
          <div className="description">
            {description.map((el) => (
              <div key={randomId()} className="item">
                <span className={el.class} />
                <p>{el.text}</p>
              </div>
            ))}
          </div>
        </>
      </MonthBox>
      {selectedDate?.data?.length > 0 && (
      <Record
        selectedDate={selectedDate}
        qeueList={qeueList}
        fetchCalendar={fetchCalendar}
      />
      )}
    </div>
  );
}

export default Calendar;
