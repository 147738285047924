import React from 'react';
import {
  FilterItemBox, FilterLineBox, FilterBox, FilterBoxTitle,
} from '../../style';
import AllFilter from './AllFilter';

function ServedFilter({ changeFilter }) {
  return (
    <FilterBox>
      <FilterBoxTitle>Обслужено</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="allCnt"
            title="Всего"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="close_cnt"
            title="Закрыто"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilter
            changeFilter={changeFilter}
            filld="neyavka_cnt"
            title="Неявка"
          />
        </FilterLineBox>
      </FilterItemBox>
    </FilterBox>
  );
}
export default ServedFilter;
