const DropdownConfig = (type) => {
  switch (type) {
    case 'status':
      return ({
        headerTitleText: 'Все',
        options: {},
        items: [
          { key: '1', name: 'all', text: 'Все' },
          { key: '2', name: 'Закрыт', text: 'Закрыт' },
          { key: '3', name: 'Закрыт по неявке', text: 'Закрыт по неявке' },
          { key: '4', name: 'Удален системой', text: 'Удален системой' },
          { key: '5', name: '\'Закрыт\', \'Закрыт по неявке\'', text: 'Обработано' },
        ],
      });
    case 'prior':
      return ({
        headerTitleText: 'Все',
        options: {},
        items: [
          { key: '1', name: 'all', text: 'Все' },
          { key: '2', name: 'Высокий', text: 'Высокий' },
          { key: '3', name: 'Обычный', text: 'Обычный' },
        ],
      });
    case 'registered':
      return ({
        headerTitleText: 'Все',
        options: {},
        items: [
          { key: '1', name: 'all', text: 'Все' },
          { key: '2', name: 'Да', text: 'Да' },
          { key: '3', name: 'Нет', text: 'Нет' },
        ],
      });
    case 'ticket_mark':
      return ({
        headerTitleText: 'Все',
        options: {},
        items: [
          { key: '1', name: 'all', text: 'Все' },
          { key: '2', name: '5', text: 'Отлично' },
          { key: '3', name: '4', text: 'Хорошо' },
          { key: '4', name: '3', text: 'Нормально' },
          { key: '5', name: '2', text: 'Плохо' },
          { key: '6', name: '1', text: 'Очень плохо' },
          { key: '7', name: '0', text: 'Без' },
        ],
      });
    default:
      return ({
        headerTitleText: 'Отменить',
        options: {},
        items: [
          { key: 'none', name: 'none', text: 'Отменить' },
          { key: '1', name: '>', text: 'Больше' },
          { key: '2', name: '>=', text: 'Больше или равно' },
          { key: '3', name: '=', text: 'Равно' },
          { key: '4', name: '<', text: 'Меньше' },
          { key: '5', name: '<=', text: 'Меньше или равно' },
          { key: 'range', name: 'range', text: 'Между' },
        ],
      });
  }
};

export default DropdownConfig;
