import React from 'react';
import { columnFormatter } from './utils';

export default function TableConfig(list) {
  const colObjects = [];
  list.forEach((li) => {
    colObjects.push({
      dataIndex: `${li[0]}.avg`,
      title: `${li[1]}`,
      render: (cell, row) => columnFormatter(cell, row, li[0]),
    });
  });

  return {
    columns: [
      {
        dataIndex: 'allCnt',
        title: 'Количество оценок',
        render: (cell) => <div style={{ textAlign: 'center' }}>{cell}</div>,
        width: 203,
      },
      ...colObjects,
    ],
  };
}
