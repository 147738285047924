import React from 'react';
import QueueTable from './QueueReport/QueueTable';
import WorkPlaceTable from './WorkPlaceReport/WorkPlaceTable';
import OperatorTable from './OperatorReport/OperatorTable';
import TicketTable from './TicketReport/TicketTable';
import MarkTable from './MarkReport/MarkTable';
import GeneralReport from './GeneralReport/GeneralReport';
import LineReport from './LineReport/LineReport';
import ClockwiseTable from './ClockwiseReport/ClockwiseTable';
import IntervalReport from './IntervalReport/IntervalReport';
import DynamicsServiceTime from './DynamicsServiceTime/DynamicsServiceTime';
import GroupReport from './GroupReport/GroupReport';
import appStore from '../../../../../../../logic/store/pullstate/appStore';
import OperatorLogi from './OperatorLogi/OperatorLogi';
import SurveyReport from './SurveyReport/SurveyReport';
import PreEntryReport from './PreEntryReport/PreEntryReport';
import SourceReport from './SourceReport/SourceReport';
import SolvedQuestionReport from './SolvedQuestionReport/SolvedQuestionReport';

function ReportBuilder(props) {
  const {
    preloader, view, loader, setLoader, setView,
  } = props;
  console.log('view === ', view);
  const reportConfig = appStore.useState((s) => s.reportConfig);
  const { reportType } = reportConfig;
  switch (reportType) {
    case 'lineReport':
      return (
        <LineReport />
      );
    case 'clockwiseReport':
      return (
        <ClockwiseTable preloader={preloader} />
      );
    case 'groupReport':
      return (
        <GroupReport preloader={preloader} />
      );
    case 'ticketReport':
      return (
        <TicketTable preloader={preloader} />
      );
    case 'operatorReport':
      return (
        <OperatorTable preloader={preloader} />
      );
    case 'queueReport':
      return (
        <QueueTable preloader={preloader} />
      );
    case 'workPlaceReport':
      return (
        <WorkPlaceTable preloader={preloader} />
      );
    case 'markReport':
      return (
        <MarkTable preloader={preloader} />
      );
    case 'generalReport':
      return (
        <GeneralReport
          preloader={preloader}
          view={view}
          loader={loader}
          setLoader={setLoader}
          setView={setView}
        />
      );
    case 'intervalReport':
      return (
        <IntervalReport preloader={preloader} />
      );
    case 'dynamicsServiceTime':
      return (
        <DynamicsServiceTime preloader={preloader} />
      );
    case 'operatorLogi':
      return (
        <OperatorLogi preloader={preloader} />
      );
    case 'surveyReport':
      return (
        <SurveyReport preloader={preloader} />
      );
    case 'preEntryReport':
      return (
        <PreEntryReport preloader={preloader} />
      );
    case 'sourceReport':
      return (
        <SourceReport preloader={preloader} />
      );
    case 'solvedQuestionReport':
      return (
        <SolvedQuestionReport preloader={preloader} />
      );
    default:
      return null;
  }
}

export default ReportBuilder;
