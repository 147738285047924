import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { DoughnutCenterLabel, DoughnutContainer } from '../../../../../../components/report/ReportTableColumns';
import { withButtonsTamplate, DubleLineTemplate } from '../../../utils/formater';

function serviceTmpl(cell, row) {
  const doughnutData = {
    labels: [`Отлично (${row.mark5})`, `Хорошо (${row.mark4})`, `Нормально (${row.mark3})`, `Плохо (${row.mark2})`, `Очень плохо (${row.mark1})`],
    datasets: [{
      data: [row.mark5, row.mark4, row.mark3, row.mark2, row.mark1],
      backgroundColor: [
        '#00FF00',
        '#ADFF2F',
        '#FFFF00',
        '#FF0000',
        '#B22222',
      ],
    },
    ],
  };
  const doughnutOptions = {
    maintainAspectRatio: false,
    responsive: false,
    legend: {
      position: 'right',
      labels: {
        boxWidth: 10,
      },
    },
  };
  return (
    <DoughnutContainer>
      <DoughnutCenterLabel>{row.markcnt}</DoughnutCenterLabel>
      <Doughnut
        data={doughnutData}
        options={doughnutOptions}
      />
    </DoughnutContainer>
  );
}

function TableConfig(moreInfo, doughnutClick, getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'user_fio',
        title: 'Оператор',
        ...getColumnSearchProps('user_fio', 'оператора'),
      }, {
        dataIndex: 'share_cnt',
        title: 'Талонов обработано',
        sorter: (a, b) => a.share_cnt - b.share_cnt,
      }, {
        dataIndex: 'solved_questions',
        title: 'Кол-во решенных вопросов',
      }, {
        dataIndex: '6',
        isDummyField: true,
        title: 'Обслужено',
        render: (cell, row) => withButtonsTamplate(row, moreInfo, 'Operator', row.all_cnt, row.close_cnt, row.neyavka_cnt),
      }, {
        dataIndex: '12',
        isDummyField: true,
        title: 'Время обслуживания',
        render: (cell, row) => DubleLineTemplate('time', 'Всего:', row.total_work_time, 'time', 'Среднее:', row.avg_work_time),
      }, {
        dataIndex: '14',
        isDummyField: true,
        title: 'Качество обслуживания',
        render: (cell, row) => serviceTmpl(cell, row, doughnutClick),
      },
    ],
  };
}

export default TableConfig;
