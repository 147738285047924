import moment from 'moment';

export default function TableConfig() {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Очередь/услуга',
      },
      {
        dataIndex: 'idle_operators',
        title: 'Кол-во операторов',
      },
      {
        dataIndex: 'cnt_clean',
        title: 'В изначальной очереди',
      },
      {
        dataIndex: 'max_initial_wait',
        title: 'Максимальное время ожидания в изначальной очереди',
        render: (cell, row) => moment.utc(row.max_initial_wait * 1000).format('HH:mm:ss'),
      },
      {
        dataIndex: 'cnt_dirty',
        title: 'Ожидание решения',
      },
      {
        dataIndex: 'max_secondary_wait',
        title: 'Максимальное время ожидания решения',
        render: (cell, row) => moment.utc(row.max_secondary_wait * 1000).format('HH:mm:ss'),
      },
    ],
  };
}
