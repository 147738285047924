import React, { useState, useEffect } from 'react';
import {
  Form, Card, Checkbox, Button, Select,
} from 'antd';
import locale from 'antd/es/locale/ru_RU';
import { toast } from 'react-toastify';
import request from '../../../../../../logic/utils/request';

const { Option } = Select;

function DeleteSlots(props) {
  const {
    dayQeueList, qeueList, selectedDate, fetchCalendar,
  } = props;
  const [qeueListOptions, setQeueListOptions] = useState();
  const [deleteAll, setDeleteAll] = useState(false);
  const [selectedQueues, setSelectedQeues] = useState([]);

  const deleteAllToggle = (e) => {
    setDeleteAll(e.target.checked);
  };

  useEffect(() => {
    const test = [];
    dayQeueList.forEach((element) => {
      qeueList.forEach((el) => {
        if (Number(element) === Number(el.id)) test.push(el);
      });
    });
    setQeueListOptions(test);
  }, [dayQeueList]);

  const selectChange = (value) => {
    setSelectedQeues(value);
  };

  const deleteSlots = async () => {
    let qeues;
    if (deleteAll) qeues = dayQeueList.map(Number);
    else qeues = selectedQueues.map(Number);
    const data = {
      q_id: qeues,
    };

    try {
      await request(`/server/api/dcalendar/${selectedDate.id}/`, 'clear-v2', { queues: data.q_id });
      toast.success('Выполнено');
      fetchCalendar();
    } catch (e) {
      const { data: { error: { text: { rus } } } } = e;
      toast.error(rus);
    }
  };

  return (
    <Card style={{ marginTop: 16 }}>
      <Form layout="vertical">
        <Form.Item key="delete" label="Список услуг для удаления слотов">
          <Select
            mode="multiple"
            onChange={selectChange}
            disabled={deleteAll}
            locale={locale.Select}
            filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
          >
            {qeueListOptions?.map((el) => <Option key={Number(el.id)}>{el.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item key="deleteAlltoggle">
          <Checkbox onChange={deleteAllToggle}>Удалить все слоты по всем услугам</Checkbox>
        </Form.Item>

        <Form.Item key="deleteAll">
          <Button onClick={deleteSlots}>Удалить слоты</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default DeleteSlots;
