import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { ReportTitleBox, TableTitle, TableTitleLeftItem } from '../../../../../components/table/TableTitle';
import BackToRootReport from '../../utils/BackToRootReport';
import DataExportConfig from '../../../../../utils/DataExportConfig';
import { BtnAdd } from '../../../../../../../common/ui/Common';

function ReportTitle(props) {
  const {
    reportConfig, name, item, exportDisable,
  } = props;

  let ticketId = '';
  let ticketNumber = '';

  const { renderBackButton } = reportConfig;

  const dataExportConfig = DataExportConfig(props);

  const openNotify = (type, message) => {
    if (type === 'success') {
      toast.success(`${message}`, 1000);
    } else {
      toast.error(`${message}`, 1000);
    }
  };

  const runDataExport = async () => {
    const { option } = dataExportConfig;

    const {
      query, name: nameExport, reportConfig: {
        endDate, startDate,
      },
    } = option;

    let { reportConfig: { reportType } } = option;

    if (nameExport.indexOf('история талона') !== -1) {
      reportType = 'ticketHistoryReport';
      const newRegExp = "ht.ticketId = '(.*)'";
      const newRegExp2 = 'история талона (.*)';
      const newStr = query.match(newRegExp);
      const newStr2 = nameExport.match(newRegExp2);
      // eslint-disable-next-line prefer-destructuring
      ticketNumber = newStr2[1];
      // eslint-disable-next-line prefer-destructuring
      ticketId = newStr[1];
    }

    const filterData = {
      type: reportType,
      startData: startDate,
      endData: endDate,
    };

    if (query) filterData.sqlQuery = query;

    const filters = {
      main: {
        ticket_id: ticketId,
        ticket_number: ticketNumber,
      },
    };

    try {
      const token = window.localStorage.getItem('token');
      const url = '/api/reports/build';
      const res = await axios({
        method: 'post',
        url,
        data: {
          token,
          filters: { ...filters },
          type: reportType,
          exports: 'true',
        },
      });

      const { statusText } = res;
      if (statusText === 'OK') {
        const message = 'Отчет подготавливается для скачивания и появиться в разделе "Архив"';
        openNotify('success', message);
      }
    } catch (err) {
      openNotify('error', err);
    }
  };

  return (
    <ReportTitleBox reportTitile left={renderBackButton}>
      <TableTitleLeftItem>
        {renderBackButton
          ? (
            <BackToRootReport />
          ) : null}
        {item ? (
          `Всего записей: ${item}`
        ) : null}
      </TableTitleLeftItem>
      <TableTitle>{name}</TableTitle>
      <div>
        {(item && exportDisable === undefined)
          ? (<BtnAdd fix={180} onClick={runDataExport}>Экспортировать</BtnAdd>)
          : null}
      </div>
    </ReportTitleBox>
  );
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(ReportTitle);
