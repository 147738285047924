import React from 'react';
import { Table } from 'antd';
import Config from './TableConfig';
import Preloader from '../../../../pages/private/components/preloader/Preloader';
import request from '../../../../logic/utils/request';
import { useSearchPropsFactory } from '../../../../pages/private/utils/useSearch';

function UsersTable({
  paramList, groups, multiData, showError,
}) {
  const handleTableChange = async (param, value) => {
    const dataArr = [{ param, value }];
    await request('/server/api/officeparams/', 'update', dataArr)
      .catch((e) => showError(e.data.error));
  };

  const Setting = Config(groups, handleTableChange, multiData, useSearchPropsFactory());
  const { columns } = Setting;

  const viewParamList = paramList === undefined
    ? <div>Параметров нет</div> : (
      <Table
        dataSource={paramList}
        columns={columns}
      />
    );

  return !paramList ? <Preloader isOpen small /> : viewParamList;
}

export default React.memo(UsersTable);
