import {
  Form, Card, DatePicker, Checkbox, Button, Select,
} from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import locale from 'antd/es/locale/ru_RU';
import { toast } from 'react-toastify';
import request from '../../../../../../logic/utils/request';

const { Option } = Select;

const { RangePicker } = DatePicker;

function CopySlots(props) {
  const {
    dayQeueList, qeueList, selectedDate, fetchCalendar,
  } = props;
  const [qeueListOptions, setQeueListOptions] = useState();
  const [replaseSlot, setReplaseSlot] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [coppyAll, setCoppyAll] = useState(false);
  const [selectedQueues, setSelectedQeues] = useState([]);

  const shangeDate = (dateRange) => {
    setStartDate(moment(dateRange[0]).format('YYYY.MM.DD'));
    setEndDate(moment(dateRange[1]).format('YYYY.MM.DD'));
  };

  const coppyAllToggle = (e) => {
    setCoppyAll(e.target.checked);
  };
  const replaseSlotToggle = (e) => {
    setReplaseSlot(e.target.checked);
  };

  useEffect(() => {
    const test = [];
    dayQeueList.forEach((element) => {
      qeueList.forEach((el) => {
        if (Number(element) === Number(el.id)) test.push(el);
      });
    });
    setQeueListOptions(test);
  }, [dayQeueList]);

  const selectChange = (value) => {
    setSelectedQeues(value);
  };

  const copySlots = async () => {
    let qeues;
    if (coppyAll) qeues = dayQeueList.map(Number);
    else qeues = selectedQueues.map(Number);
    const data = {
      end: endDate,
      begin: startDate,
      queues: qeues,
      replace: replaseSlot,
    };
    try {
      await request(`/server/api/dcalendar/${selectedDate.id}/`, 'copy-v2', data);
      toast.success('Выполнено');
      fetchCalendar();
    } catch (e) {
      const { data: { error: { text: { rus } } } } = e;
      toast.error(rus);
    }
  };

  return (
    <Card style={{ marginTop: 16 }}>
      <Form layout="vertical">
        <Form.Item key={1} label="Список услуг для копирования слотов">
          <Select
            mode="multiple"
            onChange={selectChange}
            disabled={coppyAll}
            locale={locale.Select}
            filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
          >
            {qeueListOptions?.map((el) => <Option key={Number(el.id)}>{el.name}</Option>)}
          </Select>
        </Form.Item>
        <Form.Item key={3}>
          <Checkbox onChange={coppyAllToggle}>Скопировать все слоты по всем услугам</Checkbox>
        </Form.Item>
        <Form.Item key={4} label="Интервал дат для копирования">
          <RangePicker
            onChange={shangeDate}
            locale={locale.DatePicker}
            style={{ width: '100%' }}
            format="DD.MM.YYYY"
          />
        </Form.Item>
        <Form.Item key={5}>
          <Checkbox onChange={replaseSlotToggle}>Перезаписывать слоты</Checkbox>
        </Form.Item>

        <Form.Item key={6}>
          <Button onClick={copySlots}>Копровать слоты</Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default CopySlots;
