import React from 'react';
import { FaCheckSquare } from 'react-icons/fa';
import { ItemModal } from '../../style';

function Buttons({
  onClick, active, item, ticketList,
}) {
  const click = (el) => {
    if (ticketList) {
      onClick(el);
    } else {
      onClick(el.dvb, el.fvid, el.fvn);
    }
  };

  const name = item.fvn || item.fullname || item.name;
  return (
    <ItemModal active={active} onClick={() => click(item)}>
      {item.text || name}
      {item.text && <div className="itemText">{item.text}</div> }
      <FaCheckSquare size="24px" />
    </ItemModal>
  );
}

export default Buttons;
