import React, { useState, useRef, useEffect } from 'react';
import { useToggle } from '../../../../logic/hooks/useToggle';
import { BtnTable } from '../../../../common/ui/Common';
import { TableItem } from '../../../../pages/private/components/table/Table.style';
import { ReactComponent as Config } from '../../../../common/images/config.svg';

function TableInput({
  value, type, update, param,
}) {
  const [cellData, setCellData] = useState(undefined);
  const [valid, setValid] = useToggle(true);
  const inputRef = useRef();
  const handleInput = (e) => {
    const { value: val, checked } = e.target;
    if (type === 'boolean') {
      setCellData(Number(checked));
    } else {
      setCellData(val);
    }
  };
  useEffect(() => {
    if (!valid) {
      inputRef.current.focus();
    }
  }, [valid]);

  return (
    <TableItem data-title={cellData}>
      {(type === 'boolean')
        ? (
          <input
            disabled={valid}
            value={1}
            checked={+cellData}
            ref={inputRef}
            type="checkbox"
            onChange={handleInput}
            onBlur={() => { update(param, cellData); setValid(); }}
          />
        )
        : (
          <input
            disabled={valid}
            value={cellData !== undefined ? cellData : value}
            ref={inputRef}
            type={type}
            onChange={handleInput}
            onBlur={() => { update(param, cellData); setValid(); }}
          />
        )}
      <BtnTable active onClick={() => { setValid(); }}><Config /></BtnTable>
    </TableItem>
  );
}

export default TableInput;
