import React from 'react';
import Buttons from './buttons';

function Information({
  scene, style, bottomBlock, buttons,
}) {
  const block = bottomBlock || scene.bottomBlock || scene;
  return (
    <div style={{ flex: 1, width: '100%' }}>
      {/* eslint-disable-next-line react/no-danger */}
      <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: block.topText || block.data }} />
      {block.buttons && <Buttons buttons={buttons} style={style.button} />}
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: block.bottomText }} />
    </div>
  );
}

export default Information;
