import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Input, Button, Space,
} from 'antd';
import Highlighter from 'react-highlight-words';
import 'antd/dist/antd.min.css';
import { SearchOutlined } from '@ant-design/icons';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import { getReportConfig } from '../../../../../../../../logic/store/redux/reports';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';

function OperatorTable(props) {
  const [queryParams, setQueryParams] = useState();
  const { dispatch, preloader } = props;
  const reportConfig = useSelector((s) => s);

  const moreInfo = useCallback((row, status, type) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        reportType: 'ticketReport',
        rootReport: reportConfig.reportType,
        renderBackButton: true,
        rootTable: type,
        buttonType: status,
        activeRow: row,
      },
    ));
  }, [reportConfig, dispatch]);

  const doughnutClick = useCallback((markValue, operatorId, markText) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        reportType: 'markReport',
        rootReport: reportConfig.reportType,
        renderBackButton: true,
        anyId: operatorId,
        buttonType: markValue,
        markText,
      },
    ));
  }, [reportConfig, dispatch]);

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex, searchItemText) => ({
    // eslint-disable-next-line react/no-unstable-nested-components
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={input}
          placeholder={`Найти ${searchItemText}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Найти
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Очистить
          </Button>
        </Space>
      </div>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => (record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => input.current.select(), 100);
      }
    },
    render: (text) => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    )),
  });

  const preparQueryParams = async (filters) => {
    const columns = TableConfig(moreInfo, doughnutClick, getColumnSearchProps);
    const params = {
      columns: columns.columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'operatorReport',
    };
    setQueryParams(params);
  };

  useEffect(() => {
    preparQueryParams();
  }, []);

  return (queryParams ? (
    <>
      <ReportFilters
        showFilters={{
          main: ['reportRange', 'operators'],
          others: ['actionFilters', 'servedfilters', 'workTimeFilter', 'markFilters'],
          orders: ['user_fio', 'all_cnt', 'close_cnt', 'neyavka_cnt', 'total_work_time', 'avg_work_time', 'share_cnt', 'solved_questions',
            'mark1', 'mark2', 'mark3', 'mark4', 'mark5', 'markcnt'],
        }}
        paramsCallback={preparQueryParams}
        type="operatorReport"
      />
      <PrepareTable
        keys="operator"
        type="OperatorTable"
        {...queryParams}
        preloader={preloader}
      />
    </>
  ) : null);
}

export default OperatorTable;
