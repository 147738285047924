import React, { useState, useCallback, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import SqlQuery from './SqlQuery';
import SqlQueryCloseCnt from './SqlQueryCloseCnt';
import SqlQueryNoneCnt from './SqlQueryNoneCnt';
import BasicLineReportSettings from './BasicLineReportSettings';
import ReportTitle from '../../../ReportTitle';
import { ReportBox } from '../../../../../../../../components/report/ReportTableColumns';
import Preloader from '../../../../../../../../components/preloader/Preloader';
import { PreloaderBox } from '../../../../../../../../../../common/ui/Common';
import appStore from '../../../../../../../../../../logic/store/pullstate/appStore';

function ReportLine(props) {
  const { view, loader, setLoader } = props;
  const [ReportParams, setReportParams] = useState();
  const startDateMoment = appStore.useState((s) => s.reportConfig.startDate);
  const endDateMoment = appStore.useState((s) => s.reportConfig.endDate);
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const startDate = startDateMoment.format(dateFormat);
  const endDate = endDateMoment.format(dateFormat);

  const answerFnc = useCallback(async () => {
    setLoader(true);
    const createValues = await SqlQuery(startDate, endDate);
    const closeValues = await SqlQueryCloseCnt(startDate, endDate);
    const noneValues = await SqlQueryNoneCnt(startDate, endDate);
    const BasicSettings = BasicLineReportSettings();

    const dataGraph = {};
    const dataClose = {};
    const dataNone = {};

    for (let i = 0; i < 24; i += 1) {
      dataGraph[i] = 0;
      dataClose[i] = 0;
      dataNone[i] = 0;
    }

    createValues.forEach((element) => {
      dataGraph[Number(element[1])] = Number(element[0]);
    });

    closeValues.forEach((element) => {
      dataClose[Number(element[1])] = Number(element[0]);
    });

    noneValues.forEach((element) => {
      dataNone[Number(element[1])] = Number(element[0]);
    });

    const allCntData = [];
    for (let i = 0; i < 24; i += 1) {
      allCntData.push(Number(Object.values(dataNone)[i]) + Number(Object.values(dataClose)[i]));
    }

    const allData = {
      ...BasicSettings,
      label: 'Всего обслужено',
      data: allCntData,
      pointHoverBackgroundColor: '#7486fc',
      pointBorderColor: '#7486fc',
      borderColor: '#7486fc',
      backgroundColor: 'rgba(3, 82, 252, 0.4)',
    };

    const noneData = {
      ...BasicSettings,
      label: 'Закрыто по неявке',
      data: Object.values(dataNone),
      pointHoverBackgroundColor: '#f75454',
      pointBorderColor: '#f75454',
      borderColor: '#f75454',
      backgroundColor: 'rgba(252, 5, 5, 0.4)',
    };

    const createData = {
      ...BasicSettings,
      label: 'Создано',
      data: Object.values(dataGraph),
      pointHoverBackgroundColor: '#019421',
      pointBorderColor: '#019421',
      borderColor: '#019421',
      backgroundColor: 'rgba(2, 179, 40, 0.4)',
    };

    const closeData = {
      ...BasicSettings,
      label: 'Закрытых',
      data: Object.values(dataClose),
      pointHoverBackgroundColor: '#20a8d8',
      pointBorderColor: '#20a8d8',
      borderColor: '#20a8d8',
      backgroundColor: 'rgba(75, 192, 192, 0.4)',
    };

    const ReportSettings = {
      labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
      datasets: [createData, allData, closeData, noneData],
    };
    setReportParams(ReportSettings);
    setLoader(null);
  }, [endDate, startDate]);

  useEffect(() => {
    if (view) {
      answerFnc();
    }
  }, [answerFnc, endDate, startDate]);

  return (
    <ReportBox>
      <ReportTitle
        name="Распределение талонов по часам"
      />
      { ReportParams
        && (
        <div style={{ height: '300px' }}>
          <Line
            data={ReportParams}
            height={50}
            options={{
              maintainAspectRatio: false,
              intersect: true,
            }}
          />
        </div>
        )}
      { loader
        && (
          <PreloaderBox>
            <Preloader isOpen small />
          </PreloaderBox>
        )}
    </ReportBox>
  );
}

export default ReportLine;
