import { Route } from 'react-router-dom';
import React from 'react';
import { Main as MainRoutes } from '../routes/routes';
import bottomButtons from '../common/bottomButtons';

export const getExtraRoutes = ({
  headUrl,
  selectScreen,
  config,
  startScreen,
  style,
  props,
  params,
}) => MainRoutes.map((router) => (
  <Route
    path={router.name}
    key={router.name}
    element={(
      <router.component
        exact
        scene={{}}
        selectScreen={selectScreen}
        config={config}
        startScreen={startScreen}
        style={style}
      >
        {router.buttons ? bottomButtons({
          ...props,
          scene: {},
          startScreen,
          params,
          style: style.bottomButton,
          noBack: router.noBack,
          headUrl,
        }) : null}
      </router.component>
      )}
  />
));

export default null;
