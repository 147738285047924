import React, { useState, useEffect } from 'react';
import {
  Drawer, Collapse, Card, List, Col,
  Row, Statistic, Button, Tooltip, Switch,
} from 'antd';
import { OrderedListOutlined } from '@ant-design/icons';
import { SelectBox } from '../style';
import CopySlots from './CopySlots';
import DeleteSlots from './DeleteSlots';
import TimeSlots from './timeSlots';
import ResetSlots from './ResetSlots';
import groupingSlots from './utils/groupingSlots';
import randomId from '../../../../../../logic/utils/randomId';

const { Panel } = Collapse;

const compactStyle = {
  textAlign: 'center',
  lineHeight: 'initial',
};

function Record({
  selectedDate,
  qeueList,
  fetchCalendar,
}) {
  const [time, setTime] = useState([]);
  const [sTime, setSTime] = useState(null);
  const [qId, setQid] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCopyDrawer, setOpenCopyDrawer] = useState(false);
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);
  const [openResetSlotsDrawer, setOpenResetSlotsDrawer] = useState(false);
  const [compactMode, setCompactMode] = useState(
    localStorage.getItem('preEntryCompactMode') === 'true',
  );

  const { data: selectedDateData } = selectedDate;

  const find = (t, q) => {
    const reg = new RegExp(t);
    const times = selectedDate.data.filter((el) => reg.test(el.pastart_on) && el.q_id === q);
    setTime(times);
  };

  const slots = groupingSlots(selectedDateData, qeueList);

  const findRecord = (t, q) => {
    setSTime(t);
    setQid(q);
    find(t, q);
    setOpenDrawer(true);
  };

  useEffect(() => {
    find(sTime, qId);
  }, [selectedDate, sTime, qId]);

  const toggleCompactMode = (value) => {
    setCompactMode(value);
    localStorage.setItem('preEntryCompactMode', value);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>

      <SelectBox className={`recordBig ${compactMode && 'recordBigCompact'} `}>
        <div className="titleConstructor">
          <p className="">
            Список слотов на
            {' '}
            {selectedDate.day}
          </p>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          <Switch
            checkedChildren="Компактно"
            unCheckedChildren="Обычно"
            defaultChecked={compactMode}
            onChange={toggleCompactMode}
            style={{ maxWidth: '95px' }}
          />
          <div style={{ margin: '8px 0px' }}>
            <Button onClick={() => { setOpenCopyDrawer(true); }}>
              Копировать слоты
            </Button>
            <Button onClick={() => { setOpenDeleteDrawer(true); }} style={{ marginLeft: '8px' }}>
              Удалить слоты
            </Button>
            <Button onClick={() => { setOpenResetSlotsDrawer(true); }} style={{ marginLeft: '8px' }}>
              Перезаписать слот
            </Button>
          </div>
        </div>

        <Collapse>
          {Object.keys(slots).map((slot) => {
            const registered = slots[slot].filter((el) => el.emptySlotsLength === 0);
            return (
              <Panel header={`${slots[slot][0].qName} (записано: ${registered.length} / свободно: ${slots[slot].length - registered.length} / всего: ${slots[slot].length})`} key={randomId()}>
                <List
                  grid={{
                    gutter: compactMode ? 4 : 16,
                    column: compactMode ? 15 : 4,
                  }}
                  dataSource={slots[slot]}
                  renderItem={(item) => (
                    <List.Item>
                      <Card
                        size={compactMode ? 'small' : 'default'}
                        title={item.time}
                        extra={(
                          <Button
                            size={compactMode ? 'small' : 'default'}
                            shape={compactMode && 'circle'}
                            icon={compactMode && <OrderedListOutlined />}
                            onClick={() => findRecord(item.time, item.q_id)}
                          >
                            {!compactMode && 'Показать'}
                          </Button>
                                )}
                      >
                        <Row gutter={4}>
                          <Col span={12}>
                            <Tooltip title="Всего">
                              <Card
                                size={compactMode ? 'small' : 'default'}
                              >
                                <Statistic
                                  size={compactMode ? 'small' : 'default'}
                                  title={compactMode ? null : 'Всего'}
                                  value={item.length}
                                  valueStyle={{
                                    ...(compactMode && { ...compactStyle }),
                                    color: '#454545',
                                  }}
                                />
                              </Card>
                            </Tooltip>
                          </Col>
                          <Col span={12} onClick={() => findRecord(item.time, item.q_id)}>
                            <Tooltip title="Свободно">
                              <Card
                                size={compactMode ? 'small' : 'default'}
                              >
                                <Statistic
                                  size={compactMode ? 'small' : 'default'}
                                  title={compactMode ? null : 'Свободно'}
                                  value={item.emptySlotsLength}
                                  valueStyle={{
                                    color: '#3f8600',
                                    ...(compactMode && { ...compactStyle }),
                                  }}
                                />
                              </Card>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Card>
                    </List.Item>
                  )}
                />
              </Panel>
            );
          })}
        </Collapse>
      </SelectBox>

      <Drawer
        title={`Копирование слотов за ${selectedDate.day}`}
        placement="right"
        onClose={() => { setOpenCopyDrawer(false); }}
        visible={openCopyDrawer}
        width={660}
      >
        <CopySlots
          dayQeueList={Object.keys(slots).map((idx) => idx)}
          qeueList={qeueList}
          selectedDate={selectedDate}
          fetchCalendar={fetchCalendar}
        />
      </Drawer>

      <Drawer
        title={`Перезапись слотов за ${selectedDate.day}`}
        placement="right"
        onClose={() => { setOpenResetSlotsDrawer(false); }}
        visible={openResetSlotsDrawer}
        width={660}
      >
        <ResetSlots
          dayQeueList={Object.keys(slots).map((idx) => idx)}
          qeueList={qeueList}
          selectedDate={selectedDate}
          fetchCalendar={fetchCalendar}
        />
      </Drawer>

      <Drawer
        title={`Удаление слотов за ${selectedDate.day}`}
        placement="right"
        onClose={() => { setOpenDeleteDrawer(false); }}
        visible={openDeleteDrawer}
        width={660}
      >
        <DeleteSlots
          dayQeueList={Object.keys(slots).map((idx) => idx)}
          qeueList={qeueList}
          selectedDate={selectedDate}
          fetchCalendar={fetchCalendar}
        />
      </Drawer>

      <Drawer
        title={`Слоты на ${selectedDate.day} ${time[0]?.pastart_on?.split(' ')[1]}`}
        placement="right"
        onClose={() => { setOpenDrawer(false); }}
        visible={openDrawer}
      >
        <TimeSlots
          time={time}
          fetchCalendar={fetchCalendar}
          selectedDate={selectedDate}
        />
      </Drawer>
    </div>
  );
}

export default Record;
