import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Button, Select, Space } from 'antd';
import { Title, ContentBox, PreloaderBox } from '../../../../common/ui/Common';
import request from '../../../../logic/utils/request';
import { Box } from './style';
import Table from './table';
import Preloader from '../../../../pages/private/components/preloader/Preloader';

function Session({ ...props }) {
  const [sessions, setSessions] = useState([]);
  const [users, setUsers] = useState([]);
  const [id, setId] = useState(false);

  const getData = useCallback(async () => {
    const { data: { data } } = await request('/server/api/usersession/');
    const fusers = await request('/server/api/user/');

    setSessions([...data]);
    setUsers([...fusers.data.data]);
  }, []);

  const addSessuion = async () => {
    const d = {
      user_id: id,
      sessioncomment: 'Constant session',
      config: {},
    };
    try {
      await request('/server/api/usersession/', 'add', d);
      toast.success('Выполнено');
    } catch (e) {
      toast.error(e);
    }
    getData();
  };

  useEffect(() => { getData(); }, [getData]);

  const options = users.map((el) => ({ name: el.username, id: el.id }));

  return (
    <div>
      <Title>Сессии</Title>
      <ContentBox>
        <Box>
          <p>Создание постоянной сессии для пользователя</p>
          <Space style={{ justifyContent: 'center' }} direction="horizontal" size="middle">
            <Select
              value={id || (users[0] ? users[0].id : '')}
              onChange={setId}
            >
              {options.map(({ id: itemId, name }) => (
                <Select.Option value={itemId} key={itemId}>{name}</Select.Option>
              ))}
            </Select>
            <Button onClick={addSessuion} type="primary">Добавить</Button>
          </Space>
        </Box>
        <PreloaderBox>
          {sessions.length === 0 && users.length === 0
            ? <Preloader isOpen small />
            : <Table users={users} {...props} fetchData={getData} data={sessions} />}
        </PreloaderBox>
      </ContentBox>
    </div>
  );
}

export default Session;
