import IconFormatter from '../../../../pages/private/utils/iconFormatter';

export default function ConfigTable(searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'name',
        title: 'Наименование',
        ...searchPropsFactory('name', 'наименованию'),
      },
      {
        dataIndex: 'select_by_default',
        title: 'По умолчанию',
        ...searchPropsFactory('select_by_default', 'умолчанию'),
        width: 150,
        render: (_, value) => (value ? 'Да' : 'Нет'),
      },
      {
        dataIndex: 'connected_queues',
        title: 'Подключенные услуги',
        ...searchPropsFactory('connected_queues', 'подключенным услугам'),
        render: (_, rec) => (rec.connected_queues.map((el) => (
          <p>{el.name}</p>
        ))),
      },
      {
        dataIndex: 'Settings',
        width: 150,
        render: () => IconFormatter({
          filter: false, write: true, params: true, subscribe: false,
        }),
      },
    ],
  };
}
