export default (answer) => {
  const data = [];
  const { columnNames, data: answerData } = answer;

  answerData.forEach((dv) => {
    const rElem = {};
    dv.forEach((v, idx) => {
      rElem[columnNames[idx]] = v;
    });
    data.push(rElem);
  });

  return data;
};
