import React from 'react';
import DataPickers from './DatePickers/DatePickers';
import ReportButtons from './ReportButtons/ReportButtons';

function NavigationMenu(props) {
  const { reportType, setView } = props;

  return (
    <div style={{ marginLeft: 7, marginBottom: 50 }}>
      <ReportButtons />
      {[
        'ticketReport',
        'clockwiseReport',
        'operatorReport',
        'operatorLogi',
        'queueReport',
        'groupReport',
        'workPlaceReport',
        'intervalReport',
        'dynamicsServiceTime',
        'surveyReport',
        'preEntryReport',
        'sourceReport',
        'solvedQuestionReport',
      ].includes(reportType)
        ? null
        : (
          <DataPickers
            reportType={reportType}
            setView={setView}
          />
        )}
    </div>
  );
}

export default NavigationMenu;
