import React from 'react';
import { PreContainer } from './style';
import Calendar from './components/calendar';
import preEntryStore from '../../../../../logic/store/pullstate/preEntryStore';

function CalendarBox() {
  const qeueList = preEntryStore.useState(((s) => s.qeueList));

  return (
    <PreContainer>
      <Calendar
        qeueList={qeueList}
      />
    </PreContainer>
  );
}

export default CalendarBox;
