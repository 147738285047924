import React from 'react';
import QTable from './QTable';

function ConsolidatedReport({ settings, preloader, activeSection }) {
  return (
    <QTable
      settings={settings}
      preloader={preloader}
      activeSection={activeSection}
    />
  );
}

export default (ConsolidatedReport);
