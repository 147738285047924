import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Card, Form, Input, Tooltip, Button, Statistic,
} from 'antd';
import md5 from 'md5';
import {
  SaveOutlined, DeleteOutlined, CheckOutlined, FormOutlined, CopyOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import request from '../../../../../../logic/utils/request';

function Time(props) {
  const {
    el: {
      registered, clientfio, clientmail,
      clientphone, q_id: qId, pastart_on: pastartOn,
      calendardayId, calendarCellId, pincode,
    },
    fetchCalendar,
    selectedDate,
  } = props;

  const [state, setState] = useState({
    clientfio: { value: clientfio, label: 'ФИО' },
    clientphone: { value: clientphone, label: 'Телефон' },
    clientmail: { value: clientmail, label: 'E-mail' },
  });

  const onSave = async (data, id) => {
    try {
      await request(`/server/api/dpacalendarslot/${id}/`, 'update', data);
      fetchCalendar();
      toast.success('Выполнено');
    } catch (e) {
      toast.error('Действие не возможно, пожалуйста обновите страницу и попробуйте еще');
    }
  };

  const onDelete = async () => {
    try {
      await request(`/server/api/dpacalendarslot/${calendarCellId}/`, 'delete');
      toast.success('Выполнено.');
      fetchCalendar();
    } catch (e) {
      toast.error('Действие не возможно, пожалуйста обновите страницу и попробуйте еще');
    }
  };

  const unRegistredSlot = async () => {
    try {
      await request(`/server/api/dpacalendarslot/${calendarCellId}/`, 'clear', {
        calendarCellId,
      });
      toast.success('Выполнено.');
      fetchCalendar();
    } catch (e) {
      toast.error('Действие не возможно, пожалуйста обновите страницу и попробуйте еще');
    }
  };

  const registredSlot = async () => {
    const cardData = {
      calendarCellId,
      clientEmail: state.clientmail.value,
      clientPhoneNumber: state.clientphone.value,
      clientFIO: state.clientfio.value,
    };

    const ordered = {};
    Object.keys(cardData).sort().forEach((key) => {
      ordered[key] = cardData[key];
    });

    let hashString = '';
    // eslint-disable-next-line no-return-assign
    Object.keys(ordered).forEach((el) => hashString += cardData[el]);
    cardData.sign = md5(hashString);

    try {
      const res = await axios.post('/server/public/preorder/register', { data: cardData });
      if (res.data.error) throw res;
      toast.success('Вы записаны.');
      fetchCalendar();
    } catch (e) {
      toast.error('Действие не возможно, пожалуйста обновите страницу и попробуйте еще');
    }
  };

  return (
    <Card
      style={{
        marginTop: 16,
      }}
      actions={[
        <Tooltip title="Удалить слот">
          <Button
            key="deleted"
            icon={<DeleteOutlined />}
            onClick={onDelete}
            disabled={registered}
          />
        </Tooltip>,
        <Tooltip title="Сохранить данные">
          <Button
            key="save"
            icon={<SaveOutlined />}
            disabled={registered}
            onClick={() => {
              onSave({
                clientfio: state.clientfio.value,
                clientphone: state.clientphone.value,
                clientmail: state.clientmail.value,
                qId,
                pastartOn,
                calendardayId,
                approved: true,
              }, calendarCellId);
            }}
          />
        </Tooltip>,
        registered ? (
          <Tooltip title="Перезаписать слот">
            <Button
              icon={<FormOutlined />}
              onClick={unRegistredSlot}
              disabled={moment().add(-1, 'day').isAfter(selectedDate)}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Записать слот">
            <Button
              icon={<CheckOutlined />}
              onClick={registredSlot}
            />
          </Tooltip>
        ),
      ]}
    >
      <Form
        layout="vertical"
      >
        {Object.keys(state).map((key) => (
          <Form.Item key={key} label={state[key].label}>
            <Input
              disabled={registered}
              defaultValue={state[key].value}
              onChange={(e) => setState(
                {
                  ...state,
                  [key]: { ...state[key], value: e.target.value },
                },
              )}
            />
          </Form.Item>
        ))}
      </Form>
      {registered
      && (
      <Statistic
        title="пин-код"
        value={pincode}
        groupSeparator=""
        suffix={(
          <CopyToClipboard
            text={pincode}
            onCopy={() => toast.success('Пин-код скопирован')}
          >
            <CopyOutlined />
          </CopyToClipboard>
        )}
      />
      )}
      {registered && (
      <p style={{
        marginTop: '15px',
        textAlign: 'center',
        fontSize: '12px',
        color: '#9b9696',
      }}
      >
        Слот зарегистрирован, для дальнейшего взаимодействия нажмите на кнопку «Перезаписать слот»
      </p>
      )}
    </Card>

  );
}
export default Time;
