import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Table, Typography } from 'antd';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import TableConfig from './TableConfig';
import Preloader from '../../../../../components/preloader/Preloader';
import { PreloaderBox } from '../../../../../../../common/ui/Common';

function QTable({ settings, preloader }) {
  const [ReportParams, setReportParams] = useState(undefined);
  const { startDate, endDate } = settings;
  const tableContainerRef = useRef(null);

  const fetchReportData = async () => {
    preloader();
    const url = '/api/reports/build';
    const type = 'consReport';
    const token = window.localStorage.getItem('token');
    const res = await axios({
      method: 'post',
      url,
      data: {
        token,
        filters: { main: { startDate, endDate } },
        type,
      },
    });
    const params = {
      filters: { main: { startDate, endDate } },
      items: res.data.answer.myData,
      TableConfig: TableConfig(res.data.answer.queryList.data, res.data.answer.myData),
      TebleType: 'ConsReport',
    };
    setReportParams(params);

    preloader();
  };

  useEffect(() => {
    fetchReportData();
  }, [settings]);

  useEffect(() => {
    if (tableContainerRef) {
      const { current = new HTMLDivElement() } = tableContainerRef;
      const table = current.querySelector('table');
      if (table) {
        table.id = 'table-to-xls';
      }
    }
  }, [tableContainerRef, ReportParams]);

  return (
    <div ref={tableContainerRef}>
      {
      ReportParams ? (
        <>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>
            Сводный отчет
          </Typography.Title>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={`Сводный отчет ${startDate} - ${endDate}`}
            sheet="tablexls"
            buttonText="Экспортировать"
          />
          <div style={{ width: 'calc(100vw - 400px)' }}>
            <Table
              scroll={{ x: true }}
              dataSource={ReportParams.items}
              columns={ReportParams.TableConfig.columns}
            />
          </div>
        </>
      ) : (
        <PreloaderBox>
          <Preloader isOpen small />
        </PreloaderBox>
      )
    }
    </div>
  );
}

export default QTable;
