import React from 'react';
import Operator from './Tables/Operator/Table';
import Queue from './Tables/Queue/Table';
import ReportLine from './Graphic/Line/ReportLine';
import ReportBar from './Graphic/Bar/ReportBar';
import ReportCard from './Card/ReportCard';
import DoughnutReport from './Graphic/Dounghnuts/DoughnutsReport';
import AvgTimeGraphic from './Graphic/AvgTimeGraphic/AvgTimeGraphic';

function GeneralReport(props) {
  return (
    <>
      <ReportCard
        view={props.view}
        setView={props.setView}
      />
      <AvgTimeGraphic
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
      />
      <ReportBar
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
      />
      <ReportLine
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
      />
      <DoughnutReport
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
      />
      <Operator
        view={props.view}
        loader={props.loader}
        setLoader={props.setLoader}
      />
      <Queue
        view={props.view}
      />
    </>
  );
}

export default GeneralReport;
