// STATISTICS
import Monitoring from '../subpages/statistics/monitoring/Monitoring';
import Reports from '../subpages/statistics/reports/Reports';
import QualityControl from '../subpages/statistics/quality-control/QualityControl';
import Archive from '../subpages/statistics/archive/Archive';

// QUEUE
import Fields from '../subpages/queue/fields/Fields';
import Groupings from '../subpages/queue/groupings/Groupings';
import Services from '../../../old/views/Queue/Services/Services';
import WorkPlaces from '../subpages/queue/workplaces/WorkPlaces';

// CONTROL
import Devices from '../../../old/views/Control/Devices/Devices';
// ---
import DeviceStyles from '../../../old/views/Control/Devices/DeviceStyle2/DeviceStyle2';
// ---
import DigitBoard from '../../../old/views/Control/Displays/Displays';
import HandlePoints from '../../../old/views/Control/HandlePoints/HandlePoints';
import Screens from '../../../old/views/Control/HandlePoints/Screens/Screens';
import ScreensEdit from '../../../old/views/Control/HandlePoints/Screens/Screen/ScreensEdit';
import Tickets from '../../../old/views/Control/Tickets/Tikets';
import TicketConstructor from '../../../old/views/Control/Tickets/TicketConstructor';
import Users from '../../../old/views/Control/Users/Users';
import QualityTablet from '../../../old/views/Control/QualityTablet/QualityTablet';

// SETTINGS
import About from '../subpages/settings/about/About';
import ActionLog from '../../../old/views/Settings/ActionLog/ActionLog';
import Custom from '../../../old/views/Settings/Custom/Custom';
import Params from '../../../old/views/Settings/Params/Params';
import Sync from '../../../old/views/Settings/Sync/Sync';
// import Lottery from '../views/Settings/Lottery';
import Reserve from '../../../old/views/Settings/Reserve/Reserve';
import Session from '../../../old/views/Settings/Session';

// OPERATOR
import Operator from '../../../old/views/Operator/Operator';
import PreEntry from '../../../old/views/Queue/Services/PreEntry';

export default [
  // statistics
  { name: '/monitoring/', value: Monitoring },
  { name: '/qualityControl/', value: QualityControl },
  { name: '/reports/', value: Reports },
  { name: '/reports/*', value: Reports },
  { name: '/archive/', value: Archive },
  // queue
  { name: '/fields/', value: Fields },
  { name: '/services/', value: Services },
  { name: '/services/:name/', value: Services },
  { name: '/services/:name/:ticketId', value: Services },
  { name: '/workplaces/', value: WorkPlaces },
  { name: '/pre-entry/', value: PreEntry },
  { name: '/groupings/', value: Groupings },
  // device
  { name: '/devices/', value: Devices },
  { name: '/devices/:id/', value: DeviceStyles },
  { name: '/devices/:id/scanPolicy', value: DeviceStyles },
  { name: '/devices/:id/scanPolicy/:page', value: DeviceStyles },
  { name: '/devices/:id/emulatePreRecord', value: DeviceStyles },
  { name: '/devices/:id/*', value: DeviceStyles },
  { name: '/digit-board/', value: DigitBoard },
  { name: '/handle-points/', value: HandlePoints },
  { name: '/handle-points/:id', value: Screens },
  { name: '/handle-points/:id/:screenId', value: ScreensEdit },
  { name: '/tickets/', value: Tickets },
  { name: '/tickets/ticketConstructor/', value: TicketConstructor },
  { name: '/tickets/ticketConstructor/', value: TicketConstructor },
  { name: '/tickets/ticketConstructor/:id', value: TicketConstructor },
  { name: '/users/', value: Users },
  { name: '/quality-tablet/', value: QualityTablet },
  // operator
  { name: '/operator/', value: Operator },
  // settings
  { name: '/about/', value: About },
  { name: '/action-log/', value: ActionLog },
  { name: '/custom/', value: Custom },
  { name: '/custom/:id', value: Custom },
  { name: '/params/', value: Params },
  { name: '/reserve/', value: Reserve },
  { name: '/session/', value: Session },
  { name: '/sync/', value: Sync },
];
