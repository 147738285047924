import { Store } from 'pullstate';
import moment from 'moment';
import 'moment/locale/ru';

const appStore = new Store({
  reportConfig: {
    startDate: moment().startOf('month'),
    endDate: moment().endOf('day'),
    reportType: 'ticketReport',
    reportTitle: 'По талонам',
  },
});
// generalReport
export default appStore;

export const getReportConfig = () => {
  const cState = appStore.getRawState();
  return { ...cState.reportConfig };
};

export const updateReportConfig = (newReportConfig) => {
  const cState = appStore.getRawState();
  const { reportConfig } = cState;
  appStore.update((s) => {
    // eslint-disable-next-line no-param-reassign
    s.reportConfig = {
      ...reportConfig,
      ...newReportConfig,
    };
  });
};
