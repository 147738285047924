import React, {
  useEffect, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Body } from '../../../utils/style';
import Request from '../request';
import {
  resetTicket, setTextError, setPre,
} from '../../../../store/actions';

function CanvasPre({
  style, data, config, dispatch, startScreen, errorUrl,
}) {
  const { pin, service, date } = data;

  console.log('  qq  data === ', data);
  console.log('data === ', data);

  const time = useRef(null);
  const navigate = useNavigate();
  const printTicket = async (body) => {
    const { satelliteIp, satellitePort } = config;
    await Request('/proxySatellite/gate/print', 'no', {
      ...body,
      satelliteIp,
      satellitePort,
    })
      .then((res) => {
        if (!res.data.success) {
          // eslint-disable-next-line no-throw-literal
          throw 'Ошибка печати. Проверьте принтер.';
        }
        time.current = setTimeout(() => {
          navigate(startScreen);
          dispatch(setPre({}));
          dispatch(resetTicket());
          clearTimeout(time.current);
        }, 5000);
      })
      .catch((res) => {
        navigate(errorUrl);
        dispatch(setTextError(res));
      });
  };

  const splitString = (string, maxLength, ctx, x, y) => {
    const splittedString = string.split(' ');
    let newString;
    const countedArray = splittedString.map((word) => word.length);
    let str;
    if (countedArray.reduce((a, b) => a + b) > maxLength) {
      newString = splittedString.splice(3).toString().replace(/,/g, ' ');
      str = splittedString.toString().replace(/,/g, ' ');
      splitString(newString, maxLength, ctx, x, y + 20);
    } else {
      return ctx.fillText(string, x, y);
    }
    return ctx.fillText(str, x, y);
  };

  const ref = useRef(null);

  useEffect(() => {
    const ctx = ref.current.getContext('2d');
    ref.current.width = 500;
    ref.current.height = 500;
    ctx.clearRect(0, 0, ctx.width, ctx.height);
    ctx.fillStyle = '#fff';
    ctx.rect(0, 0, 500, 500);
    ctx.fill();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'top';
    ctx.fillStyle = '#000';
    ctx.font = `bold 28px ${style.button.fontFamily || 'Courier New'}`;
    ctx.fillText('Ваш PIN-CODE', 250, 140);
    ctx.font = `bold 56px ${style.button.fontFamily || 'Courier New'}`;
    ctx.fillText(`${pin}`, 250, 200);
    ctx.font = `bold 28px ${style.button.fontFamily || 'Courier New'}`;
    splitString(service, 20, ctx, 250, 280);
    ctx.font = `bold 18px ${style.button.fontFamily || 'Courier New'}`;
    ctx.fillText(date, 250, 450);
  }, [date, pin, service, style.button.fontFamily]);

  useEffect(() => {
    const base64 = ref.current.toDataURL();
    const body = { data: base64.split(',')[1] };
    printTicket(body);
  }, [printTicket]);

  return (
    <Body style={style.body}>
      <canvas ref={ref} style={{ width: 500, height: 500, position: 'relative' }} />
    </Body>
  );
}

export default CanvasPre;
