import React from 'react';
import Field from './Field';
import request from '../../../../../logic/utils/request';
import { ParamsBox, Text } from '../../../../../pages/private/subpages/queue/workplaces/Parameters/style';
import CheckBoxList from '../../../../../pages/private/components/CheckBoxList';

function ParamsItems({
  defParams, newParams, userId, fetchNewParams, write,
}) {
  const handleRequest = (id, action, data) => {
    request(`/server/api/officeparamsbinding/${id}/`, action, data)
      .then(() => { fetchNewParams(); });
  };

  const setParam = (id, newValue) => {
    const data = {
      params: [{
        officeparam_id: id,
        overridevalue: newValue,
      }],
      user_id: userId,
    };

    newParams.forEach((item) => {
      data.params.push({
        officeparam_id: item.officeparam_id,
        overridevalue: item.overridevalue,
      });
    });
    request('/server/api/officeparamsbinding/', 'add', data)
      .then(() => fetchNewParams());
  };

  const onSave = (value, id, type) => {
    let defValue;
    let newValue = value;
    if (type === 'number' && value === '') newValue = 0;
    request(`/server/api/officeparams/${id}/`, 'get') // get default param
      .then((res) => {
        defValue = res.data.data[0].value;
        if (newParams.length) {
          let add = true;
          newParams.forEach((newItem) => {
            if (newItem.officeparam_id === id) {
              add = false; // if this input is already changed then update or delete
              if (defValue === newValue) {
                handleRequest(newItem.id, 'delete'); // delete existing binding
              } else {
                const data = {
                  overridevalue: newValue,
                };
                handleRequest(newItem.id, 'update', data); // update existing binding
              }
            }
          });
          if (add) { //  add new binding if newParams exists but this input hasnt been
            setParam(id, newValue); //  changed before
          }
        } else if (defValue !== newValue) { // add new binding if no newParams
          setParam(id, newValue);
        }
      });
  };

  const resetParam = (id) => {
    newParams.forEach((item) => {
      if (item.officeparam_id === id) {
        handleRequest(item.id, 'delete');
      }
    });
  };

  const operatorHideWidgetsData = [
    { id: 1, key: 'fullname', name: 'Полное имя клиента в талоне' },
    { id: 2, key: 'phonenumber', name: 'Номер телефона клиента в талоне' },
    { id: 3, key: 'email', name: 'E-mail клиента в талоне' },
    { id: 4, key: 'comment', name: 'Комментарий к талону' },
    { id: 5, key: 'refundv', name: 'Кнопка "Вернуть в очередь" в окне валидации' },
    { id: 6, key: 'questioncount', name: 'Счетчик решенных вопросов' },
    { id: 7, key: 'filters', name: 'Кнопка "Подключенные филтры"' },
  ];

  const setUserData = (e) => {
    const disableElements = [];
    const { connectedRoles: disableElementsId } = e;
    disableElementsId.forEach((disableId) => {
      operatorHideWidgetsData.forEach((element) => {
        if (element.id === disableId) {
          disableElements.push(element.key);
        }
      });
    });

    onSave(disableElements.join(','), 6, 'string');
  };

  const renderParams = () => {
    if (newParams.length) {
      const cloneDefParams = [];
      return (defParams.map((defItem, i) => {
        cloneDefParams.push({ ...defItem });
        newParams.forEach((newItem) => {
          if (newItem.officeparam_id === defItem.id) {
            cloneDefParams[i].value = newItem.overridevalue; // rewrite defParam to binding
            cloneDefParams[i].reset = true; // set reset button
          }
        });
        if (defItem.param === 'operatorHideWidgets') {
          const checedValues = [];
          let dbcheced;
          const param = newParams.find((el) => (el.officeparam_id === 6));
          if (param) {
            dbcheced = param.overridevalue.split(',');
          } else {
            dbcheced = defItem.value.split(',');
          }

          dbcheced.forEach((el) => {
            operatorHideWidgetsData.forEach((element) => {
              if (element.key === el) {
                checedValues.push(element.id);
              }
            });
          });

          return (
            <div>
              <Text>{defItem.description}</Text>
              <CheckBoxList
                item={{
                  label: 'Элементы:', name: 'connectedRoles', options: operatorHideWidgetsData, checked: checedValues,
                }}
                data={operatorHideWidgetsData}
                setData={setUserData}
              />
            </div>
          );
        }
        return (
          <Field
            key={cloneDefParams[i].id}
            param={cloneDefParams[i]}
            onSave={onSave}
            write={write}
            resetParam={resetParam}
          />
        );
      }));
    }

    return defParams.map((defItem) => (
      <Field
        key={defItem.id}
        param={defItem}
        write={write}
        onSave={onSave}
        resetParam={resetParam}
      />
    ));
  };

  return (
    <ParamsBox>
      {renderParams()}
    </ParamsBox>
  );
}

export default ParamsItems;
