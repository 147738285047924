import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import { isAfter } from 'date-fns';
import { DatePicker } from 'antd';
import ReportFilters from './ReportFilters/ReportFilters';
import {
  ReportParametrsBox, ReportParametrsTitle, DataPicersBox, DatePicersItem,
} from '../../../../../components/report/ReportMenu';
import appStore, { updateReportConfig } from '../../../../../../../logic/store/pullstate/appStore';
import { Button } from '../../../../../../../common/ui/Common';

function DatePickers(props) {
  const { setView } = props;
  const reportConfig = appStore.useState((s) => s.reportConfig);
  const startDate = moment(appStore.useState((s) => s.reportConfig.startDate), 'dd.MM.yyyy HH:mm');
  const endDate = moment(appStore.useState((s) => s.reportConfig.endDate), 'dd.MM.yyyy HH:mm');

  const handleDataChange = ({ sDate, eDate }) => {
    setView(true);
    const newStartDate = sDate || startDate.toDate();
    let newEndDate = eDate || endDate.toDate();

    newEndDate = isAfter(newStartDate, newEndDate) ? newStartDate : newEndDate;
    const startTime = moment(newStartDate);
    const endTime = moment(newEndDate);

    updateReportConfig({
      startDate: startTime,
      endDate: endTime,
    });
  };

  const getReports = () => {
    handleDataChange({ eDate: new Date() });
    setView(true);
  };

  return (
    <ReportParametrsBox>
      <ReportParametrsTitle>Параметры отчета</ReportParametrsTitle>
      <DataPicersBox>
        <DatePicersItem>
          <div style={{ marginRight: '8px' }}>С</div>
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            defaultValue={startDate}
            showTime
            onChange={(date) => handleDataChange({ sDate: date })}
          />
        </DatePicersItem>
        <DatePicersItem left>
          <div style={{ marginRight: '8px' }}>По</div>
          <DatePicker
            format="DD.MM.YYYY HH:mm"
            defaultValue={endDate}
            showTime
            onChange={(date) => handleDataChange({ eDate: date })}
          />
        </DatePicersItem>
        <ReportFilters
          reportType={reportConfig.reportType}
        />
      </DataPicersBox>
      <Button
        style={{ margin: 0, marginTop: 30, marginLeft: 17 }}
        onClick={getReports}
      >
        Загрузить отчёт
      </Button>
    </ReportParametrsBox>
  );
}

export default DatePickers;
