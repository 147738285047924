import { playButtonTempl } from './utils';

const translate = {
  ticketReport: 'По талонам',
  generalReport: 'Сводный',
  operatorReport: 'По операторам',
  queueReport: 'По очередям',
  workPlaceReport: 'По рабочим местам',
  workPlaceQuery: 'По рабочим местам',
  groupReport: 'Групповой',
  clockwiseReport: 'Почасовой',
  dynamicsServiceTime: 'Динамика',
  operatorLogi: 'Логи операторов',
  surveyReport: 'Отчет по дополнительным полям',
  preEntryReport: 'Предварительная запись',
  sourceReport: 'По Источнику',
  solvedQuestionReport: 'Решенным вопросам',
  requestReport: 'По обращениям',
  dictionaryReport: 'По опросам',
  dynamicsServiceTimeReport: 'По динамике',
};
export default function TableConfig(searchPropsFactory) {
  return {
    columns: [
      {
        dataIndex: 'createdAt',
        title: 'Дата создания',
        ...searchPropsFactory('createdAt', 'дату создания'),
      }, {
        dataIndex: 'fileName',
        title: 'Название файла',
      },
      {
        dataIndex: 'type',
        title: 'Тип отчета',
        ...searchPropsFactory('type', 'тип отчета'),
        render: (type) => translate[type],
      },
      {
        dataIndex: 'status',
        title: 'Статус',
        ...searchPropsFactory('status', 'статус'),
        render: (_, rec) => (
          rec.status === 1 ? 'Готов для скачивания' : 'Подготавливается'
        ),
      },
      {
        dataIndex: 'Settings',
        title: '  ',
        render: (_, row) => playButtonTempl(row),
      },
    ],
  };
}
