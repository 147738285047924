import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BigButtonsContainer, BoxButtonContent, ButtonContent, StyleButton,
} from '../../../utils/style';

function PreEntry({ children }) {
  const { headUrl, id } = useSelector((state) => state.config);
  const { style } = useSelector((state) => ({ style: state.style.button }));
  const navigate = useNavigate();

  const buttons = [
    { title: 'Записаться', path: `${headUrl}/${id}/preRecord` },
    { title: 'Получить талон', path: `${headUrl}/${id}/pinCode` },
  ];
  return (
    <>
      <BigButtonsContainer style={{ alignItems: style.alignItems }}>
        {' '}
        {/* Рендер кнопок */}
        <StyleButton width={style.widthContent} style={{ justifyContent: style.justifyContent }}>
          {buttons.map((el) => (
            <div key={el.path} style={style} onClick={() => navigate(el.path)}>
              <ButtonContent>
                <BoxButtonContent>
                  <p>{el.title}</p>
                </BoxButtonContent>
              </ButtonContent>
            </div>
          ))}
        </StyleButton>
      </BigButtonsContainer>
      {children}
    </>
  );
}

export default PreEntry;
