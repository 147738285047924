/* eslint-disable no-nested-ternary */
import React from 'react';
import moment from 'moment';
import { updatePreEntryStore } from '../../../../../../logic/store/pullstate/preEntryStore';

function Day({
  dayInMounthNumber, item, selectedDayId, monthNumber,
}) {
  let isOpen = false;
  let isRegistered = 0;
  let takeTicket = 0;
  let isAfter = false;

  if (item) {
    isOpen = item.some((el) => !el.registered);
    isRegistered = item.some((el) => el.registered);
    takeTicket = item.every((el) => el.ticket_id);
    isAfter = moment().startOf('day').isAfter(item[0].thedate);
  }

  const classLi = `${isAfter ? false : isOpen ? 'open' : isRegistered ? 'registered' : takeTicket ? 'take' : false}`;
  const isActive = item && !isAfter && selectedDayId === item[0].calendarday_id;
  const title = `${isAfter ? 'Нет свободных слотов' : isOpen ? 'Можно записаться' : isRegistered ? 'Уже зарегистрировались' : takeTicket ? 'Записан' : 'Нет свободных слотов'}`;

  const clickOnDay = () => {
    updatePreEntryStore({
      selectedDate: {
        data: item,
        id: item.length > 0 ? item[0].calendarday_id : null,
        day: moment(`${dayInMounthNumber}.${monthNumber}`, 'DD.MM').format('DD.MM.YYYY'),
      },
    });
  };

  return (
    <li
      key={dayInMounthNumber}
      className={`item ${classLi}  ${isActive && 'active'}`}
      title={title}
      onClick={() => (item && clickOnDay())}
    >
      {dayInMounthNumber}
    </li>
  );
}

export default Day;
