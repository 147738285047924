import React, { useState, useEffect } from 'react';
import Modal from '../../../../../../common/ui/Modal/Modal';
import request from '../../../../../../logic/utils/request';
import ParamsItems from './ParamsItems';

function PlaceParams({
  params, setParams, placeId, preloader, write,
}) {
  console.log('params === ', params);
  const [newParams, setNewParams] = useState([]);
  const [defParams, setDefParams] = useState([]);

  const fetchNewParams = async () => {
    preloader();
    await request('/server/api/officeparamsbinding/', 'get', { wp_id: placeId }).then((res) => {
      setNewParams([...res.data.data]);
      preloader();
    });
  };

  const fetchDefParams = async () => {
    preloader();
    const res = await request('/server/api/officeparams/', 'get');
    const defaultParams = res.data.data.filter((item) => item.applicability.indexOf('wplace') >= 0);
    console.log('defaultParams === ', defaultParams);
    setDefParams([...defaultParams]);
    preloader();
  };

  useEffect(() => {
    fetchDefParams();
    fetchNewParams();
  }, []);

  return (
    <Modal title="Параметры рабочего места" toggleOpen={setParams} isOpen={params}>
      { defParams.length
          && (
          <ParamsItems
            defParams={defParams}
            newParams={newParams}
            placeId={placeId}
            write={write}
            fetchNewParams={fetchNewParams}
            fetchDefParams={fetchDefParams}
          />
          )}
    </Modal>
  );
}

export default React.memo(PlaceParams);
