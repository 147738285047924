import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Request from '../request';
import Preload from '../../../preload';
import Canvas from './canvas';
import findError from '../findError';
import store from '../../../../store/store';
import { resetTicket, setPre } from '../../../../store/actions';
import { PrintBox } from '../../../utils/style';
import { resetScanPolicyStore } from '../../../../../../../logic/store/pullstate/scanPolicy';
import CanvasPre from './canvasPre';

const { dispatch } = store;

function Print(props) {
  const [data, setData] = useState({});
  const {
    ticket, config, preEntry, style, startScreen,
  } = useSelector((state) => state);
  const [needPrint, setNeedPrint] = useState(true);
  const navigate = useNavigate();
  const { errorUrl } = config;

  const sendTicket = async () => {
    const tk = {
      ...ticket,
      dictionary: ticket.dictionary.map(({ to, inout, ...otherProps }) => otherProps),
      zone_id: config.idZone,
    };
    const emuPin = localStorage.getItem('emulatePreRecord');
    if (emuPin) {
      tk.comment = `Пинкод записи: ${emuPin}`;
      tk.numPriority = 1000;
    }

    try {
      const res = await Request('/server/public/createTicket', 'no', { data: tk });
      const error = findError({
        res, navigate, dispatch, errorUrl,
      });
      if (!error) {
        setData(res.data);
      } else {
        dispatch(resetTicket());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const sendPin = async () => {
    // const arrDictionary = [{fv: [], fid: id}]

    ticket.satellite_id = +ticket.satellite_id;
    try {
      const res = await Request('/server/public/preorder/createTicketByPin', 'no', { data: ticket });
      const error = findError({
        res, navigate, dispatch, errorUrl,
      });
      if (!error) setData(res.data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  useEffect(() => {
    if (config.twoScreen) navigate(startScreen); // magick
    if (preEntry.pin) {
      setData(preEntry);
    } else if (ticket.pinCode) {
      sendPin();
    } else {
      sendTicket();
    }
    return (() => {
      dispatch(setPre({}));
      resetScanPolicyStore();
    });
  }, []);

  const getServerParams = async () => {
    try {
      const newParams = await Request('/server/public/getTicketPrintMode/', 'get', {});
      const { data: { are_tickets_printed: areTicketsPrinted } } = newParams;
      setNeedPrint(areTicketsPrinted !== 0);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e === ', e);
    }
  };

  useEffect(() => {
    getServerParams();
  }, []);

  const viewCanvas = () => (data.ticket
    ? (
      <Canvas
        dispatch={dispatch}
        errorUrl={errorUrl}
        {...props}
        config={config}
        data={data}
        needPrint={needPrint}
      />
    )
    : (
      <CanvasPre
        dispatch={dispatch}
        errorUrl={errorUrl}
        {...props}
        config={config}
        data={data}
        needPrint={needPrint}
        style={props.style}
        startScreen={startScreen}
      />
    ));

  return (
    <PrintBox style={{ background: style.body.background }}>
      {(data.pin || data.style)
        ? viewCanvas()
        : <Preload print />}
    </PrintBox>
  );
}

function compareProps(prevProps, nextProps) {
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
}

export default React.memo(Print, compareProps);
