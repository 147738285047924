import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ctx from '../utils/ctx';

function Print() {
  const { ticket, dispatch } = useContext(ctx);
  const { pin, date, service } = ticket;
  const navigate = useNavigate();

  useEffect(() => {
    const time = setTimeout(() => {
      dispatch({ type: 'clearTicket' });
      navigate('/');
    }, 5000);
    return () => clearTimeout(time);
  }, [navigate]);

  return (
    <div className="print">
      <h3>
        Ваш ПИН-КОД:
        <b>{pin}</b>
      </h3>
      <p>
        Услуга:
        <span>{service}</span>
      </p>
      <p>
        Вы записанны на:
        <span>{date}</span>
      </p>
    </div>
  );
}

export default Print;
