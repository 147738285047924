import React, { useState } from 'react';
import { Table } from 'antd';
import Config from './TableConfig';
import Preloader from '../../../../../pages/private/components/preloader/Preloader';
import { EmptyList } from '../../../../../common/ui/Common';
import DeleteWarning from '../../../../../common/ui/Modal/DeleteWarning';
import { useToggle } from '../../../../../logic/hooks/useToggle';
import ServiceParams from './Parameters/ServiceParams';
import DeleteItem from '../../../../../pages/private/utils/deleteItem';
import { useSearchPropsFactory } from '../../../../../pages/private/utils/useSearch';

function ServiceListTable({
  serviceList, setServiceData,
  setServiceUpdate, toggleOpen, preloader, fetchServices, write, showError,
}) {
  const [showDelWarn, setShowDelWarn] = useToggle(false);
  const [params, setParams] = useToggle(false);
  const [serviceId, setServiceId] = useState(false);
  const [serviceName, setServiceName] = useState(false);

  const delService = async () => {
    await DeleteItem({
      preloader, url: `/server/api/queue/${serviceId}`, fetchData: fetchServices, setShowWarn: setShowDelWarn, setId: setServiceId, showError,
    });
  };

  const handleTableIcon = (e, row) => {
    const { dataset } = e.target;
    const {
      id, position, name, fullname, prefix, workWeek, prior,
    } = row;
    if (dataset.name === 'del') {
      setServiceId(row.id);
      setServiceName(row.name);
      setShowDelWarn();
    } else if (dataset.name === 'update') {
      setServiceData({
        id, position, name, fullname, prefix, workWeek, prior,
      });
      setServiceUpdate(true);
      toggleOpen();
    } else if (dataset.name === 'params') {
      setParams();
      setServiceId(row.id);
    }
  };
  const Setting = Config(write, useSearchPropsFactory());
  const { columns } = Setting;

  const viewServices = () => (serviceList.length
    ? (
      <Table
        dataSource={serviceList}
        columns={columns}
        onRow={(row) => ({
          onClick: (e) => { handleTableIcon(e, row); },
        })}
      />
    )
    : <EmptyList>Услуг нет</EmptyList>);

  return (
    <>
      {!serviceList ? <Preloader isOpen small /> : viewServices()}
      <ServiceParams
        write={write}
        params={params}
        setParams={setParams}
        serviceId={serviceId}
        preloader={preloader}
      />
      <DeleteWarning
        name={serviceName}
        confirmDel={delService}
        isOpen={showDelWarn}
        toggleOpen={setShowDelWarn}
      />
    </>
  );
}

export default React.memo(ServiceListTable);
