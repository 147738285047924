import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import moment from 'moment';
import preEntryStore, { updatePreEntryStore } from '../../../../../logic/store/pullstate/preEntryStore';
import request from '../../../../../logic/utils/request';

import { PreEntryBox } from './style';
import CalendarBox from './calendarBox';
//  import Create from './create';

function PreEntry({ write, showError }) {
  const [loading, setLoading] = useState(true);
  const [calendar, setCalendar] = useState();
  const selectedDate = preEntryStore.useState(((s) => s.selectedDate));

  const getDayId = async (value) => {
    const dd = +moment(value, 'DD.MM.YYYY').format('DD');
    const mm = +moment(value, 'DD.MM.YYYY').format('MM');
    const d = await request('/server/api/dcalendar/', 'get', {}, `year=${moment(value, 'DD.MM.YYYY').format('YYYY')}`);
    return d.data.data.find((el) => el.dayOfMonth === dd && el.monthNumber === mm).dayId;
  };

  const fethDayId = async () => {
    updatePreEntryStore(
      {
        selectedDate: {
          ...selectedDate,
          id: await getDayId(selectedDate.day),
        },
      },
    );
  };

  const fetch = async (resetSelected) => {
    setLoading(true);
    try {
      const ansewer = await request('/server/public/preorder/queues/', 'get');

      const { data: { data } } = ansewer;
      const selectItems = data.map((el) => ({ id: el.qId, name: el.qFullName }));
      if (resetSelected) {
        updatePreEntryStore(
          {
            qeueList: selectItems,
            selectedQueue: {
              id: selectItems[0].id,
              name: selectItems[0].name,
            },
          },
        );
      } else {
        updatePreEntryStore(
          { qeueList: selectItems },
        );
      }
      setLoading(false);
    } catch (e) {
      showError(e.data.error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fethDayId();
    fetch(true);
  }, []);

  return (
    <PreEntryBox>
      {loading ? <Skeleton />
        : (
          <div style={{ width: '100%' }}>
            <CalendarBox
              calendar={calendar}
              setCalendar={setCalendar}
              fetch={fetch}
              write={write}
            />
          </div>
        )}
    </PreEntryBox>
  );
}

export default PreEntry;
