import React, {
  useState, useCallback, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import defaultFilters from '../../../components/ReportFilters/utils/defaultFilters';
import TableConfig from './TableConfig';
import { getReportConfig } from '../../../../../../../../logic/store/redux/reports';
import PrepareTable from '../../../components/PrepareTable';
import ReportFilters from '../../../components/ReportFilters/ReportFilters';
import { getColumnSearchProps } from './getColumnsSearchProps';

function QueueTable(props) {
  const [queryParams, setQueryParams] = useState();
  const { reportConfig, dispatch, preloader } = props;

  const moreInfo = useCallback((row, status, type) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        reportType: 'ticketReport',
        rootReport: reportConfig.reportType,
        renderBackButton: true,
        rootTable: type,
        buttonType: status,
        activeRow: row,
      },
    ));
  }, [reportConfig, dispatch]);

  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const input = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const preparQueryParams = useCallback(async (filters) => {
    const { columns } = TableConfig(
      moreInfo,
      getColumnSearchProps(input, handleSearch, handleReset, searchedColumn, searchText),
    ); // Колонки для таблиц
    const params = {
      columns,
      filters: filters || defaultFilters(),
      url: '/api/reports/build',
      type: 'queueReport',
    };
    setQueryParams(params);
  }, [moreInfo]);

  useEffect(() => {
    preparQueryParams();
  }, [props, preparQueryParams]);

  return (
    queryParams ? (
      <>
        <ReportFilters
          showFilters={{
            main: ['reportRange', 'queues'],
            others: ['servedfilters', 'waitTimeFilters', 'procTimeFilter', 'actionFilters'],
            orders: ['queue_name', 'all_cnt', 'close_cnt', 'neyavka_cnt', 'total_wait_time', 'avg_wait_time', 'total_proc_time', 'avg_proc_time'],
          }}
          paramsCallback={preparQueryParams}
          type="queueReport"
        />
        <PrepareTable
          keys="queue"
          type="QueueTable"
          {...queryParams}
          preloader={preloader}
        />
      </>
    ) : null);
}

function mapStateToProps(state) {
  return { reportConfig: state.reportConfig };
}

export default connect(mapStateToProps)(QueueTable);
