import React from 'react';
import Input from './TableInput';
import CheckBoxList from '../../../../pages/private/components/CheckBoxList';

import MultiSelect from '../../../../pages/private/subpages/queue/workplaces/Parameters/MultiSelect/MultiSelect';

const operatorHideWidgetsData = [
  { id: 1, key: 'fullname', name: 'Полное имя клиента в талоне' },
  { id: 2, key: 'phonenumber', name: 'Номер телефона клиента в талоне' },
  { id: 3, key: 'email', name: 'E-mail клиента в талоне' },
  { id: 4, key: 'comment', name: 'Комментарий к талону' },
  { id: 5, key: 'refundv', name: 'Кнопка "Вернуть в очередь" в окне валидации' },
  { id: 6, key: 'questioncount', name: 'Счетчик решенных вопросов' },
  { id: 7, key: 'filters', name: 'Кнопка "Подключенные филтры"' },
];

export default function Setting(groups, handleTableChange, multiData, searchPropsFactory) {
  const setUserData = (e) => {
    const disableElements = [];
    const { connectedRoles: disableElementsId } = e;
    disableElementsId.forEach((disableId) => {
      operatorHideWidgetsData.forEach((element) => {
        if (element.id === disableId) {
          disableElements.push(element.key);
        }
      });
    });

    handleTableChange('operatorHideWidgets', disableElements.join(','));
  };

  const handleMultiSelect = (param, newVal) => {
    const newParsed = newVal.map((item) => item.value);
    const joined = newParsed.join(' ');
    handleTableChange(param, joined);
  };

  const cellFormatter = (cell, row) => {
    const { param_type: paramType, value, param } = row;

    let type = paramType;
    if (paramType === 'string') {
      type = 'text';
    }
    if (paramType === 'boolean') {
      type = 'boolean';
    }
    if (paramType === 'soundstring') {
      return (
        <MultiSelect
          data={multiData}
          value={value}
          onSave={(newVal) => handleMultiSelect(param, newVal)}
        />
      );
    }
    if (param === 'operatorHideWidgets') {
      const checedValues = [];
      const dbcheced = value.split(',');

      dbcheced.forEach((el) => {
        operatorHideWidgetsData.forEach((element) => {
          if (element.key === el) {
            checedValues.push(element.id);
          }
        });
      });

      return (
        <div>
          <CheckBoxList
            item={{
              label: 'Элементы:', name: 'connectedRoles', options: operatorHideWidgetsData, checked: checedValues,
            }}
            data={operatorHideWidgetsData}
            setData={setUserData}
          />
        </div>
      );
    }

    return <Input value={value} type={type} param={param} update={handleTableChange} />;
  };

  return {
    columns: [
      {
        dataIndex: 'param',
        title: 'Параметр',
        sort: false,
        ...searchPropsFactory('param', 'Параметр'),
        width: 320,
      },
      {
        dataIndex: 'description',
        title: 'Описание',
        ...searchPropsFactory('description', 'Описанию'),
      },
      {
        dataIndex: 'group_name',
        title: 'Группа',
        ...searchPropsFactory('group_name', 'Группе'),
        width: 200,
        paddingLeft: 10,
        paddingRight: 10,
      },
      {
        dataIndex: 'value',
        title: 'Значение',
        ...searchPropsFactory('value', 'Значению'),
        render: (_, row) => cellFormatter(_, row),
      },
    ],
  };
}
