import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import 'moment/locale/ru';
import DounhnutsColorMap from '../../../../../../utils/DounghnutsColorMap';

function AvgWaitTimeDoughnut({ data: items }) {
  const [ReportParams, setReportParams] = useState();
  const { reportConfig } = useSelector((state) => state);
  const answerFnc = useCallback(() => {
    const AvgProcLabels = [];
    const AvgProcData = [];
    let allCntAvgProcDoughnut = 0;

    items.forEach((el) => {
      if (el[1] > 0) {
        AvgProcLabels.push(el[0]);
        AvgProcData.push(el[1]);
        allCntAvgProcDoughnut += Number(el[1]);
      }
    });

    const avgProcTime = allCntAvgProcDoughnut;
    const color = DounhnutsColorMap(AvgProcData.length);

    const doughnutData = {
      labels: AvgProcLabels,
      datasets: [{
        data: AvgProcData,
        backgroundColor: color,
      },
      ],
    };

    const doughnutOptions = {
      maintainAspectRatio: false,
      // responsive: false,
      intersect: true,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            type: 'linear',
            position: 'left',
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    const doughnutParams = {
      Data: doughnutData,
      Options: doughnutOptions,
      AllCnt: avgProcTime,
    };

    setReportParams(doughnutParams);
  }, [items]);

  useEffect(() => {
    answerFnc();
  }, [answerFnc, reportConfig]);

  return (ReportParams ? (
    <Bar
      data={ReportParams.Data}
      options={ReportParams.Options}
      height={250}
    />
  ) : null);
}

export default AvgWaitTimeDoughnut;
