import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import {
  Col, Row, Typography, List,
} from 'antd';
import Request from '../../../../../../../logic/utils/request';
import Preloader from '../../../../../components/preloader/Preloader';
import LicenceUpdate from './LicenceUpdate';

const { Title } = Typography;

function LicenceComponent({ preloader, showError }) {
  const [licence, setLicence] = useState(null);

  const getLicence = useCallback(async () => {
    const licenceReq = await Request('/server/api/licence/');
    const { data: { data: licData } } = licenceReq;
    const currentLic = licData.find((lic) => (lic.active));
    setLicence(currentLic);
  }, []);

  useEffect(() => {
    getLicence();
  }, [getLicence]);

  return (
    <>
      <Preloader isOpen={false} small={false} />
      <Title>Лицензия</Title>

      {licence ? (
        <>
          <Title level={2}>
            <div>
              Лицензия действительна
              {(!licence.validfrom && !licence.validto) && '(ДЕМО ЛИЦЕНЗИЯ)'}
            </div>
            <div>
              {(licence.validfrom) ? moment(licence.validfrom).format('DD.MM.YYYY') : '01.01.2019'}
              {' '}
              -
              {' '}
              {(licence.validto) ? moment(licence.validto).format('DD.MM.YYYY') : '31.12.2119'}
            </div>
          </Title>
          <LicenceUpdate preloader={preloader} showError={showError} />
          <Row gutter={[10, 10]} style={{ width: '95%', maxWidth: '850px', margin: '0 auto' }}>
            <Col span={12}>
              <Title level={3}>Параметры</Title>
              <List size="small">
                {Object.keys(licence.licdescriptionjsn).filter((l) => typeof licence.licdescriptionjsn[l] !== 'boolean').map((k) => (
                  <List.Item key={k} style={{ textAlign: 'left' }}>
                    <List.Item.Meta title={k} />
                    {licence.licdescriptionjsn[k]}
                  </List.Item>
                ))}
              </List>
            </Col>
            <Col span={12}>
              <Title level={3}>Модули</Title>
              <List size="small">
                {Object.keys(licence.licdescriptionjsn).filter((l) => typeof licence.licdescriptionjsn[l] === 'boolean').map((k) => (
                  <List.Item key={k} style={{ textAlign: 'left' }}>
                    <List.Item.Meta title={k} />
                    {(licence.licdescriptionjsn[k]) ? <FaCheckCircle size={18} color="#2490ff" /> : <FaTimesCircle size={18} />}
                  </List.Item>
                ))}
              </List>
            </Col>
          </Row>
        </>
      ) : (
        <>
          Получение информации о лицензии...
        </>
      )}
    </>
  );
}
export default LicenceComponent;
