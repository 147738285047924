import React from 'react';
import { Select } from 'antd';
import appStore, { updateReportConfig } from '../../../../../../../logic/store/pullstate/appStore';

const { Option } = Select;

function ReportButtons() {
  const reportConfig = appStore.useState((s) => s.reportConfig);
  const { reportType } = reportConfig;

  const handleChange = (_, option) => {
    const { children, value } = option;
    updateReportConfig({ reportType: value, reportTitle: children });
  };
  return (
    <Select
      defaultValue={reportType}
      onChange={handleChange}
      style={{ width: '100%' }}
      size="large"
    >
      <Option value="generalReport">Сводный</Option>
      <Option value="ticketReport">По талонам</Option>
      <Option value="operatorReport">По операторам</Option>
      <Option value="queueReport">По очередям</Option>
      <Option value="workPlaceReport">По рабочим местам</Option>
      <Option value="groupReport">Групповой</Option>
      <Option value="clockwiseReport">Почасовой</Option>
      <Option value="dynamicsServiceTime">Динамика</Option>
      <Option value="operatorLogi">Логи операторов</Option>
      <Option value="surveyReport">Отчет по дополнительным полям</Option>
      <Option value="preEntryReport">Предварительная запись</Option>
      <Option value="sourceReport">По Источнику</Option>
      <Option value="solvedQuestionReport">Решенным вопросам</Option>
    </Select>
  );
}
export default ReportButtons;
