import React, {
  useState, useEffect,
} from 'react';
import { connect } from 'react-redux';
import { FaArrowLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from 'antd';
import Icon from '@ant-design/icons';
import request from '../../../../../../logic/utils/request';
import RenderInputs from './RenderInputs';
import RenderButtons from './RenderButtons';
import randomId from '../../../../../../logic/utils/randomId';
import BottomScreen from './BottomScreen';
import Request from '../../../Devices/DeviceStyle/TerminalStyle/Terminal/utils/request';

function ScreenEdit({
  showError, preloader, dispatch,
}) {
  const navigate = useNavigate();
  const [zone, setZone] = useState([]);
  const [selectList, setSelectList] = useState({ select: [], multi: [] });
  const [screen, setScreen] = useState({ buttons: [], bottomBlock: { buttons: [], topText: '', bottomText: '' } });
  const [screenList, setScreenList] = useState([]);
  const [infoScreen, setInfoScreen] = useState(false);
  const { id, screenId } = useParams();
  const create = screenId === 'new' || screen.type === 'preEntry';
  const [terminals, setTerminals] = useState([]);

  const isValid = screen.headTitle && screen.type;

  const getTerminals = async () => {
    const { data: { data } } = await request('/server/api/satellite/', 'get');
    const term = data.filter((el) => el.type === 'terminal');
    setTerminals(term);
  };

  const setSelectTerminal = async (el) => {
    const { data } = await Request(`/server/public/satellite/${el.id}/`);
    dispatch({ type: 'SET_CONFIG', payload: data.data[0] });
  };

  const fetchFields = async () => {
    preloader();
    const res = await request('/server/api/ticketsfields/', 'get');
    preloader();
    const select = [];
    const multi = [];
    res.data.data.forEach((field) => {
      if (field.type === 'select') {
        select.push(field);
      } else if (field.type === 'multiselect') {
        multi.push(field);
      }
    });
    setSelectList({ select, multi });
  };

  const fetchScreens = async () => {
    preloader();
    try {
      const res = await request(`/server/api/zone/${id}`, 'get');
      preloader();
      if (res.data.data) {
        res.data.data.config.screens.forEach((sc) => sc.buttons.forEach((btn) => {
          btn.icon = btn.icon || false;
        }));
        setZone(res.data.data);
        setScreenList(res.data.data.config.screens);
        res.data.data.config.screens.forEach((item) => {
          if (item.screenId === screenId) {
            setScreen({
              ...item,
              bottomBlock: item.bottomBlock || { buttons: [], topText: '', bottomText: '' },
            });
            setInfoScreen(item.type === 'infoScreen');
          }
        });
      }
    } catch (e) {
      const text = { text: { rus: 'Зона недоступна' } };
      showError(text);
    }
  };

  const addScreen = async () => {
    if (isValid) {
      const screens = [...zone.config.screens];
      screen.screenId = randomId(5);
      if (screen.type === 'infoScreen') {
        screen.data = '';
      }
      screens.push(screen);
      const newData = { ...zone, config: { ...zone.config, screens } };
      preloader();
      await request(`/server/api/zone/${id}/`, 'update', newData).catch((err) => {
        showError(err.data.error);
      });
      preloader();
      fetchScreens();
      navigate(-1);
      toast.success('Экран добавлен');
    }
  };

  const updateScreen = async (addBtn) => {
    if (isValid || addBtn) {
      const screens = [...screenList];
      const newScreens = screens.map((item) => {
        if (item.screenId === screen.screenId) {
          return screen;
        }
        return item;
      });
      const newData = {
        ...zone,
        config: { ...zone.config, screens: newScreens },
      };
      preloader();
      await request(`/server/api/zone/${id}/`, 'update', newData).catch((err) => {
        showError(err.data.error);
      });
      preloader();
      fetchScreens();
      toast.success('Экран обновлен');
    }
  };

  useEffect(() => {
    getTerminals();
    fetchScreens();
    fetchFields();
  }, []);

  const dictOptions = screen.type === 'multiScreen' ? [...selectList.multi] : [...selectList.select];

  const changeScreen = (value, param) => {
    const regex = /(<([^>]+)>)/ig;
    const result = value.replace(regex, '');
    setScreen({ ...screen, bottomBlock: { ...screen.bottomBlock, [param || 'data']: result } });
  };

  const screenTypes = [
    { id: 'startScreen', name: 'Начальный экран' },
    { id: 'dictScreen', name: 'Экран одиночного выбора' },
    { id: 'inputScreen', name: 'Экран с полями ввода' },
    { id: 'btnScreen', name: 'Экран с кнопками' },
    { id: 'multiScreen', name: 'Экран множественного выбора' },
    { id: 'infoScreen', name: 'Информационный экран' },
  ];

  if (zone) {
    return (
      <>
        <Button
          type="primary"
          shape="circle"
          icon={<Icon component={FaArrowLeft} />}
          onClick={() => navigate(-1)}
        />
        <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>
          {!create ? (
            <>
              Изменение экрана -
              {' '}
              <span style={{ color: '#f54c03' }}>
                {screen.headTitle}
              </span>
              {' '}
            </>
          ) : 'Создание экрана'}
        </Typography.Title>
        <RenderInputs
          terminals={terminals}
          setTerminal={setSelectTerminal}
          setInfoScreen={setInfoScreen}
          screenList={screenList}
          setScreenList={setScreenList}
          setScreen={setScreen}
          nowScreen={screen}
          dictOptions={dictOptions}
          screenTypes={screenTypes}
          isValid={isValid}
          updateScreen={updateScreen}
          addScreen={addScreen}
          create={create}
        />
        {!create
          ? (
            <>
              {!infoScreen && (
              <RenderButtons
                screen={screen}
                screenList={screenList}
                preloader={preloader}
                dictOptions={dictOptions}
                updateScreen={updateScreen}
              />
              )}
              { (infoScreen || screen.type === 'startScreen') && screen.bottomBlock && (
              <BottomScreen
                screenList={screenList}
                noText={infoScreen}
                screen={screen}
                updateScreen={updateScreen}
                changeScreen={changeScreen}
                showError={showError}
              />
              )}
            </>
          )
          : null}
      </>
    );
  }
  return null;
}

export default connect()(ScreenEdit);
