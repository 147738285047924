import Request from '../../../../../../../../../../logic/utils/request';

export default async function SqlQuery(time, endTime) {
  const dataObjects = [];
  const query = `select
  tq.id,
  wd.work_day,
  tq.fullname,
  allt.all_cnt,
  (allt.all_cnt / wd.work_day) as all_cnt_pd,
  ct.close_cnt,
  (ct.close_cnt / wd.work_day) as close_cnt_pd,
  nc.none_cnt,
  (nc.none_cnt / wd.work_day) as none_cnt_pd,
  sc.sys_close_cnt,
  (sc.sys_close_cnt / wd.work_day) as sys_close_pd
  from
  ((
  select
  ss.q_id,
  count (distinct(ss.days)) as work_day
  from
  (
  select
  q_id,
  to_char(modified_on, 'DD.MM.YYYY') as days
  from
  eq.ttickethistory
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}') ss
  group by
  q_id) wd
  left join (
  select
  q_id,
  count(distinct ticket_id) as all_cnt
  from
  eq.ttickethistory
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}'
  and status in (3,
  4,
  9)
  group by
  q_id
  ) allt on
  wd.q_id = allt.q_id
  left join (
  select
  q_id,
  count(distinct ticket_id) as close_cnt
  from
  eq.ttickethistory
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}'
  and status = 4
  group by
  q_id) ct on
  wd.q_id = ct.q_id
  left join (
  select
  q_id,
  count(distinct ticket_id) as none_cnt
  from
  eq.ttickethistory
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}'
  and status = 3
  group by
  q_id ) nc on
  wd.q_id = nc.q_id
  left join (
  select
  q_id,
  count(distinct ticket_id) as sys_close_cnt
  from
  eq.ttickethistory
  where
  modified_on::timestamp >= '${time}'
  and modified_on::timestamp <= '${endTime}'
  and status = 9
  group by
  q_id ) sc on
  wd.q_id = sc.q_id
  right join (
  select id, fullname, deleted from eq.tqueue where deleted = false
  ) tq
  on wd.q_id = tq.id)
  order by allt.all_cnt desc NULLS LAST
  `;

  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  return { dataObjects, query };
}
