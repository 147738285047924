import React from 'react';

export default null;
export const columnFormatter = (cell, row, list) => (
  <div
    style={{
      display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px',
    }}
  >
    <div style={{ textAlign: 'center', width: 'fit-content' }}>{row[list] ? row[list].cnt : '—'}</div>
    {row[list] && row[list].avg && <div style={{ textAlign: 'center', width: 'fit-content' }}>{row[list] ? row[list].avg : '—'}</div>}
    <p>&nbsp;/&nbsp;</p>
    <div style={{ textAlign: 'center', width: 'fit-content' }}>{row[list] ? `${Number(row[list].nps).toFixed(2)}%` : '—'}</div>
  </div>
);
