import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Request from '../../../../logic/utils/request';
import Preloader from '../../Elements/Preloader/PortalPreloader';
import Config from './ConfigTable';
import Button from './constructor/components/Buttons';
import { EmptyList } from '../../../../common/ui/Common';
import { Btn, BtnsBox } from '../../../../common/ui/Common2';
import Modal from '../../../../common/ui/Modal/PortalModal';
import DeleteWarning from '../../../../common/ui/Modal/DeleteWarning';
import { useSearchPropsFactory } from '../../../../pages/private/utils/useSearch';

function TicketList() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [queue, setQueue] = useState([]);
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState();
  const [activeElem, setActiveElement] = useState();
  const [connect, setConnect] = useState([]);
  const [title, setTitle] = useState();
  const [deleteItem, setDelete] = useState(false);
  const [defaultItem, setDefaultItem] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const fetchData = async () => {
    const tList = await Request('/server/api/tickettemplate/', 'get', {});
    const qList = await Request('/server/api/queue/', 'get', {});
    setData(tList.data.data);
    setQueue(qList.data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTableIcon = (e, row) => {
    const { name } = e.target.dataset;
    if (name === 'update') {
      navigate(`/tickets/ticketConstructor/${row.id}`);
      return;
    }
    if (name === 'del' || name === 'params') {
      const newConnect = [];
      const newAction = name === 'params' ? 0 : 1;
      if (row.connected_queues.length > 0) {
        row.connected_queues.forEach((item) => {
          newConnect.push(item.q_id);
        });
      }
      if (name === 'del') {
        setTitle(row.name);
        setDelete(name === 'del');
      } else {
        setTitle('Привязка услуги');
        setConnect(newConnect);
        setOpen(!open);
      }
      setActiveElement(row.id);
      setAction(newAction);
    }
  };

  const cancel = () => {
    if (!defaultItem) return;
    const newData = data.slice(0);
    let index = 0;
    newData.forEach((el, ind) => {
      if (el.id === defaultItem.id) {
        index = ind;
      }
    });
    newData.splice(
      index,
      1,
      defaultItem,
    );
    setData(newData);
  };

  const toggleOpen = () => {
    cancel();
    setIsValid(false);
    setOpen(!open);
  };

  const onChangeConnect = (item) => {
    const newConnect = connect.slice(0);
    const newData = data.slice(0);
    const selectItem = newData.find((el) => el.id === activeElem);
    setDefaultItem({ ...selectItem });

    setIsValid(true);
    if (item.id === true) {
      selectItem.select_by_default = item.id;
      setData(newData);
      setConnect([]);
      return;
    }

    if (newConnect.some((elem) => elem === item.id)) {
      newConnect.splice(connect.findIndex((el) => el === item.id), 1);
      selectItem.select_by_default = false;
    } else {
      newConnect.push(item.id);
      selectItem.select_by_default = false;
    }
    setData(newData);
    setConnect(newConnect);
  };

  const sendServer = () => {
    const newData = data.filter((item) => item.id === activeElem)[0];
    if (!action) {
      newData.connected_queues = connect;
      Request(`/server/api/tickettemplate/${newData.id}/`, 'update', newData)
        .then(() => {
          toast.success('Выполнено', 1000);
          setOpen(false);
          setIsValid(false);
          fetchData();
        })
        .catch((er) => {
          toast.error(er.data.error.text.rus, 1000);
          setOpen(false);
          setIsValid(false);
          fetchData();
        });
    } else {
      Request(`/server/api/tickettemplate/${newData.id}/`, 'delete', {})
        .then(() => {
          toast.success('Выполнено', 1000);
          const index = data.indexOf(newData);
          const newDataDelete = data.slice(0);
          newDataDelete.splice(index, 1);
          setDelete(false);
          setIsValid(false);
          fetchData();
        })
        .catch((er) => {
          toast.error(er.data.error.text.rus, 1000);
          setOpen(false);
          setIsValid(false);
          fetchData();
        });
    }
  };

  const defaultButton = () => {
    const elem = data.find((item) => item.id === activeElem);
    const item = { fullname: 'По умолчанию', id: elem && !elem.select_by_default };
    return elem && (
      <Button
        ticketList
        active={elem.select_by_default}
        onClick={onChangeConnect}
        item={item}
        list
      />
    );
  };

  const Setting = Config(useSearchPropsFactory);
  const { columns } = Setting;

  const viewData = () => (data.length
    ? (
      <Table
        columns={columns}
        dataSource={data}
        rowKey="id"
        onRow={(row) => ({
          onClick: (e) => { handleTableIcon(e, row); },
        })}
      />
    ) : <EmptyList>Стилей нет</EmptyList>);

  return (
    <>
      <React.Suspense fallback={null}>
        {!data ? <Preloader isOpen small /> : viewData()}
      </React.Suspense>
      <DeleteWarning
        name={title}
        confirmDel={sendServer}
        isOpen={deleteItem}
        toggleOpen={() => setDelete(!deleteItem)}
      />
      {open && (
        <Modal onClose={toggleOpen} isOpen={open} title={title}>
          {defaultButton()}
          { queue.map((sat) => (
            <React.Fragment key={sat.id}>
              <Button
                ticketList
                active={connect.some((el) => el === sat.id)}
                onClick={onChangeConnect}
                item={sat}
                list
              />
            </React.Fragment>
          ))}
          <BtnsBox>
            <Btn
              margin="0 25px 0 0"
              bg="green"
              onClick={sendServer}
              valid={isValid}
            >
              Сохранить
            </Btn>
            <Btn
              cancel
              onClick={() => toggleOpen()}
              bg="accent"
            >
              Отменить
            </Btn>
          </BtnsBox>
        </Modal>
      )}
    </>
  );
}

export default TicketList;
