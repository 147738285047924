import React from 'react';
import { Select } from 'antd';
import {
  FilterBoxTitle, FilterItemBox, FilterBox,
} from '../style';
// import MultiSelectNew from '../../../../../../components/multiselect/MultiSelectNew';

function WorkPlaceSelect({ changeFilter, filtersData }) {
  const MultiSelectItemList = (list) => {
    const ww = list.join(', ');
    if (ww === '') changeFilter('workPlace', 'all');
    else changeFilter('workPlace', ww);
  };

  return (
    <FilterBox Select>
      <FilterBoxTitle>Список рабочих мест</FilterBoxTitle>
      <FilterItemBox>
        <Select
          mode="multiple"
          placeholder="Выберите рабочее место"
          className="multiselect"
          onChange={MultiSelectItemList}
        >
          {filtersData.map((wplace) => (
            <Select.Option
              key={wplace.id}
              value={wplace.id}
            >
              {wplace.name}
            </Select.Option>
          ))}
        </Select>
      </FilterItemBox>
    </FilterBox>

  // <FilterBox Select>
  //   <FilterBoxTitle>Список рабочих мест</FilterBoxTitle>
  //   <FilterItemBox>
  //     <MsFiltersItem>
  //       {dropdownConfig.items ? (
  //         <Dropdown
  //           config={dropdownConfig}
  //           clickHandler={changeOperetorFilter}
  //           replaceableTitle
  //         />
  //       ) : (null)}
  //     </MsFiltersItem>
  //   </FilterItemBox>
  // </FilterBox>
  );
}

export default WorkPlaceSelect;
