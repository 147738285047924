import React, { useState } from 'react';
import { InputBox, KeyboardContainer } from '../../../../utils/style';
import Keyboard from '../../../common/keyboard';

function ModalInput({
  id, inputs, input, setId, styleButton, values, setValues,
}) {
  const [language, setLanguage] = useState(false);
  const [shift, setShift] = useState(false);
  const index = id - 1;

  const deleteSymbol = () => {
    let newText = values[index].split('');
    newText.splice(-1, 1);
    newText = newText.join('');
    const newValue = [...values];
    newValue.splice(index, 1, newText);
    setValues(newValue);
  };

  const addSymbol = (val) => {
    const nextSymbol = shift ? val.toUpperCase() : val;
    const newText = `${values[index]}${nextSymbol}`;
    const newValue = [...values];
    newValue.splice(index, 1, newText);
    setValues(newValue);
  };

  const keyClick = (val) => {
    const value = String(val).toLowerCase();
    switch (value) {
      case 'eng':
      case 'rus':
        setLanguage(!language);
        break;
      case 'shift':
        setShift(!shift);
        break;
      case '←':
        deleteSymbol(val);
        break;
      default:
        addSymbol(val);
    }
  };

  const styleReady = {
    ...styleButton,
    width: 'min-content',
    height: 'min-content',
    margin: '30px auto',
    padding: '5px 35px',
  };

  return (
    <KeyboardContainer>
      <InputBox button={input}>
        {inputs[index]}
        <Keyboard
          styleButton={styleButton}
          login={50}
          language={language}
          id={id === 1 ? 'number' : 'text'}
          width={styleButton.width}
          shift={shift}
          onClick={keyClick}
        />
        <div className="ready" style={styleReady} onClick={() => setId(0)}> Готово </div>
      </InputBox>
    </KeyboardContainer>
  );
}

export default ModalInput;
