import React, { useCallback } from 'react';
import AddButton from '../../../../pages/private/utils/AddButton';
import { FiltersBox } from '../../Queue/WorkPlaces/style';
import FilterItem from './FilterItem';

function Body(props) {
  const {
    list,
    setData,
    data,
    dict,
    usedDict,
    setUsedDict,
    setValid,
    defaultFilter,
    user,
    changeFilterUser,
    removeFilterUser,
  } = props;

  const { filtersA } = data.dictionary;

  const add = () => {
    const fA = [...data.dictionary.filtersA];
    fA.push({ fid: '', fv: [] });
    const newData = { ...data };
    newData.dictionary.filtersA = fA;
    setData(newData);
  };

  const onChange = useCallback(({ value, index, name }) => {
    const fA = [...data.dictionary.filtersA];
    if (name === 'fid') {
      if (!usedDict.some((el) => el === value)) setUsedDict([...usedDict, value]);
      fA[index][name] = value;
      fA[index].fv = [];
    } else if (!fA[index][name].some((el) => el === value)) {
      fA[index][name].push(+value);
    } else {
      fA[index][name].splice(fA[index][name].indexOf(value), 1);
    }
    const newData = { ...data };
    setValid(
      (fA.some((el) => el.fv.length > 0 && el.fid)
        && defaultFilter !== fA)
        || fA.length === 0,
    );
    newData.dictionary.filtersA = fA;
    setData(newData);
  }, [data, defaultFilter, setData, setUsedDict, setValid, usedDict]);

  const onChangeUser = ({
    el, action, index, name, value,
  }) => {
    const fA = [...data.dictionary.filtersA];
    if (name === 'fid') {
      if (!usedDict.some((element) => element === value)) setUsedDict([...usedDict, value]);
      fA[index][name] = value;
      fA[index].fv = [];
    } else if (action) {
      const fv = fA[index].fv.map((d) => d);
      fv.push(el);
      const dataSend = {
        fid: fA[index].fid,
        fvs: [el],
      };
      changeFilterUser(dataSend, 'add', { fv, index });
    } else {
      fA[index].fv.splice(fA[index].fv.indexOf(el), 1);
      const dataSend = {
        fid: fA[index].fid,
        fvs: [el],
      };
      changeFilterUser(dataSend, 'delete', {
        ...data,
        dictionary: { fA },
      });
    }
  };

  const removeFilter = useCallback((item) => {
    const fA = [...data.dictionary.filtersA];
    fA.splice(fA.indexOf(item), 1);
    const newData = { ...data };
    newData.dictionary.fA = fA;
    const newUseDict = [...usedDict];
    setValid(defaultFilter !== fA);
    newUseDict.splice(usedDict.indexOf(item.fid), 1);
    setUsedDict(newUseDict);
    setData(newData);
    if (user) removeFilterUser(item.fid);
  }, [data, defaultFilter, removeFilterUser, setValid, setData, setUsedDict, usedDict, user]);

  return (
    <div>
      {dict.length > filtersA.length && <AddButton toggle={add} />}
      <FiltersBox>
        {filtersA.map((el, index) => (
          <FilterItem
            user={user}
            key={el.fid}
            removeFilter={removeFilter}
            list={list}
            usedDict={usedDict}
            item={el}
            dict={dict}
            onChange={user ? onChangeUser : onChange}
            index={index}
          />
        ))}
      </FiltersBox>
    </div>
  );
}

export default React.memo(Body);
