const lexicon = {
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'j',
  з: 'z',
  и: 'i',
  й: 'j',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  х: 'h',
  ц: 'c',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  ъ: '',
  ы: 'y',
  ь: '',
  э: 'ae',
  ю: 'yu',
  я: 'ya',
};

module.exports = (letter, dict = lexicon, parser) => {
  const toTranslate = letter.toString().toLowerCase();
  if (toTranslate.length > 1) return toTranslate;

  const shouldTranslate = dict[toTranslate];

  return (typeof parser === 'function') ? parser(shouldTranslate) : shouldTranslate;
};
