import React, { useState } from 'react';
import { Typography } from 'antd';
import NavigationMenu from './NavigationMenu/NavigationMenu';
import ReportList from './ReportList/ReportList';
import { ReportBigBox } from '../../../../../common/ui/Common';
import appStore from '../../../../../logic/store/pullstate/appStore';

function Reports(props) {
  const { preloader } = props;
  const [view, setView] = useState(null);
  const [loader, setLoader] = useState(null);

  const reportConfig = appStore.useState((s) => s.reportConfig);
  const { reportType } = reportConfig;
  return (
    <ReportBigBox>
      <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>Отчеты</Typography.Title>
      <NavigationMenu
        reportType={reportType}
        setView={setView}
        setLoader={setLoader}
      />
      <ReportList
        preloader={preloader}
        view={view}
        loader={loader}
        setLoader={setLoader}
        setView={setView}
      />
    </ReportBigBox>
  );
}

export default Reports;
