import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import Icon from '@ant-design/icons';
import { getReportConfig } from '../../../../../../../../logic/store/redux/reports';
import QueueListSqlQuerys from './QueueListSqlQuerys';
import OperatorListSqlQuery from './OperatorListSqlQuery';
import WorkplaceQslQuery from './WorkplaceSqlQuery';
import { FilterBox, FilterSelection } from '../../../../../../components/report/ReportMenu';
import { ReactComponent as DdArrow } from '../../../../../../../../common/images/DdArrow.svg';

function ReportFilters({ reportType, dispatch }) {
  const [queueList, setQueueList] = useState({});
  const [operatorList, setOperatorList] = useState({});
  const [workplaceList, setWorkplaceList] = useState({});
  const { reportConfig } = useSelector((state) => state);
  const [filtersHeaders, setFiltersHeaders] = useState({
    queue: '',
    operator: '',
    workPlace: '',
  });

  useEffect(() => {
    if (reportType === 'clockwiseReport') {
      QueueListSqlQuerys(reportConfig, setQueueList);
      OperatorListSqlQuery(reportConfig, setOperatorList);
      WorkplaceQslQuery(reportConfig, setWorkplaceList);
    }
  }, [reportType, reportConfig]);

  const changeQueuFilter = async (key) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        filterts: {
          ...reportConfig.filterts,
          queue: key,
        },
      },
    ));
  };

  const changeOperetorFilter = async (key) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        filterts: {
          ...reportConfig.filterts,
          operator: key,
        },
      },
    ));
  };

  const changeWorkplaceFilter = async (key) => {
    dispatch(getReportConfig(
      {
        ...reportConfig,
        filterts: {
          ...reportConfig.filterts,
          workplace: key,
        },
      },
    ));
  };

  const renderDropdown = (items, clickHandler, header, setHeader) => (
    <Dropdown
      overlay={(
        <Menu
          items={items.map((item) => ({
            label: item.text,
            icon: item.icon,
            key: item.key,
            onClick() {
              clickHandler(item.key);
              setHeader(item.text || item.name);
            },
          }))}
        />
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={(e) => e.preventDefault()}>
        {header}
        <Icon component={DdArrow} />
      </a>
    </Dropdown>
  );

  return (reportType === 'clockwiseReport' ? (
    <div style={{ display: 'flex', justifyContent: 'space-around', flex: '1' }}>
      {queueList.items ? (
        <FilterBox>
          <div>Очередь</div>
          <FilterSelection>
            {renderDropdown(
              queueList,
              changeQueuFilter,
              filtersHeaders.queue,
              (key) => setFiltersHeaders({ ...filtersHeaders, queue: key }),
            )}
          </FilterSelection>
        </FilterBox>
      ) : null}
      {operatorList.items ? (
        <FilterBox>
          <div>Оператор</div>
          <FilterSelection>
            {renderDropdown(
              operatorList,
              changeOperetorFilter,
              filtersHeaders.operator,
              (key) => setFiltersHeaders({ ...filtersHeaders, operator: key }),
            )}
          </FilterSelection>
        </FilterBox>
      ) : null}
      {workplaceList.items ? (
        <FilterBox>
          <div>Рабочие место</div>
          <FilterSelection>
            {renderDropdown(
              workplaceList,
              changeWorkplaceFilter,
              filtersHeaders.workPlace,
              (key) => setFiltersHeaders({ ...filtersHeaders, workPlace: key }),
            )}
          </FilterSelection>
        </FilterBox>
      ) : null}
    </div>
  ) : null);
}

export default ReportFilters;
