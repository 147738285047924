import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import getDropdownConfig from './DropdownConfig';
import { TestDiv, TestDiv2 } from '../../style';
// import Dropdown from '../../../../../../../components/dropdown/Dropdown';

function AllFilter({
  changeFilter, title, smallTitle, type, filld,
}) {
  const defaultFilterData = {
    comparator: 'all',
  };
  const [filterData, setFilterData] = useState(defaultFilterData);

  const DropdownConfig = getDropdownConfig(type);

  const changeFilterMark = (_, option) => {
    setFilterData({ comparator: option.value });
  };

  useEffect(() => {
    if (filterData.comparator === 'all') {
      changeFilter(filld, 'all');
    } else if (filterData.comparator === '\'Закрыт\', \'Закрыт по неявке\'') {
      changeFilter(filld, `in (${filterData.comparator})`);
    } else {
      changeFilter(filld, `= '${filterData.comparator}'`);
    }
  }, [filterData, changeFilter, filld]);

  return (

    <TestDiv2>
      <TestDiv>
        {smallTitle ? (<div style={{ fontSize: '12px', maxWidth: '90px', wordBreak: 'break-all' }}>{title}</div>) : (
          <div>
            {' '}
            {title}
            {' '}
          </div>
        )}
        <div style={{ marginLeft: '10px' }}>
          {/* <Dropdown */}
          {/*  config={DropdownConfig} */}
          {/*  clickHandler={changeFilterMark} */}
          {/*  replaceableTitle */}
          {/* /> */}
          <Select className="select" defaultValue={DropdownConfig.items[0].name} onChange={changeFilterMark}>
            {DropdownConfig.items.map((el) => (
              <Select.Option key={el.key} value={el.name}>{el.text}</Select.Option>
            ))}
          </Select>
        </div>
      </TestDiv>
    </TestDiv2>

  );
}

export default AllFilter;
