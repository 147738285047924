import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { PolicyContainer } from '../../../../../utils/style';
import PolicyBB from './policyBB';
import { updateScanPolicyStore } from '../../../../../../../../../logic/store/pullstate/scanPolicy';

function PolicyScan({
  title, button, satelliteParams, startScreen,
}) {
  const { satelliteIp, satellitePort } = satelliteParams;
  const getData = async () => {
    const response = await axios.get(`/proxySatellite/gate/cardreader?satelliteIp=${satelliteIp}&satellitePort=${satellitePort}`);
    return response.data;
  };
  const [screenText, setScreenText] = useState({
    title: 'Пожалуйста,',
    main: 'вставьте ваш полис',
    sub: 'в считыватель',
  });

  useEffect(() => {
    const poll = setInterval(() => {
      getData()
        .then(async (res) => {
          if (res.done && res.oms) {
            setScreenText({
              title: 'Полис успешно распознан,',
              main: 'идет поиск записей на сегодня.',
              sub: 'пожалуйста ожидайте.',
            });
            const { omsnum, birthdate } = res.oms;
            clearInterval(poll);
            const bDate = moment(birthdate, 'DDMMYYYY').format('YYYY-MM-DD');
            try {
              const responce = await axios.get(`/api/extGateway?act=get_external_precs&npolis=${omsnum}&bdate=${bDate}`, { timeout: 15000 });
              const { data: axData } = responce;
              if (axData.status === 'ok') {
                const { data: rData } = axData;
                if (rData) {
                  const { records: respData } = rData;
                  if (respData) {
                    updateScanPolicyStore({ activeScreen: 'list', records: respData });
                  } else {
                    updateScanPolicyStore({ activeScreen: 'error', errorText: '' });
                  }
                } else updateScanPolicyStore({ activeScreen: 'error', errorText: 'Записи на сегодня<br/> по данному полису отсутствуют' });
              } else {
                updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> данный полис не найден' });
              }
            } catch (e) {
              updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> сервер временно недоступен' });
            }
          } else if (res.done && !res.oms) {
            updateScanPolicyStore({ activeScreen: 'error', errorText: 'Мы сожалеем,<br/> данный полис не найден' });
          }
        });
    }, 1000);
    return () => clearInterval(poll);
  }, []);

  return (
    <PolicyContainer>
      <div className="policyBox">
        <h2 style={{ ...title, fontSize: title.fontSize * 0.8 }}>
          {screenText.title}
          <br />
          {' '}
          {screenText.main}
          <br />
          {' '}
          {screenText.sub}
        </h2>
      </div>
      <PolicyBB button={button} startScreen={startScreen} />
    </PolicyContainer>
  );
}

export default PolicyScan;
