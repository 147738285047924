import React from 'react';
import { Line } from 'react-chartjs-2';
import { Title, GraphicBox } from '../../../../../../common/ui/Common';

function Graphic(props) {
  const { data: graphicData } = props;
  const closed = graphicData.map((hour) => hour.closed);
  const graph = {
    labels: ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00',
      '06:00', '07:00', '08:00', '09:00', '10:00', '11:00',
      '12:00', '13:00', '14:00', '15:00', '16:00', '17:00',
      '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
    datasets: [{
      label: 'Количество обслуженых талонов',
      fill: false,
      lineTension: 0.05,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: '#20a8d8',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#20a8d8',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#20a8d8',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
      data: closed,
    }],
  };
  return (
    <GraphicBox>
      <Title>Почасовое распределение обслуженных талонов за текущий день</Title>
      <div style={{ height: '300px', marginBottom: 100 }}>
        <Line
          data={graph}
          height={100}
          options={{
            maintainAspectRatio: false,
          }}
        />
      </div>
    </GraphicBox>

  );
}

export default React.memo(Graphic);
