import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Dropdown, Menu } from 'antd';
import Icon from '@ant-design/icons';
import { OrderElementBox } from '../style';
import getDropdownConfig from './DropdownConfig';
import { ReactComponent as DdArrow } from '../../../../../../../../common/images/DdArrow.svg';

function OrderElement({ task, index, changeFilter }) {
  const [DropdownConfig, setDropdownConfig] = useState(null);
  const textFields = ['Оператор', 'Рабочее место', 'Очередь', '№ Талона'];
  const [dropdownTitle, setDropdownTitle] = useState('');

  const changeFilterMark = (e, options, key, name) => {
    changeFilter(`${task.fild}`, `${name}`);
  };

  useEffect(() => {
    const defaultConfig = getDropdownConfig();
    if (textFields.includes(task.FieldDescription)) {
      defaultConfig.headerTitleText = 'От А до Я';
      defaultConfig.items[0].text = 'От А до Я';
      defaultConfig.items[1].text = 'От Я до А';
    }
    setDropdownConfig(defaultConfig);
    setDropdownTitle(defaultConfig.headerTitleText);
  }, []);

  return (
    <Draggable draggableId={`${task.fild}`} index={index} key={task.id}>
      {(provided, snapshot) => (
        <OrderElementBox
          key={index}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          {task.FieldDescription}
          {DropdownConfig && (
            <Dropdown
              overlay={(
                <Menu
                  items={DropdownConfig.items.map((item) => ({
                    label: item.text,
                    icon: item.icon,
                    key: item.key,
                    onClick() {
                      changeFilterMark(item.text, item.option, item.key, item.name);
                      setDropdownTitle(item.text || item.name);
                    },
                  }))}
                />
              )}
            >
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a onClick={(e) => e.preventDefault()}>
                {dropdownTitle}
                <Icon component={DdArrow} />
              </a>
            </Dropdown>
          )}
        </OrderElementBox>
      )}

    </Draggable>
  );
}

export default OrderElement;
