import { TripleLineTemplate, withButtonsTamplate, DubleLineTemplate2 } from '../../../utils/formater';

export default function TableConfig(moreInfo, getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'queue_name',
        title: 'Очередь',
        ...getColumnSearchProps('queue_name', 'очередь'),
      },
      {
        dataIndex: '190',
        title: 'Кол-во обработанных талонов с временем ожидания',
        render: (cell, row) => DubleLineTemplate2([
          {
            name: '< 15 мин.', value: row.less, name2: 'Доля от обслуженных :', value2: row.lesspersent,
          },
          {
            name: '15 мин.< и < 30 мин.', value: row.range, name2: 'Доля от обслуженных :', value2: row.rangepersent,
          },
          {
            name: '> 30 мин.', value: row.more, name2: 'Доля от обслуженных :', value2: row.morepersent,
          },
        ]),
      },
      {
        dataIndex: 'solved_questions',
        title: 'Кол-во решенных вопросов',
      },
      {
        dataIndex: 'all_cnt',
        title: 'Обслужено',
        render: (cell, row) => withButtonsTamplate(row, moreInfo, 'WorkPlace', row.all_cnt, row.close_cnt, row.neyavka_cnt),
        sorter: (a, b) => a.all_cnt - b.all_cnt,
      },
      {
        dataIndex: '13',
        title: 'Длительность ожидания',
        render: (cell, row) => TripleLineTemplate('Общая:', row.total_wait_time, 'Максимальная:', row.maxtotalwait_format, 'Средняя:', row.avg_wait_time),
      },
      {
        dataIndex: '12',
        title: 'Длительность обслуживания',
        render: (cell, row) => TripleLineTemplate('Общая:', row.total_proc_time, 'Максимальная:', row.maxtotalproc_format, 'Средняя:', row.avg_proc_time),
      },
    ],
  };
}
