import {
  Form, Card, DatePicker, Checkbox, Button, Select, Tabs, TimePicker, InputNumber,
} from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import locale from 'antd/es/locale/ru_RU';
import { toast } from 'react-toastify';
import request from '../../../../../../logic/utils/request';

const { Option } = Select;

function AddSlots(props) {
  const {
    qeueList, fetchCalendar,
  } = props;
  const [replaseSlot, setReplaseSlot] = useState(false);
  const [addSlotType, setAddSlotType] = useState('single');
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [addAll, setAddAll] = useState(false);
  const [selectedQueues, setSelectedQeues] = useState([]);

  const [singeAddData, setSingleAddData] = useState(
    {
      date: null,
      time: null,
    },
  );

  const [multiSingleDate, setMultiSingleDate] = useState(
    {
      replace: false,
      begin: null,
      end: null,
      step: 15,
      amount: 5,
      date: null,
    },
  );

  const getDayId = async (value) => {
    const dd = +moment(value, 'YYYY.MM.DD').format('DD');
    const mm = +moment(value, 'YYYY.MM.DD').format('MM');
    const d = await request('/server/api/dcalendar/', 'get', {}, `year=${moment(value, 'YYYY.MM.DD').format('YYYY')}`);
    return d.data.data.find((el) => el.dayOfMonth === dd && el.monthNumber === mm).dayId;
  };

  const shangeDate = (dateRange) => {
    setStartTime(moment(dateRange[0])?.format('HH:mm:ss.sss'));
    setEndTime(moment(dateRange[1])?.format('HH:mm:ss.sss'));
  };

  const changeStep = (value) => {
    setMultiSingleDate((prevState) => ({
      ...prevState,
      step: value,
    }));
  };

  const changeSlotAmount = (value) => {
    setMultiSingleDate((prevState) => ({
      ...prevState,
      amount: value,
    }));
  };

  const shangeSingleDate = (date) => {
    setSingleAddData((prevState) => ({
      ...prevState,
      date: date?.format('YYYY.MM.DD'),
    }));
  };

  const shangeMultiDate = (date) => {
    setMultiSingleDate((prevState) => ({
      ...prevState,
      date: date?.format('YYYY.MM.DD'),
    }));
  };

  const shangeSingeleTime = (date) => {
    setSingleAddData((prevState) => ({
      ...prevState,
      time: date.format('HH:mm:ss.sss'),
    }));
  };

  const addToAllToggle = (e) => {
    setAddAll(e.target.checked);
  };

  const replaseSlotToggle = (e) => {
    setReplaseSlot(e.target.checked);
  };

  const selectChange = (value) => {
    setSelectedQeues(value);
  };

  const tabChange = (value) => {
    setAddSlotType(value);
  };

  const addSlot = async () => {
    let qeues;
    const data = {};

    if (addAll) qeues = qeueList.map((el) => Number(el.id));
    else qeues = selectedQueues.map(Number);

    data.queues = qeues;
    if (addSlotType === 'single') {
      data.calendarday_id = await getDayId(singeAddData.date);
      data.begin = `${singeAddData.date} ${singeAddData.time}`;
      data.end = `${singeAddData.date} ${singeAddData.time}`;
      try {
        await request('/server/api/dpacalendarslot/', 'add-v2', data);
        toast.success('Выполнено');
        fetchCalendar();
      } catch (e) {
        const { data: { error: { text: { rus } } } } = e;
        toast.error(rus);
      }
    } else {
      try {
        data.replace = replaseSlot;
        data.begin = `${multiSingleDate.date} ${startTime}`;
        data.end = `${multiSingleDate.date} ${endTime}`;
        data.step = multiSingleDate.step;
        data.amount = multiSingleDate.amount;
        const calendardayId = await getDayId(multiSingleDate.date);
        await request(`/server/api/dcalendar/${calendardayId}`, 'populate-v2', data);
        toast.success('Выполнено');
        fetchCalendar();
      } catch (e) {
        const { data: { error: { text: { rus } } } } = e;
        toast.error(rus);
      }
    }
  };

  return (
    <Card style={{ marginTop: 16 }}>
      <Form layout="vertical">
        <Form.Item key={1} label="Список услуг для добавления слотов">
          <Select
            mode="multiple"
            onChange={selectChange}
            disabled={addAll}
            locale={locale.Select}
            filterOption={
              (input, opt) => (opt.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0)
            }
          >
            {qeueList?.map((el) => <Option key={Number(el.id)}>{el.name}</Option>)}
          </Select>
          <Form.Item key={3}>
            <Checkbox onChange={addToAllToggle}>Добавить слоты по всем услугам</Checkbox>
          </Form.Item>
        </Form.Item>

        <Tabs
          onChange={tabChange}
        >
          <Tabs.TabPane tab="Одиночное добавление слота" key="single">

            <Form.Item key={6}>
              <DatePicker
                onChange={shangeSingleDate}
                locale={locale.DatePicker}
                format="DD.MM.YYYY"
                style={{ width: '280px' }}
              />
              <TimePicker
                onChange={shangeSingeleTime}
                locale={locale.DatePicker}
                format="HH:mm"
                minuteStep={5}
                style={{ width: '150px', marginLeft: '10px' }}
              />
            </Form.Item>

          </Tabs.TabPane>
          <Tabs.TabPane tab="Множественное добавление слотов" key="multi">

            <Form.Item key={4} label="Выберите день">
              <DatePicker
                onChange={shangeMultiDate}
                locale={locale.DatePicker}
                format="DD.MM.YYYY"
                style={{ width: '280px' }}
              />
            </Form.Item>
            <div style={{ display: 'flex' }}>
              <Form.Item key="step" label="Интервал времени (минуты)">
                <InputNumber
                  min={1}
                  defaultValue={multiSingleDate.step}
                  onChange={changeStep}
                />
              </Form.Item>
              <Form.Item key="amount" label="Кол-во слотов на одно время" style={{ marginLeft: '20px' }}>
                <InputNumber
                  min={1}
                  defaultValue={multiSingleDate.amount}
                  onChange={changeSlotAmount}
                />
              </Form.Item>
            </div>
            <Form.Item key={8} label="Интервал времени">
              <TimePicker.RangePicker
                onChange={shangeDate}
                locale={locale.DatePicker}
                style={{ width: '340px' }}
                format="HH:mm"
              />
            </Form.Item>
            <Form.Item key={5}>
              <Checkbox onChange={replaseSlotToggle}>Перезаписывать существующие слоты</Checkbox>
            </Form.Item>

          </Tabs.TabPane>
        </Tabs>

        <Form.Item key={6}>
          {addSlotType === 'single' ? (
            <Button
              onClick={addSlot}
              disabled={
              singeAddData.date === null
              || singeAddData.time === null
              || (!selectedQueues.length > 0 && !addAll)
            }
            >
              Добавить слоты
            </Button>
          )
            : (
              <Button
                onClick={addSlot}
                disabled={
                  multiSingleDate.date === null
                || multiSingleDate.time === null
                || endTime === null
                || startTime === null
                || (!selectedQueues.length > 0 && !addAll)
              }
              >
                Добавить слоты
              </Button>
            )}

        </Form.Item>
      </Form>
    </Card>
  );
}

export default AddSlots;
