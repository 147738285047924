import Request from '../../../../../../../../logic/utils/request';
import PrepearServerAnswer from './prepearServerAnswer';

export default async function SqlQuery(startDate, endDate) {
  const query = `select mt.*, count(*) OVER() AS total_count from (select  sd.id,
  sd.ticket_id,
  syrv.survey_id,
  case when syrv.survey_id is null then 'Обращение' else 'Опрос' end as type,
  quest.surveyname,
  sd.audiorecordpath, 
  sd.videorecordpath, 
  sd.photo_b64,
  to_char(sd.created_on, 'DD.MM.YYYY hh24:MI') as created_on,
  sd.textrecord,
  CASE WHEN th."number" is null THEN 'Без талона' 
  when th."number" is not null then th."number"
  end as "number",
  CASE WHEN th.username is null THEN 'Не привязан' 
  WHEN th.username is not null then th.username 
  end as username,
  CASE WHEN th.fio is null THEN 'Не привязан' 
  WHEN th.fio is not null then th.fio 
  end as fio,
  coalesce(sd.source, 'Не указан') as source
  from eq.tqcsessiondata sd
  left join (
  select survey_id, sessiondata_id from eq.tqcsessionquestion
  ) syrv 
  on sd.id = syrv.sessiondata_id
  left join (select ww.surveyname,
  qq.sessiondata_id 
  from eq.tqcsessionquestion qq,
  eq.tqcsurvey ww
  where qq.survey_id = ww.id) quest
  on sd.id = quest.sessiondata_id
  left join (
  select 
  distinct (th.ticket_id), 
  th.number,
  tu.username, tu.fio
  from eq.ttickethistory th,
  eq.tuser tu 
  where th.status in (3, 4, 9) 
  and tu.id = th.modified_by_id
  ) th 
  on sd.ticket_id = th.ticket_id) mt 
  WHERE created_on::timestamp >= '${startDate}'
  and created_on::timestamp <= '${endDate}' 
  `;

  const queryExport = `select mt.*, count(*) OVER() AS total_count from (select  sd.id,
    sd.ticket_id,
    syrv.survey_id,
    COALESCE(quest.surveyname, 'Обращение') as surveyname,
    sd.audiorecordpath, 
    sd.videorecordpath, 
    1 as id,
    to_char(sd.created_on, 'DD.MM.YYYY hh24:MI') as created_on,
    sd.textrecord,
    CASE WHEN th."number" is null THEN 'Без талона' 
    when th."number" is not null then th."number"
    end as "number",
    CASE WHEN th.username is null THEN 'Не привязан' 
    WHEN th.username is not null then th.username 
    end as username,
    CASE WHEN th.fio is null THEN 'Не привязан' 
    WHEN th.fio is not null then th.fio 
    end as fio,
    coalesce(sd.source, 'Не указан') as source
    from eq.tqcsessiondata sd
    left join (
    select survey_id, sessiondata_id from eq.tqcsessionquestion
    ) syrv 
    on sd.id = syrv.sessiondata_id
    left join (select ww.surveyname,
    qq.sessiondata_id 
    from eq.tqcsessionquestion qq,
    eq.tqcsurvey ww
    where qq.survey_id = ww.id) quest
    on sd.id = quest.sessiondata_id
    left join (
    select 
    distinct (th.ticket_id), 
    th.number,
    tu.username, tu.fio
    from eq.ttickethistory th,
    eq.tuser tu 
    where th.status in (3, 4, 9) 
    and tu.id = th.modified_by_id
    ) th 
    on sd.ticket_id = th.ticket_id) mt 
    WHERE created_on::timestamp >= '${startDate}'
    and created_on::timestamp <= '${endDate}'`;

  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const data = PrepearServerAnswer(res.data);

  return { data, queryExport };
}
