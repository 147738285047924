import React, { useState } from 'react';
import moment from 'moment';
import { Tabs, Typography } from 'antd';
import Params from './params/Params';
import { ReportBigBox } from '../../../../../common/ui/Common';
import Request from './Elements/Request/Request';
import ConsolidatedReport from './Elements/ConsolidatedReport/ConsolidatedReport';
import DetailedReport from './Elements/DetailedReport/DetailedReport';
import NpsReport from './Elements/Nps/NpsReport';

function QualityControl(props) {
  const { preloader } = props;
  const [activeSection, setActiveSection] = useState('requests');
  const [settings, setSettings] = useState({
    startDate: moment().startOf('month').format('DD.MM.YYYY HH:mm'),
    endDate: moment().endOf('day').format('DD.MM.YYYY HH:mm'),
    questId: '',
  });

  return (
    <ReportBigBox>
      <Typography.Title style={{ textAlign: 'center', color: '#25547A' }}>Оценочные опросы</Typography.Title>

      <Params
        activeSection={activeSection}
        params={{ settings, setSettings }}
      />

      <Tabs
        activeKey={activeSection}
        onChange={setActiveSection}
      >
        <Tabs.TabPane tab="Обращения" key="requests">
          <Request
            settings={settings}
            preloader={preloader}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Сводный отчет" key="free report">
          <ConsolidatedReport
            settings={settings}
            preloader={preloader}
            activeSection={activeSection}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Детализированный отчет" key="detailed">
          <DetailedReport
            settings={settings}
            preloader={preloader}
            activeSection={activeSection}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Лояльность" key="nps">
          <NpsReport
            settings={settings}
            preloader={preloader}
            activeSection={activeSection}
          />
        </Tabs.TabPane>
      </Tabs>

    </ReportBigBox>
  );
}

export default QualityControl;
