import React from 'react';
import { BtnTable } from '../../../../common/ui/Common';
import { ReactComponent as Edit } from '../../../../common/images/edit.svg';
import { ReactComponent as False } from '../../../../common/images/false.svg';
import { ReactComponent as True } from '../../../../common/images/true.svg';
import c from '../../../../common/ui/theme/Colors';
import IconFormatter from '../../../../pages/private/utils/iconFormatter';
import { dateFormat } from '../../../utils/tableFormatters';

function priceFormatter(column, colIndex, { filterElement }) {
  return (
    <div className="filterBoxTable">
      {column.dataField !== 'Settings' && <Edit style={{ margin: '2px 10px 0 0' }} fill={c.main} /> }
      { filterElement }
    </div>
  );
}

function groupFormatter(value, row, roles) {
  return (
    <div className="rolesGroupTable">
      {value.map((item) => roles.map((roleItem) => {
        if (item === roleItem.id) {
          return <div key={item}>{roleItem.printname}</div>;
        }
        return false;
      }))}
    </div>
  );
}

function checkFormatter(value, revers) {
  return (value
    ? <BtnTable bg="accent" hNone>{!revers ? <False fill="white" /> : <True fill="white" />}</BtnTable>
    : <BtnTable bg="gray" hNone>{!revers ? <True fill="white" /> : <False fill="white" />}</BtnTable>
  );
}

export default function Setting(roles, write, getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: 'id',
        title: 'ID',
        ...getColumnSearchProps('id', 'ID'),
      },
      {
        dataIndex: 'fio',
        title: 'ФИО',
        ...getColumnSearchProps('fio', 'ФИО'),
        headerFormatter: priceFormatter,
      },
      {
        dataIndex: 'username',
        title: 'Логин',
        ...getColumnSearchProps('username', 'логин'),
        headerFormatter: priceFormatter,
      },
      {
        dataIndex: 'connectedRoles',
        title: 'Роли',
        formatter: (value, row) => groupFormatter(value, row, roles),
      },
      {
        dataIndex: 'modified_by_username',
        title: 'Изменен',
        ...getColumnSearchProps('modified_by_username', 'кем изменён'),

        editable: false,
      },
      {
        dataIndex: 'modified_on',
        title: 'Время изменения',
        ...getColumnSearchProps('modified_on', 'время изменения'),
        render: (value) => (
          <p>{dateFormat(value)}</p>
        ),
      },
      {
        dataIndex: 'hidden',
        title: 'Работа на АРМ',
        width: '10%',
        render: (value) => checkFormatter(value, false),
      },
      {
        dataIndex: 'blocked',
        title: 'Вход через Панель администрирования',
        width: '10%',
        render: (value) => checkFormatter(value, false),
      },
      {
        dataIndex: 'Settings',
        width: 180,
        render: () => IconFormatter({ filter: true, write, params: true }),
      },
    ],
  };
}
