import React from 'react';
import {
  DivWithoutStyles,
  LineDescription,
  LineValue,
  ReportLine,
  SomeLineColumn,
  StarRaitingContainer,
  StarRatings,
  StarRatingsButton,
  StarRatingsTop,
} from '../../../../../components/report/ReportTableColumns';

export function GeneralReportMarkTemplate(markValue, markCnt) {
  return (markValue !== '0%'
    ? (
      <SomeLineColumn>
        <ReportLine>
          <LineDescription>Количество оценок:</LineDescription>
          <LineValue>{markCnt}</LineValue>
        </ReportLine>

        <ReportLine>
          <LineDescription>Средняя оценка</LineDescription>
          <LineValue>
            <StarRaitingContainer title="Средняя оценка качества обслуживания">
              <StarRatings>
                <StarRatingsTop width={markValue} />
                <StarRatingsButton />
              </StarRatings>
            </StarRaitingContainer>
          </LineValue>
        </ReportLine>
      </SomeLineColumn>
    )
    : (<DivWithoutStyles>Оценок нет</DivWithoutStyles>)
  );
}

export default null;
