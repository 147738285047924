import { Store } from 'pullstate';
import moment from 'moment';

const defaultValues = {
  qeueList: [],
  selectedQueue: {},
  interval: 15,
  amount: 5,
  approved: true,
  replace: false,
  selectedDate: {
    id: undefined,
    data: [],
    day: moment().format('DD.MM.YYYY'),
  },
  time: {
    hour: Number(moment().format('HH')),
    minute: Math.ceil(Number(moment().format('mm')) / 5) * 5,
  },
  startTime: {
    hour: Number(moment().format('HH')),
    minute: Math.ceil(Number(moment().format('mm')) / 5) * 5,
  },
  endTime: {
    hour: Number(moment().format('HH')) + 1,
    minute: 0,
  },
};

const preEntryStore = new Store(defaultValues);

export const updatePreEntryStore = (values) => {
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      s[key] = values[key];
    });
  };
  preEntryStore.update(updatedValues);
};

export default preEntryStore;
