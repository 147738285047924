import {
  useEffect,
  useState,
} from 'react';
import SqlQuery from './SqlQuery';
import appStore from '../../../../../../../../../logic/store/pullstate/appStore';
import { CardReportTemplate } from '../../../../utils/formater';

const ReportCard = (props) => {
  const { view } = props;
  const [ReportParams, setReportParams] = useState();
  const startDateMoment = appStore.useState((s) => s.reportConfig.startDate);
  const endDateMoment = appStore.useState((s) => s.reportConfig.endDate);
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const startDate = startDateMoment.format(dateFormat);
  const endDate = endDateMoment.format(dateFormat);

  useEffect(() => {
    if (view) {
      SqlQuery(startDate, endDate).then((resp) => {
        const { dataObjects, query } = resp;
        const params = {
          items: dataObjects,
          query,
        };
        setReportParams(params);
      });
    }
  }, [startDate, endDate]);

  return (
    CardReportTemplate(ReportParams ? ReportParams.items[0] : [])
  );
};

export default ReportCard;
