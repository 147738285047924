export default function TableConfig() {
  return {
    columns: [
      {
        dataIndex: 'fio',
        title: 'Оператор',
      }, {
        dataIndex: 'name',
        title: 'Рабочее место',
      }, {
        dataIndex: 'action',
        title: 'Действие',
      }, {
        dataIndex: 'displaydate',
        title: 'Дата действия',
      },
      {
        dataIndex: 'displaytime',
        title: 'Время действия',
      },
    ],
  };
}
