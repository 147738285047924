import React from 'react';
import {
  Card, Col, Row, Space, Typography,
} from 'antd';
import moment from 'moment';
import LicenceComponent from './components/licence/LicenceComponent';
import Loader from './components/loader';
import UpdateBlock from './components/UpdateBlock';
import { useAbout } from './useAbout';

const { Title, Text } = Typography;

function About({ preloader, showError }) {
  const {
    arrData,
    fLatest,
    info,
    viewNoDate,
    days,
    hours,
    min,
    sec,
    fCurrent,
  } = useAbout();

  return (
    <Space direction="vertical" style={{ width: '100%', textAlign: 'center', justifyItems: 'center' }} size="middle">
      <LicenceComponent preloader={preloader} showError={showError} />
      <Title>О системе</Title>

      <Row justify="center" gutter={[30, 30]} style={{ width: '95%', maxWidth: '1000px', margin: '0 auto' }}>
        {arrData.map((el) => (
          <Col span={6} key={el.title}>
            <Card>
              <Title type="primary" level={5}>{el.title}</Title>
              <Text type="secondary">
                {el.date
                  ? moment(info[el.text], 'YYYY.MM.DD HH:mm:ss').format('HH:mm:ss - DD.MM.YYYY')
                  : viewNoDate(el)}
              </Text>
            </Card>
          </Col>
        ))}
        <Col span={6}>
          <Card>
            <Title type="primary" level={5}>
              Время работы
              <br />
              В-сервера:
            </Title>
            { fLatest
              ? (
                <Text type="secondary">
                  {days ? `${days} дн.` : ''}
                  {' '}
                  {hours ? `${hours} час.` : ''}
                  {' '}
                  {min ? `${min} мин.` : '0 мин.'}
                  {' '}
                  {sec && `${sec} сек.`}
                </Text>
              )
              : <Loader />}
          </Card>
        </Col>
        <UpdateBlock
          title="Панель администрирования"
          cVersion={(fCurrent) && fCurrent.admin}
          lVersion={(fLatest) && fLatest.admin}
          loading={fCurrent && fLatest}
          type="admin"
        />
      </Row>
    </Space>
  );
}

export default About;
