import React from 'react';
import moment from 'moment';
import Button from 'antd/es/button';
import randomId from '../../../../../../../../logic/utils/randomId';

const parseAudio = (audio) => {
  if (!audio) return <span>Записи нет</span>;
  const audioArr = audio.split(',');
  return audioArr.map((record, idx) => {
    const audioPath = record.split('/');
    const src = `/records/${audioPath[audioPath.length - 1]}`;
    // eslint-disable-next-line jsx-a11y/media-has-caption
    return <audio key={randomId()} src={src} controls style={idx !== 0 ? { marginTop: '10px' } : {}} />;
  });
};

// const downloadExtraData = (row) => {
//  try {
//    const chatData = JSON.parse(row.extradata);
//    let chatFile = '';
//    chatData.forEach((chatMessage) => {
//      chatFile += `${moment(chatMessage.time).format('DD.MM.YYYY HH:mm:ss')}
// ${chatMessage.type}: ${chatMessage.text ? chatMessage.text : chatMessage.file.downloadLink}`;
//      chatFile += '\r\n';
//    });
//    const dataStr = `data:text/plain;charset=utf-8,${encodeURIComponent(chatFile)}`;
//    const downloadAnchorNode = document.createElement('a');
//    downloadAnchorNode.setAttribute('href', dataStr);
//    downloadAnchorNode.setAttribute('download', `chat-${row.ticket_id}.txt`);
//    document.body.appendChild(downloadAnchorNode); // required for firefox
//    downloadAnchorNode.click();
//    downloadAnchorNode.remove();
//  } catch (e) {
//    // eslint-disable-next-line no-console
//    console.log(e.message);
//  }
// };

export default function TableConfig(moreInfo) {
  return (
    [
      {
        title: 'Информация о талоне',
        children: [
          {
            title: 'Id',
            dataIndex: 'ticket_id',
            key: 'ticket_id',
            width: 100,
            fixed: 'left',
            sorter: (a, b) => a.ticket_id - b.ticket_id,
          },
          {
            title: '№',
            dataIndex: 'ticket_number',
            key: 'ticket_number',
            width: 100,
            fixed: 'left',
          },
        ],
      },
      {
        title: 'Дата',
        children: [
          {
            title: 'Создания',
            dataIndex: 'create_data',
            key: 'create_data',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.create_data, 'DD.MM.YYYY HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.create_data, 'DD.MM.YYYY HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
          {
            title: 'Вызова',
            dataIndex: 'start_proc_ticket',
            key: 'start_proc_ticket',
            width: 160,
            // sorter: (a, b) => {
            //   const aToSec = new Date(moment(a.start_proc_ticket, 'DD.MM.YYYY HH:mm:ss'))
            //   .getTime();
            //   const bToSec = new Date(moment(b.start_proc_ticket, 'DD.MM.YYYY HH:mm:ss'))
            //   .getTime();
            //   return aToSec * bToSec ? aToSec - bToSec : -10000000000000000000000000;
            // //   if (!aToSec) return -1000000000;
            // //   if (!bToSec) return -1000000000;
            // //   return (aToSec - bToSec);
            // },
          },
          {
            title: 'Закрытия',
            dataIndex: 'close_data',
            key: 'close_data',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.close_data, 'DD.MM.YYYY HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.close_data, 'DD.MM.YYYY  HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
        ],
      },
      {
        title: 'Длительность',
        children: [
          {
            title: 'Ожидания',
            dataIndex: 'total_wait_time',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.total_wait_time, 'HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.total_wait_time, 'HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
          {
            title: 'Oбслуживания',
            dataIndex: 'total_proc_time',
            width: 160,
            sorter: (a, b) => {
              const aToSec = new Date(moment(a.total_proc_time, 'HH:mm:ss')).getTime();
              const bToSec = new Date(moment(b.total_proc_time, 'HH:mm:ss')).getTime();
              return (aToSec - bToSec);
            },
          },
        ],
      },
      {
        title: 'Очередь',
        dataIndex: 'queue_name',
      },
      {
        title: 'Рабочее место',
        dataIndex: 'workplace_name',
      },
      {
        title: 'Оператор',
        dataIndex: 'user_fio',
      },
      {
        title: 'Источник',
        dataIndex: 'source',
        width: 200,
      },
      //  {
      //    title: 'Доп. данные',
      //    dataIndex: 'extradata',
      //    width: 200,
      //    render: (_, res) => {
      //      const { extradata } = res;
      //      return (
      //        <div style={{
      //          display: 'flex', flexDirection: 'column',
      //  justifyContent: 'center', alignItems: 'center',
      //        }}
      //        >
      //          {extradata
      //            ? (
      //              <button
      //                type="button"
      //                className="addButton"
      //                onClick={() => downloadExtraData(res)}
      //              >
      //                Скачать
      //              </button>
      //            )
      //            : 'Нет'}
      //        </div>
      //      );
      //    },
      //  },
      {
        title: 'Кол-во решенных вопросов',
        dataIndex: 'solved_questions',
        width: 150,
        sorter: (a, b) => a.solved_questions - b.solved_questions,
      },
      {
        title: 'Приоритет',
        dataIndex: 'prior',
        width: 130,
        filters: [
          {
            text: 'Обычный',
            value: 'Обычный',
          },
          {
            text: 'Высокий',
            value: 'Высокий',
          },
        ],
        onFilter: (value, record) => (record.prior.indexOf(value) === 0),
      },
      {
        title: 'Предворительная запись',
        dataIndex: 'preorderstatus',
        width: 180,
        filters: [
          {
            text: 'Да',
            value: 'Да',
          },
          {
            text: 'Нет',
            value: 'Нет',
          },
        ],
        onFilter: (value, record) => (record.prior.indexOf(value) === 0),
      },
      {
        title: 'Оценка',
        dataIndex: 'ticket_mark',
        width: 100,
        sorter: (a, b) => a.ticket_mark - b.ticket_mark,
      },
      {
        title: 'Аудио запись',
        dataIndex: 'audio',
        width: 320,
        render: (_, res) => {
          const { audio } = res;
          return (
            <div style={{
              display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
            }}
            >
              {parseAudio(audio)}
            </div>
          );
        },
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        fixed: 'right',
        width: 150,
        filters: [
          {
            text: 'Закрыт',
            value: 'Закрыт',
          },
          {
            text: 'Закрыт по неявке',
            value: 'Закрыт по неявке',
          },
          {
            text: 'Удален системой',
            value: 'Удален системой',
          },
        ],
        onFilter: (value, record) => (record.status.indexOf(value) === 0),
      },
      {
        title: '',
        dataIndex: '',
        fixed: 'right',
        width: 120,
        render: (_, row) => (
          <Button
            default
            onClick={() => {
              moreInfo(row);
            }}
          >
            История
          </Button>
        ),
      },
    ]
  );
}
