import React from 'react';
import Time from './time';

function TimeSlots(props) {
  const {
    time, fetchCalendar, selectedDate,
  } = props;

  const renderSlot = (el) => (
    <Time
      key={el}
      el={el}
      fetchCalendar={fetchCalendar}
      selectedDate={selectedDate}
    />
  );

  return (
    <div>
      {time.map((el) => renderSlot(el))}
    </div>
  );
}

export default TimeSlots;
