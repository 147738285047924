import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import ReportTitle from '../../../../../../quality-control/Elements/DetailedReport/TableTitle';
import AvgWaitTimeQuery from './SqlAvgWaitTime';
import AvgProcTimeQuery from './SqlAvgProcTime';
import appStore from '../../../../../../../../../../logic/store/pullstate/appStore';

function AvgTimeGraphic(props) {
  const { view } = props;
  const { reportConfig } = useSelector((state) => state);
  const [graphicData, setGraphicData] = useState();
  const startDateMoment = appStore.useState((s) => s.reportConfig.startDate);
  const endDateMoment = appStore.useState((s) => s.reportConfig.endDate);
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const startDate = startDateMoment.format(dateFormat);
  const endDate = endDateMoment.format(dateFormat);

  const BasicSettings = {
    fill: false,
    lineTension: 0.05,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
  };

  const fetchGraphicData = async () => {
    const labels = [];
    const qq = [];
    const ww = [];
    const { dataObjects: pTimeDataObjects } = await AvgProcTimeQuery(startDate, endDate);
    const { dataObjects: wTimeDataObjects } = await AvgWaitTimeQuery(startDate, endDate);

    wTimeDataObjects.forEach((el) => {
      labels.push(el[0]);
      qq.push(el[3]);
    });

    pTimeDataObjects.forEach((el) => {
      ww.push(el[3]);
    });

    const allData = {
      ...BasicSettings,
      label: 'Ожидания',
      data: qq,
      pointHoverBackgroundColor: '#7486fc',
      pointBorderColor: '#7486fc',
      borderColor: '#7486fc',
      backgroundColor: 'rgba(3, 82, 252, 0.4)',
    };

    const noneData = {
      ...BasicSettings,
      label: 'Обслуживания',
      data: ww,
      pointHoverBackgroundColor: '#f75454',
      pointBorderColor: '#f75454',
      borderColor: '#f75454',
      backgroundColor: 'rgba(252, 5, 5, 0.4)',
    };

    const ReportSettings = {
      labels,
      datasets: [allData, noneData],
    };

    const doughnutOptions = {
      maintainAspectRatio: false,
      // responsive: false,
      intersect: true,
      scales: {
        yAxes: [
          {
            type: 'linear',
            position: 'left',
            ticks: {
              // stepSize: 60 * 10,
              beginAtZero: true,
              callback: (value) => (`${Math.round(value / 60)} мин.`),
            },
          },
        ],
      },
      legend: {
        position: 'top',
        labels: {
          boxWidth: 50,
        },
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            let label = data.labels[tooltipItem.index] || '';
            const xlabel = data.datasets[tooltipItem.datasetIndex].label || '';
            const times = Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);

            // Ожидания -> Ожидание
            const changedXlabel = `${xlabel.substring(0, xlabel.length - 1)}е`;

            label = `${changedXlabel}: ${moment.utc(times * 1000).format('HH:mm:ss')}`;

            // prev version
            // if (label) {
            //   label += ': ';
            // }
            // label += moment.utc(times * 1000).format('HH:mm:ss');

            return label;
          },
        },
      },
    };
    console.log('ReportSettings === ', ReportSettings);
    const doughnutParams = {
      Data: ReportSettings,
      Options: doughnutOptions,
    };

    setGraphicData(doughnutParams);
  };

  useEffect(() => {
    if (view) {
      fetchGraphicData();
    }
  }, [reportConfig, endDate, startDate]);

  return (
    <>
      <ReportTitle name="Среднее время по очередям" />
      {graphicData
          && (
          <div style={{ height: '300px' }}>
            <Bar
              data={graphicData.Data}
              height={300}
              options={graphicData.Options}
            />
          </div>
          )}
    </>
  );
}

// export default AvgTimeGraphic;

export default AvgTimeGraphic;
