import { GeneralReportMarkTemplate, DubleLineTemplate } from '../../../../../utils/formater';

export default function TableConfig(getColumnSearchProps) {
  return {
    columns: [
      {
        dataIndex: '2',
        title: 'Оператор',
        ...getColumnSearchProps('2', 'оператора'),
      },
      {
        dataIndex: '9',
        title: 'Рабочее время',
        sorter: (a, b) => a[9] - b[9],
        sortDirections: ['descend', 'ascend'],
        render: (cell, row) => DubleLineTemplate('time', 'Время обслуживания талонов:', row[6], 'count', 'Рабочих дней:', row[4]),
      },
      {
        dataIndex: '3',
        title: 'Талонов обработано',
        render: (cell, row) => DubleLineTemplate('count', 'Всего:', row[3], 'count', 'В день:', row[5]),
        sorter: (a, b) => b[3] - a[3],
        sortDirections: ['descend', 'ascend'],
      },
      {
        dataIndex: '8',
        title: 'Оценка',
        sorter: (a, b) => b[8] - a[8],
        sortDirections: ['descend', 'ascend'],
        render: (cell, row) => GeneralReportMarkTemplate(`${row[7] * 20}%`, row[8]),
      },
    ],
  };
}
