import React from 'react';
import {
  FilterBox,
  FilterBoxTitle,
  FilterItemBox,
  FilterLineBox,
} from '../style';
import AllFilterInput from '../utils/ServedFilters/AllFilterInput';
import AllFilterdd from '../utils/ServedFilters/AllFilterdd';

function TiketParamsFilters({
  changeFilter,
  showPin,
  showClientFio,
  showClientPhone,
  showClientMail,
  showPrior,
  showRegistred,
  showTicketId,
}) {
  return (
    <FilterBox>
      <FilterBoxTitle>Параметры талона</FilterBoxTitle>
      <FilterItemBox>
        <FilterLineBox>
          <AllFilterInput
            changeFilter={changeFilter}
            filld="ticket_number"
            title="№ Талона"
            smallTitle="true"
            stringValue="true"
          />
        </FilterLineBox>
        {showTicketId && (
        <FilterLineBox>
          <AllFilterInput
            changeFilter={changeFilter}
            filld="ticket_id"
            title="Id Талона"
            smallTitle="true"
            stringValue="true"
          />
        </FilterLineBox>
        )}
        {showPrior
      && (
      <FilterLineBox>
        <AllFilterdd
          changeFilter={changeFilter}
          filld="prior"
          title="Приоритет"
          smallTitle="true"
          type="prior"
        />
      </FilterLineBox>
      )}
        <FilterLineBox>
          <AllFilterdd
            changeFilter={changeFilter}
            filld="status"
            title="Статус"
            smallTitle="true"
            type="status"
          />
        </FilterLineBox>
        <FilterLineBox>
          <AllFilterdd
            changeFilter={changeFilter}
            filld="ticket_mark"
            title="Оценка"
            smallTitle="true"
            type="ticket_mark"
          />
        </FilterLineBox>
        {showPin
      && (
      <FilterLineBox>
        <AllFilterInput
          changeFilter={changeFilter}
          filld="pincode"
          title="Пин-код"
          smallTitle="true"
          stringValue="true"
        />
      </FilterLineBox>
      )}

        {showClientFio
      && (
      <FilterLineBox>
        <AllFilterInput
          changeFilter={changeFilter}
          filld="clientfio"
          title={(
            <>
              ФИО
              <br />
              клиента
            </>
          )}
          smallTitle="true"
          stringValue="true"
        />
      </FilterLineBox>
      )}
        {showClientMail
      && (
      <FilterLineBox>
        <AllFilterInput
          changeFilter={changeFilter}
          filld="clientmail"
          title="E-mail клиента"
          smallTitle="true"
          stringValue="true"
        />
      </FilterLineBox>
      )}
        {showClientPhone
      && (
      <FilterLineBox>
        <AllFilterInput
          changeFilter={changeFilter}
          filld="clientphone"
          title={(
            <>
              Телефон
              <br />
              клиента
            </>
          )}
          smallTitle="true"
          stringValue="true"
        />
      </FilterLineBox>
      )}
        {showRegistred
        && (
        <FilterLineBox>
          <AllFilterdd
            changeFilter={changeFilter}
            filld="registered"
            title="Зарегистрирован"
            smallTitle="true"
            type="registered"
          />
        </FilterLineBox>
        )}
      </FilterItemBox>
    </FilterBox>
  );
}
export default TiketParamsFilters;
