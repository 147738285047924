import React, { useState, useCallback, useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenCards from './ScreenCards';
import {
  Title,
  ButtonBack,
  ContentBox,
  PreloaderBox,
  BtnAdd,
} from '../../../../../common/ui/Common';
import request from '../../../../../logic/utils/request';
import ScreenSchema from './ScreenSchema';

function Screens({ preloader, showError }) {
  const [screenList, setScreenList] = useState(null);
  const [nowScreen, setNowScreen] = useState(null);
  const [zone, setZone] = useState(null);
  const zoneId = Number(useParams().id);
  const navigate = useNavigate();

  const fetchScreens = useCallback(async () => {
    preloader();
    try {
      const res = await request(`/server/api/zone/${zoneId}`, 'get');
      preloader();
      if (res.data.data) {
        setZone(res.data.data);
        setScreenList(res.data.data.config.screens);
        if (nowScreen) {
          res.data.data.config.screens.forEach((item) => {
            if (item.screenId === nowScreen.screenId) {
              setNowScreen(item);
            }
          });
        }
      }
    } catch (e) {
      const text = { text: { rus: 'Зона недоступна' } };
      showError(text);
    }
  }, [showError, preloader, nowScreen, zoneId]);

  useEffect(() => {
    fetchScreens();
  }, [fetchScreens]);

  const screenEdit = (screen) => {
    if (screen === 'print' || screen === 'scan') return;
    if (typeof screen === 'string') {
      navigate(`/handle-points/${zoneId}/${screen}`);
    } else {
      setNowScreen(screen);
    }
  };

  const screenTypes = [
    { id: 'startScreen', name: 'Начальный экран' },
    { id: 'dictScreen', name: 'Экран справочник' },
    { id: 'inputScreen', name: 'Экран с полями ввода' },
    { id: 'btnScreen', name: 'Экран с кнопками' },
    { id: 'multiScreen', name: 'Мультивыбор' },
    { id: 'infoScreen', name: 'Информационный экран' },
  ];

  return (
    <>
      {zone && (
      <Title>
        <ButtonBack onClick={() => navigate(-1)}>
          <FaArrowLeft size="14px" />
        </ButtonBack>
        Конструктор экранов зоны обслуживания -
        {' '}
        <span>{zone.name}</span>
      </Title>
      )}
      <ContentBox>
        {screenList && (
          <ScreenSchema onChange={screenEdit} screens={screenList} />
        )}
        <BtnAdd
          onClick={() => navigate(`/handle-points/${zoneId}/new`)}
          add
        >
          <span>Добавить</span>
        </BtnAdd>

        <PreloaderBox>
          <ScreenCards
            screenList={screenList}
            zone={zone}
            fetchScreens={fetchScreens}
            zoneId={zoneId}
            screenTypes={screenTypes}
            screenEdit={screenEdit}
          />
        </PreloaderBox>
      </ContentBox>
    </>
  );
}
export default Screens;
