import styled from 'styled-components';

export const ButtonBack = styled.div`
  color: #fff;
  /* position: absolute; */
  text-transform: uppercase;
  font-size: 24px;
  font-family: Roboto;
  font-weight: 800;
  text-align: center;
  top: 0;
  left: 0;
  background-color: #FC4C02;
  border-radius: 3px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-right: 10px;
`;

export const ReportTitleBox = styled.div`
  font-size: 20px;
  text-transform: uppercase;
  padding: ${(p) => (p.reportTitile ? 'none;' : '10px 15px;')}
  background-color: ${(p) => p.theme.accent};
  color: ${(p) => p.theme.white};
  margin-bottom: ${(p) => (p.reportTitile ? '10px;' : '0px;')};
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 3px 3px 0 0;
`;

export const TableTitle = styled.div`
  color: ${(p) => p.theme.buttonHover};
`;

export const TableTitleLeftItem = styled.div`
  display: flex;
  align-items: center;
`;
