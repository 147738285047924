import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import ReportTitle from '../../../ReportTitle';
import { ReportBox } from '../../../../../../../../components/report/ReportTableColumns';
import Preloader from '../../../../../../../../components/preloader/Preloader';
import { PreloaderBox } from '../../../../../../../../../../common/ui/Common';
import appStore from '../../../../../../../../../../logic/store/pullstate/appStore';
import { useAnswerFnc } from './useAnswerFnc';

function ReportBar(props) {
  const { view, loader, setLoader } = props;
  const [ReportParams, setReportParams] = useState();
  const startDateMoment = appStore.useState((s) => s.reportConfig.startDate);
  const endDateMoment = appStore.useState((s) => s.reportConfig.endDate);
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const startDate = startDateMoment.format(dateFormat);
  const endDate = endDateMoment.format(dateFormat);

  const answerFnc = useAnswerFnc({
    setLoader,
    startDate,
    endDate,
    setReportParams,
  });

  useEffect(() => {
    if (view) {
      answerFnc();
    }
  }, [startDate, endDate, answerFnc]);

  return (
    <ReportBox>
      <ReportTitle
        name="Распределение талонов по дням недели"
      />
      { ReportParams
        && (
        <div style={{ height: '300px' }}>
          <Bar
            data={ReportParams}
            height={50}
            options={{
              maintainAspectRatio: false,
              intersect: true,
            }}
          />
        </div>
        )}
      {loader
        && (
          <PreloaderBox>
            <Preloader isOpen small />
          </PreloaderBox>
        )}
    </ReportBox>

  );
}

export default ReportBar;
