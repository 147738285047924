import React from 'react';
import Accordion from '../components/Accordion';
import ServedFilter from '../utils/ServedFilters/ServedFilters';
import WaitTimeFilter from './WaitTimeFilter';
import ProcTimeFilter from './ProcTimeFilter';
import WorkTimeFilter from './WorkTimeFilter';
import ActionFilters from './ActionFilters';
import MarkFilter from './MarkFilter';
import ServedTimeFilters from './ServedTimeFilters';
import ServedDateFilters from './ServedDateFilters';
import TiketParamsFilters from './TiketParamsFilters';
import { ReportFilterItems } from '../style';

function DopFilters({
  changeFilter,
  showFilters,
  showPin,
  showClientFio,
  showClientPhone,
  showClientMail,
  showPrior,
  showRegistred,
  showTicketId,
}) {
  const filtersCmp = {
    servedfilters: <ServedFilter changeFilter={changeFilter} key={1} />,
    waitTimeFilters: <WaitTimeFilter changeFilter={changeFilter} key={2} />,
    procTimeFilter: <ProcTimeFilter changeFilter={changeFilter} key={3} />,
    workTimeFilter: <WorkTimeFilter changeFilter={changeFilter} key={4} />,
    markFilters: <MarkFilter changeFilter={changeFilter} key={5} />,
    actionFilters: <ActionFilters changeFilter={changeFilter} key={6} />,
    servedTimeFilters: <ServedTimeFilters changeFilter={changeFilter} key={7} />,
    servedDateFilters: <ServedDateFilters changeFilter={changeFilter} key={8} />,
    tiketParamsFilters: <TiketParamsFilters
      showPrior={showPrior}
      changeFilter={changeFilter}
      key={9}
      showPin={showPin}
      showClientFio={showClientFio}
      showClientPhone={showClientPhone}
      showClientMail={showClientMail}
      showRegistred={showRegistred}
      showTicketId={showTicketId}
    />,
  };

  const renderFilterElements = () => (
    <ReportFilterItems>
      {showFilters.map((fitem) => (
        filtersCmp[fitem]
      ))}
    </ReportFilterItems>
  );

  return (
    <Accordion
      title="Дополнительные фильтры"
      content={renderFilterElements()}
    />

  );
}

export default DopFilters;
