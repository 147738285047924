import React from 'react';

export default function TableConfig(list) {
  const colObjects = [];
  list.forEach((li) => {
    colObjects.push({
      dataIndex: 'answerdata',
      title: `${li[1]}`,
      width: 220,
      align: 'center',
      render: (_, cell) => (
        <p>{cell.answerdata[li[0]] || '—'}</p>
      ),
    });
  });

  return {
    columns: [
      {
        dataIndex: 'created_on',
        title: 'Дата оценки',
        width: 150,
        fixed: 'left',
      },
      ...colObjects,
      {
        dataIndex: 'answerdata',
        title: 'Средняя оценка',
        width: 190,
        align: 'center',
        render: (_, cell) => (
          <p>{cell.answerdata.qround}</p>
        ),
      },
      {
        dataIndex: 'number',
        title: 'Номер талона',
        width: 190,
      },
      {
        dataIndex: 'fio',
        title: 'ФИО сотрудника (который обслуживал клиента)',
        width: 230,
      }],
  };
}
