import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import SqlQuery from './SqlQuery';
import TableConfig from './TableConfig';
import Table from '../../../../../../components/table/Table';
import { PreloaderBox } from '../../../../../../../../common/ui/Common';
import Preloader from '../../../../../../components/preloader/Preloader';
import { QalityAvgMarkBox } from '../../../../../../components/report/ReportTableColumns';

function SessionResultTable({ sessionDataId }) {
  const [ReportParams, setReportParams] = useState();
  const { reportConfig } = useSelector((state) => state);

  useEffect(() => {
    SqlQuery(sessionDataId).then((resp) => {
      const { dataObjects, query, avgMark } = resp;
      const params = {
        items: dataObjects,
        query,
        TableConfig: TableConfig(),
        avgMark,
      };
      params.items.forEach((item) => {
        // eslint-disable-next-line prefer-destructuring
        if (item[4] == null && item[5] != null) item[4] = item[5];
      });
      setReportParams(params);
    });
  }, [reportConfig]);

  return (
    ReportParams !== undefined
      ? (
        <PreloaderBox>
          <QalityAvgMarkBox>
            <div style={{ marginRight: '10px' }}>Средняя оценка</div>
            <div style={{ fontWeight: '700' }}>{ReportParams.avgMark}</div>
          </QalityAvgMarkBox>
          <Table
            keys="0"
            data={ReportParams.items}
            columns={ReportParams.TableConfig.columns}
            disablePagination
          />
        </PreloaderBox>
      )
      : <Preloader isOpen small />
  );
}

export default SessionResultTable;
