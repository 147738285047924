import React, { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import 'antd/dist/antd.min.css';
import moment from 'moment';
import TableConfig from './TableConfig';
import { Title } from '../../../../../../../common/ui/Common';
import { getColumnSearchProps } from './getColumnSearchProps';
import { getProcessTime, getWaitingTime } from './utils';

function TicketList({ data }) {
  const [searchText, setSearchText] = useState();
  const [searchedColumn, setSearchedColumn] = useState();
  const [actualData, setActualData] = useState(data);
  const input = useRef(null);

  useEffect(() => {
    // TODO Надо уточнить
    const arrayData = JSON.parse(JSON.stringify(data));

    setActualData(arrayData.map((ticket) => ({
      ...ticket,
      createdOn: moment(ticket.createdOn).format('DD.MM.YYYY HH:mm:ss'),
      modifiedOn: moment(ticket.modifiedOn).format('DD.MM.YYYY HH:mm:ss'),
      waitingTime: getWaitingTime(ticket),
      processTime: getProcessTime(ticket),

    })));
  }, [data]);

  const config = TableConfig(getColumnSearchProps({
    input,
    searchedColumn,
    searchText,
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
    },
    handleReset(clearFilters) {
      clearFilters();
      setSearchText('');
    },
  }));

  return (data ? (
    <>
      <Title>Список талонов</Title>
      <div>
        <Table
          rowKey="id"
          dataSource={actualData}
          columns={config.columns}
        />
      </div>
    </>
  ) : (<div>Пусто</div>));
}

export default React.memo(TicketList);
