import Request from '../../../../../../../../../../logic/utils/request';

export default async function SqlAvgProcTime(time, endTime) {
  const query = `select qt.fullname, qt.deleted, ft.avg_proc_time, ft.avg_none_format_time from (
    (select id, fullname, deleted from eq.tqueue) qt left join (select q_id,
    (sum(totalproc) / count(distinct ticket_id)) * interval '1 sec' as avg_proc_time,
    (sum(totalproc) / count(distinct ticket_id)) as avg_none_format_time
    from eq.ttickethistory where modified_on::timestamp >= '${time}' and modified_on::timestamp <= '${endTime}' and status in(3, 4)
    group by q_id) ft on qt.id = ft.q_id)  where  qt.deleted = false`;

  const dataObjects = [];
  const res = await Request('/server/api/genreportfromquery/', 'get', {
    querySql: `${query}`,
  });

  const answer = res.data.data;
  answer.forEach((v) => {
    dataObjects.push({ ...v });
  });

  return { dataObjects, query };
}
