import { Store } from 'pullstate';
import moment from 'moment/moment';
import { getReportConfig } from './appStore';

const { startDate, endDate } = getReportConfig();

const filterStore = new Store({
  operator: 'all',
  workPlace: 'all',
  queue: 'all',
  close_cnt: 'all',
  groupinType: 'wp_id',
  bunch_id: 'all',
  all_cnt: 'all',
  neyavka_cnt: 'all',
  avg_wait_time: 'all',
  total_wait_time: 'all',
  avg_proc_time: 'all',
  total_proc_time: 'all',
  total_work_time: 'all',
  avg_work_time: 'all',
  mark1: 'all',
  mark2: 'all',
  mark3: 'all',
  mark4: 'all',
  mark5: 'all',
  markcnt: 'all',
  share_cnt: 'all',
  solved_questions: 'all',
  create_data: 'all',
  close_data: 'all',
  ticket_number: 'all',
  ticket_id: 'all',
  registered: 'all',
  status: 'all',
  prior: 'all',
  ticket_mark: 'all',
  dateType: 'day',
  totalproc: 'all',
  pincode: 'all',
  clientfio: 'all',
  clientmail: 'all',
  clientphone: 'all',
  dateTypeDescription: 'день',
  startDate: startDate.format('DD.MM.YYYY HH:mm'),
  endDate: endDate.format('DD.MM.YYYY HH:mm'),
  historyDate: moment().format('DD.MM.YYYY HH:mm'),
  intervalReprotHistoryDate: 'all',
  orderArr: [],
});

export default filterStore;
