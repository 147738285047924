import React, { Fragment } from 'react';
// import DatePicker from 'react-datepicker';
import { DatePicker as AntDatePicker, Select } from 'antd';
import 'moment/locale/ru';
// import Dropdown from '../../../../../../../components/dropdown/Dropdown';
import { TestDiv, TestDiv2, TestDiv3 } from '../../style';
import 'react-datepicker/dist/react-datepicker.css';
import { useAllFilterDate } from './useAllFilterDate';

function AllFilter({
  changeFilter, filld, title, smallTitle,
}) {
  const {
    DropdownConfig,
    changeFilterMark,
    renderInput,
    DuobleItems,
    items,
    handleInput,
  } = useAllFilterDate({
    changeFilter, filld, title, smallTitle,
  });

  return (
    <TestDiv2>
      <TestDiv>
        {smallTitle ? (<div style={{ fontSize: '12px' }}>{title}</div>) : (
          <div>
            {' '}
            {title}
            {' '}
          </div>
        )}
        <div style={{ marginLeft: '10px' }}>
          {/* <Dropdown */}
          {/*  config={DropdownConfig} */}
          {/*  clickHandler={changeFilterMark} */}
          {/*  replaceableTitle */}
          {/* /> */}
          <Select onChange={changeFilterMark} defaultValue={DropdownConfig.items[0].name} className="select">
            {DropdownConfig.items.map((el) => (
              <Select.Option key={el.key} value={el.name}>{el.text}</Select.Option>
            ))}
          </Select>
        </div>
      </TestDiv>
      <TestDiv3 time="true">
        {renderInput === 'none'
          ? <div />
          : (renderInput === 'range' ? DuobleItems : items).map((item) => (
            <Fragment key={item.name}>
              <div
                style={{
                  marginLeft: '10px', display: 'flex', justifyContent: 'center',
                }}
              >
                {/* <DatePicker */}
                {/*  {...dpConfig} */}
                {/*  selected={item.value ?
                 moment(`${item.value}`, 'DD.MM.YYYY HH:mm').toDate() :
                 item.value} */}
                {/*  onChange={(date) => handleInput({ eDate: date }, item.name)} */}
                {/* /> */}
                <AntDatePicker
                  showTime
                  onChange={(date) => handleInput(date, item.name)}
                />
              </div>
              {renderInput === 'range' && item.name === 'rangeFrom' && (
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>И</div>
              )}
            </Fragment>
          ))}
      </TestDiv3>
    </TestDiv2>

  );
}

export default AllFilter;
