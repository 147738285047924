import React from 'react';
import { Table } from 'antd';
import { Title } from '../../../../../../../common/ui/Common';
import TableConfig from './TableConfig';

function QueuesList(props) {
  const { data } = props;
  const columns = TableConfig(); // Колонки для таблиц
  return (
    data ? (
      <>
        <Title>Список очередей/услуг</Title>
        <div>
          <Table
            rowKey="id"
            dataSource={data}
            columns={columns.columns}
          />
        </div>
      </>
    ) : (<div>Пусто</div>)
  );
}

export default QueuesList;
