import React, { useState } from 'react';
import {
  KeyboardBox, KeyboardStyle, KeyboardLine, KeyboardNumber,
} from './keyboardStyle';

function RenderButton({
  style, btn, click, shift, id, width,
}) {
  const otherBtn = btn === ' ' ? 2 : 0;
  const pad = btn === 'rus' || btn === 'eng' || btn === 'shift' ? 1 : otherBtn;
  let st = { ...style };
  if (id === 'text') {
    st = {
      ...st,
      minWidth: pad === 1 ? 'auto' : width / 10 - 5,
      maxWidth: style.width - 245,
      boxSizing: 'border-box',
      width: pad === 1 || !pad ? 'auto' : 'calc(100% - 30px)',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 20,
      justifyContent: 'center',
      fontSize: 26,
      flexWrap: 'none',
      padding: !pad ? '0' : '5px 23px 5px',
      margin: 2.5,
      textTransform: shift ? 'uppercase' : 'none',
      height: 50,
    };
  } else {
    st = {
      ...st,
      width: 'calc(33.3% - 10px)',
      boxSizing: 'border-box',
      height: 50,
      display: 'flex',
      fontSize: 32,
      alignItems: 'center',
      borderRadius: 20,
      justifyContent: 'center',
      margin: 5,
      textTransform: shift ? 'uppercase' : 'none',
    };
  }

  return (
    <div
      onDragStart={() => false}
      onContextMenu={(event) => event.preventDefault()}
      onClick={() => click(btn)}
      style={st}
    >
      {btn}
    </div>
  );
}

export default function KeyboardComponent({
  styleButton, value, id, login, width, setValue, onClick,
}) {
  const [shift, setShift] = useState(0);
  const [language, setLanguage] = useState(1);

  const deleteSymbol = () => {
    let newText = value.split('');
    newText.splice(-1, 1);
    newText = newText.join('');
    setValue(newText);
  };

  const addSymbol = (val) => {
    const nextSymbol = shift ? String(val).toUpperCase() : val;
    const newText = `${value}${nextSymbol}`;
    setValue(newText);
  };

  const keyClick = (val) => {
    const newValue = String(val).toLowerCase();
    switch (newValue) {
      case 'eng':
      case 'rus':
        setLanguage(!language);
        break;
      case 'shift':
        setShift(!shift);
        break;
      case '←':
        if (onClick) {
          onClick(val);
        } else {
          deleteSymbol(val, true);
        }
        break;
      default:
        if (onClick) {
          onClick(val);
        } else {
          addSymbol(val);
        }
    }
  };

  let lang = { number: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0] };
  if (language) {
    lang = {
      ...lang,
      line1: ['й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х'],
      line2: ['ъ', 'ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'э'],
      line3: ['я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю', '-', '←'],
      line4: ['.', 'eng', ' ', 'shift', ','],
    };
  } else {
    lang = {
      ...lang,
      line1: ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
      line2: ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', '-'],
      line3: ['z', 'x', 'c', 'v', 'b', 'n', 'm', '@', '.', '←'],
      line4: ['.', 'rus', ' ', 'shift', ','],
    };
  }

  return (
    <KeyboardBox data-time={123} style={{ marginBottom: login, marginTop: login, width }}>
      {id === 'text'
        ? (
          <KeyboardStyle>
            <KeyboardStyle>
              {lang.number.map((btn) => (
                <RenderButton
                  key={btn}
                  width={width}
                  shift={shift}
                  id={id}
                  btn={btn}
                  click={keyClick}
                  style={styleButton}
                />
              ))}
            </KeyboardStyle>
            {[lang.line1, lang.line2, lang.line3, lang.line4].map((line) => (
              <KeyboardLine>
                {line.map((btn) => (
                  <RenderButton
                    key={btn}
                    width={width}
                    shift={shift}
                    id={id}
                    btn={btn}
                    click={keyClick}
                    style={styleButton}
                  />
                ))}
              </KeyboardLine>
            ))}
          </KeyboardStyle>
        )
        : (
          <KeyboardStyle>
            <KeyboardNumber>
              {lang.number.map((btn) => (
                <RenderButton
                  key={btn}
                  shift={shift}
                  btn={btn}
                  click={keyClick}
                  style={styleButton}
                />
              ))}
              <RenderButton shift={shift} id={id} btn="←" click={keyClick} style={styleButton} />
            </KeyboardNumber>
          </KeyboardStyle>
        )}
    </KeyboardBox>
  );
}
