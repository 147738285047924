import styled from 'styled-components';

export const Box = styled.div`
  display: flex;
  flex-direction: ${(p) => (p.row ? 'row' : 'column')};
  margin: ${(p) => p.m || '0 0 15px'};
  > div {
    width: 50%;
  }
  p {
    margin-bottom: 10px;
  }
  &:first-child {
    margin-left: 0;
  }
  input {
    margin-bottom: 0;
  }
  .intervalTimeBox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
    h4 {
      min-width: 100%;
      display: block;
    }
  }
  .timeBox {
    width: 50%;
  }
  .ant-picker.ant-picker-borderless {
    padding: 0;
  }
  .ant-picker-range .ant-picker-active-bar {
    margin-left: 0;
  }
  .ant-picker-input > input {
    height: 40px;
    border: 1px solid rgba(0,0,0, 0.1);
    padding: 0 15px;
  }
  .interval {
    width: 100%;
    padding-right: 15px;
    >div{
      width: 79.3%;
    }
  }
  .inputBox {
    display: inline-block;
  }
  .react-datepicker__tab-loop {
    width: auto !important;
  }
`;

export const Container = styled.div`
  display: flex;
  padding: 15px 25px;
  flex-direction: ${(p) => (p.create ? 'column' : 'row')};
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0,0,0,.1), 0 0 10px rgba(0,0,0,.1);
  width: max-content;
  background: ${(p) => p.theme.bgTable};
  margin-bottom: 30px;
  > p {
    font-weight: bold;
    text-transform: uppercase;
    margin: ${(p) => (p.create ? '0 0 30px' : '0 0 0 30px')};
  }
`;

export const PreEntryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  h1 {
    width: 100%;
    text-align: center;
  }
  .createBox {
    display: flex;
    > * {
      margin-right: 15px;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 25px;
  background: ${(p) => p.theme.accent};
  color: ${(p) => p.theme.buttonHover};
  border-radius: 3px;
  cursor: pointer;
  transition: 0.1s ease-out;
  margin-top: 20px;
  border: none;
  &:hover {
    box-shadow: 0 0 0 3px ${(p) => p.theme.accentl}
  }
`;

export const InputBox = styled.div`
  margin-right: 15px;
  p {
    margin-bottom: 10px;
  }
`;

export const PreBox = styled.div`
  display: flex;
`;
export const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .selectedDay {
    margin-bottom: 10px;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
      width: 100%;
      cursor: pointer;
      padding: 10px 25px;
      margin-bottom: 15px;
      font-weight: bold;
      text-transform:uppercase; 
    }
  }
  > .flex {
    flex-wrap: wrap;
  }


  &.recordBig {
    max-width: 100%;
    .flex {
      margin: -5px;
    }

  
  &.recordBigCompact {
        .ant-card-small > .ant-card-head{
            padding: 0 5px;
        }
        .ant-card-small > .ant-card-body {
            padding: 5px;
        }
        .ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
            padding: 5px 0;
            font-size: 14px;
        }
    }


  > .titleConstructor {
    font-weight: normal;
    display: flex;
    margin-bottom: 16px;
    p {
      font-weight: bold;
      font-size: 22px;
      text-transform: uppercase;
      color: ${(p) => p.theme.text}
    }
  }
  .dis {
    background: ${(p) => p.theme.disable};
    border: 1px solid rgba(0,0,0,.2);
    padding: 0 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .active {
    background: ${(p) => p.theme.accent};
  }
  .box {
    margin-right: 50px;
    &.no {
      margin: 0
    }
    .btn {
      width: 100%;
      margin-top: 15px;
    }
    > div {
      margin-bottom: 10px;
      max-width: 340px;
      min-width: 340px;
      .react-datepicker-wrapper {
        min-width: 100%; 
      }
      .react-datepicker-wrapper input {
        margin-bottom: 15px;
        background: transparent;
      }
      input {
        box-shadow: none;
        min-width: 100%;
        border: 1px solid rgba(0,0,0,.1);
      }
    }
    p {
      margin-bottom: 5px;
      span {
        transform: translateY(-20px);
        color: ${(p) => p.theme.accent};
      }
    }
  }
  .recordBox {
    display: flex;
    flex-wrap: wrap;
    max-height: 0px;
    padding-right: 30px;
    overflow: auto;
    transition: 0.3s ease-out;
    &.openlist {
      max-height: 500px;
    }
  }
  .calendarButtonBox {
    width: 100%;
    display: flex;
    margin: 0px 0 30px;
  }
  .recordItem {
    display: flex;
    align-items: center;
    margin: 5px;
    height: 40px;
    border: 1px solid ${(p) => p.theme.disable};
    padding: 5px;
    &.clear {
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
      padding: 5px 20px;
      cursor: pointer;
    }
    &.green {
      background: ${(p) => p.theme.green};
    }
    .amount {
      font-size: 12px;
      padding: 3px 7px;
      border-radius: 50%;
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.buttonHover};
    }
    p {
      padding: 0 25px;
    }
    span {
      cursor: pointer;
      display: flex;
      align-items: center;
      svg {
        height: 20px;
        width: 20px;
        fill: ${(p) => p.theme.accent};
      }
    }
    .delete {
      margin-left: 5px;
    }
    &.registered {
      border: 1px solid ${(p) => p.theme.disable};
      span {
        background: ${(p) => p.theme.disable};
        cursor: no-drop;
      }
    }
  }
  .timesBox {
    max-height: 500px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
  }
`;
export const FilterMonth = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 10px;
      font-weight: normal;
    }
  }
  .monthFilter {
    margin-right: 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    > p {
      display: flex;
      text-transform: capitalize;
      align-items: center;
      justify-content: center;
      margin: 0 50px;
      flex: 1;
      text-align: center;
    }
    span {
      width: 40px;
      height: 40px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: ${(p) => p.theme.accent};
      &.shadow {
        border: none;
        background: none !important;
      }
    }
  }
`;

export const MonthBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  width: 340px;
  margin: 0px 28px 0 0;
  .month {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
  }
  .titleBox {
    margin: 0 0 5px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.2);
  }
  .description {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    .item {
      font-size: 12px;
      display: flex;
      max-width: 50%;
      min-width: 50%;
      margin-bottom: 10px;
      align-items: center;
      span {
        min-width: 20px;
        height: 20px;
        margin-right: 15px;
        background: ${(p) => p.theme.disable};
        &.green {
          background: ${(p) => p.theme.green};
        }
        &.blue {
          background: ${(p) => p.theme.blue};
        }
        &.red {
          background: ${(p) => p.theme.accent};
        }
        &.white {
          background: ${(p) => p.theme.bg};
          border: 1px solid ${(p) => p.theme.accent};
        }
      }
    }
  }
  .title {
    border: none;
    width: calc(100% / 7 - 10px);
    text-transform: none;
    margin: 0 5px;
    color: ${(p) => p.theme.text};
    font-size: 18px;
    cursor: default;
    font-weight: normal;
    &:after {
      display: none;
    }
  }
  .preloader {
    width: 100%;
    height: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  li {
    width: calc(100% / 7 - 10px);
    margin: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,.1);
    color: ${(p) => p.theme.disable};
    height: 40px;
    position: relative;
    .absoluteMenu {
      position: absolute;
      top: 0px;
      left: 100%;
      z-index: 2;
      padding: 20px;
      background: ${(p) => p.theme.bgTable};
      border-radius: 3px;
      box-shadow: 0 1px 5px rgba(0,0,0,.1), 0 10px 15px rgba(0,0,0,.1);
      color: ${(p) => p.theme.text};
      display: none;
      flex-direction: column;
      &.hide {
        display: none;
      }
      > div {
        white-space: nowrap;
        margin: 5px 0;
        padding: 5px 15px;
        border: 1px solid rgba(0,0,0,.1)
      }
    }
    &:hover {
      .absoluteMenu {
        display: flex;
      }
    }
    &.item {
      background: rgba(0,0,0,.1);
      color: rgba(0,0,0,.4);
      font-weight: normal;
    }
    &.open {
      background: ${(p) => p.theme.green};
      color: ${(p) => p.theme.text};
    }
    &.take {
      background: ${(p) => p.theme.accent};
      color: ${(p) => p.theme.text};
    }
    &.registered {
      background: ${(p) => p.theme.blue};
      color: ${(p) => p.theme.text};
    }
    &.active {
      border: 1px solid ${(p) => p.theme.accent};
      background: ${(p) => p.theme.bg};
    }
  }
`;
export const PreContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    color: ${(p) => p.theme.lightText};
  }
  .list {
    > p {
      font-weight: bold;
      margin: 30px 0 25px;
    }
    div {
      display: flex;
      flex-wrap: wrap;
      p {
        display: flex;
        height: 40px;
        padding: 0 0 0 15px;
        align-items: center;
        margin-right: 10px;
        width: min-content;
        border: 1px solid rgba(0,0,0,.1);
        span {
          padding: 0 12px;
          cursor:pointer;
          height: 100%;
          display: flex;
          background: ${(p) => p.theme.accent};
          color: ${(p) => p.theme.buttonHover};
          align-items: center;
          margin-left: 15px;
        }
      }
    }
  }
`;
