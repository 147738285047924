import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import 'moment/locale/ru';
import { isAfter } from 'date-fns';
import { DatePicker } from 'antd';
import {
  FilterBox, FilterBoxTitle, FilterItemBox,
} from '../style';
import appStore from '../../../../../../../../logic/store/pullstate/appStore';
// import ruDate from 'date-fns/locale/ru';
// import DatePicker from 'react-datepicker';

function ReportRange({ changeFilter }) {
  const reportConfig = appStore.useState((s) => s.reportConfig);
  // const startDate =
  // moment(appStore.useState((s) => s.reportConfig.startDate), 'dd.MM.yyyy HH:mm');
  // const endDate = moment(appStore.useState((s) => s.reportConfig.endDate), 'dd.MM.yyyy HH:mm');

  const handleDataChange = (range) => {
    // const defaultStartDate = startDate;
    // const defaultEndDate = endDate;
    const newStartDate = range ? range[0] : moment().startOf('month').toDate();
    let newEndDate = range ? range[1] : moment().toDate();

    newEndDate = isAfter(newStartDate, newEndDate) ? newStartDate : newEndDate;
    const time = moment(newStartDate);
    const endTime = moment(newEndDate);
    const dateFormat = 'DD.MM.YYYY HH:mm';

    appStore.update((s) => {
      // eslint-disable-next-line no-param-reassign
      s.reportConfig = {
        ...reportConfig,
        startDate: time,
        endDate: endTime,
      };
    });
    //
    changeFilter('startDate', time.format(dateFormat));
    changeFilter('endDate', endTime.format(dateFormat));
  };

  return (
    <FilterBox>
      <FilterBoxTitle>Период</FilterBoxTitle>
      <FilterItemBox range>
        {/* <ReportRangeItem> */}
        {/*  <div style={{ marginRight: '8px' }}>С</div> */}
        {/*  <DatePicker */}
        {/*    selectsStart */}
        {/*    showTimeSelect */}
        {/*    timeFormat="HH:mm" */}
        {/*    timeIntervals={15} */}
        {/*    locale={ruDate} */}
        {/*    timeCaption="Время" */}
        {/*    maxDate={type === 'preEntryReport' ? null : new Date()} */}
        {/*    dateFormat="dd.MM.yyyy HH:mm" */}
        {/*    selected={startDate.toDate()} */}
        {/*    onChange={(date) => handleDataChange({ sDate: date })} */}
        {/*  /> */}
        {/* </ReportRangeItem> */}
        {/* <ReportRangeItem left> */}
        {/*  <div style={{ marginRight: '8px' }}>По</div> */}
        {/*  <DatePicker */}
        {/*    selectsStart */}
        {/*    showTimeSelect */}
        {/*    timeFormat="HH:mm" */}
        {/*    timeIntervals={15} */}
        {/*    locale={ruDate} */}
        {/*    timeCaption="Время" */}
        {/*    maxDate={type === 'preEntryReport' ? null : new Date()} */}
        {/*    dateFormat="dd.MM.yyyy HH:mm" */}
        {/*    selectsEnd */}
        {/*    selected={endDate.toDate()} */}
        {/*    minDate={startDate.toDate()} */}
        {/*    onChange={(date) => handleDataChange({ eDate: date })} */}
        {/*  /> */}
        {/* </ReportRangeItem> */}
        <DatePicker.RangePicker
          format="DD.MM.YYYY HH:mm"
          style={{ height: '100%' }}
          onChange={handleDataChange}
          defaultValue={[moment(reportConfig.startDate, 'DD.MM.YYYY HH:mm'), moment(reportConfig.endDate, '2015/01/01', 'DD.MM.YYYY HH:mm')]}
          showTime
        />
      </FilterItemBox>
    </FilterBox>
  );
}

export default ReportRange;
